import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-sliderdateerror',
    templateUrl: './sliderdateerror.component.html',
    styleUrls: ['./sliderdateerror.component.css']
  })
  export class SliderDateErrorComponent implements OnInit {
    constructor(
      public router: Router,
      public dialogRef: MatDialogRef<SliderDateErrorComponent>
    ) {}

    ngOnInit() {
    }

    ok() {
      this.dialogRef.close();
    }
  }
