import { Component, OnInit, Input } from '@angular/core';
import { ServiceError } from '../../models/error';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-service-error',
  templateUrl: './service-error.component.html',
  styleUrls: ['./service-error.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({height: 0, opacity: 0}),
          animate('500ms', style({height: '40px', opacity: 1}))
        ]),
        transition(':leave', [
          style({height: '40px', opacity: 1}),
          animate('500ms', style({height: 0, opacity: 0}))
        ])
      ]
    )
  ]
})
export class ServiceErrorComponent implements OnInit {

  @Input() error?: ServiceError;
  @Input() show: boolean;

  constructor() { }

  ngOnInit() {
  }

  displayError(...args: number[]): boolean {
    if (!this.error) {
      return false;
    }

    return args.indexOf(this.error.errorCode) > -1;
  }
}
