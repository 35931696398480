import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, Input, Output, EventEmitter } from '@angular/core';
import { interval } from 'rxjs';
import { DateTime } from 'luxon';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { interpolateParams } from '@angular/animations/browser/src/util';

@Component({
  selector: 'app-parking-time',
  templateUrl: './parking-time.component.html',
  styleUrls: ['./parking-time.component.css']
})
export class ParkingTimeComponent implements OnInit, AfterViewInit {

  private _circlePadding = 30;

  private _width: number;
  private _height: number;

  @ViewChild('circleCanvas')
  circleCanvasRef: ElementRef;

  @ViewChild('timeContainer')
  timeContainer: ElementRef<HTMLDivElement>;

  @Input('fromMillis')
  fromMillis?: string;

  @Output()
  validToChanged = new EventEmitter<DateTime>();

  circleCanvas: HTMLCanvasElement;
  circleContext: CanvasRenderingContext2D;

  hitCanvas: HTMLCanvasElement;
  hitContext: CanvasRenderingContext2D;

  value: number;

  day = 0;
  hour = 0;
  minute = 0;
  dateSet: DateTime;
  validTo: DateTime;

  constructor(private dialog: MatDialog) {
    this.hour = 4;
    this.value = 240;
    this.validTo = this.getFromTime().plus({hours: 4});
    this.dateSet = this.validTo.toLocal();

    this.hitCanvas = document.createElement('canvas');
    this.hitContext = this.hitCanvas.getContext('2d');
   }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.circleCanvas = <HTMLCanvasElement>this.circleCanvasRef.nativeElement;
    this.circleContext = <CanvasRenderingContext2D>this.circleCanvas.getContext('2d');

    this.circleCanvas.height = this.circleCanvas.width;

    this._width = this.circleCanvas.width;
    this._height = this.circleCanvas.height;

    this.timeContainer.nativeElement.style.width = (this.getCircleRadius() * 1.5) + 'px';

    this.hitCanvas.width = this._width;
    this.hitCanvas.height = this._height;
    this.drawGhostCircle();

    this.drawCircle();
    // this.drawCircleOuterText();

    this.circleCanvas.addEventListener('click', (evt) => {
      const mouseCords = this.getMousePos(evt);

      const inCircle = (<any>this.hitContext).isPointInStroke(mouseCords.x, mouseCords.y);
      if (!inCircle) {
        return;
      }

      const mouseRadian = Math.atan2(mouseCords.y - (this._height / 2), mouseCords.x - (this._width / 2));
      const degree = this.radianToDegree(mouseRadian);

      // console.log('d: ' + degree);

      const value = Math.ceil(this.degreeToValue(degree));

      // console.log('v: ' + value);
      // this.redrawCircle();

      let min = Math.ceil((value % 60) / 5) * 5;
      let hours = Math.round((value - min) / 60);

      if (min === 60) {
        hours += 1;
        min = 0;
      }

      this.setDuration(hours, min);
    });
  }

  private degreeToValue(degree: number): number {
    const minutesPerDegree = 720 / 360;
    const minuteSkew = 180;

    let result = (degree * minutesPerDegree) + minuteSkew;
    // console.log(result);
    if (result > 720) {
      result -= 720;
    }

    // console.log(result);
    return result;
  }

  private getMousePos(evt): {x: number, y: number} {
    // const rect = this.circleCanvas.getBoundingClientRect();
    // return {
    //   x: evt.clientX - rect.left,
    //   y: evt.clientY - rect.top
    // };

    const rect = this.circleCanvas.getBoundingClientRect(),
      scaleX = this.circleCanvas.width / rect.width,
      scaleY = this.circleCanvas.height / rect.height;

      return {
        x: (evt.clientX - rect.left) * scaleX,
        y: (evt.clientY - rect.top) * scaleY
      };
  }

  private redrawCircle(): void {
    this.circleContext.clearRect(0, 0, this.circleCanvas.width, this.circleCanvas.height);
    this.drawCircle();
    // this.drawCircleOuterText();
  }

  // private drawCircleOuterText(): void {
  //   const topText = '0';
  //   const rightText = '3t';
  //   const bottomText = '6t';
  //   const leftText = '9t';

  //   const width = this.circleCanvas.width;
  //   this.circleContext.font = 'bold 10pt Arial';
  //   this.circleContext.fillStyle = '#375977';
  //   this.circleContext.fillText('0', 146, 30);
  //   this.circleContext.fillText('3t.', 265, 155);
  //   this.circleContext.fillText('6t.', 140, 280);
  //   this.circleContext.fillText('9t.', 5, 155);
  // }

  private getCircleRadius(): number {
    return (this.circleCanvas.width / 2) - 15 - this._circlePadding;
  }

  private drawGhostCircle(): void {
    // this.hitContext = this.circleContext;
    const width = this.circleCanvas.width;
    const centerX = width / 2;
    const centerY = width / 2;

    const radius = this.getCircleRadius();

    this.hitContext.beginPath();
    this.hitContext.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);

    this.hitContext.lineWidth = 35;
    this.hitContext.strokeStyle = '#000000';
    this.hitContext.stroke();

  }

  private drawCircle(): void {
    const width = this.circleCanvas.width;
    const centerX = width / 2;
    const centerY = width / 2;

    const radius = this.getCircleRadius();

    const startRadian = this.degreeToRadian(-90);

    const degreesPerMinute = 360 / 720;
    let minutes = this.value;
    if (minutes > 720) {
      minutes = 720;
    }

    const degrees = (minutes * degreesPerMinute) - 90;
    const minutesRadians = this.degreeToRadian(degrees);

    this.circleContext.beginPath();
    this.circleContext.arc(centerX, centerY, radius, startRadian, minutesRadians, false);

    this.circleContext.setLineDash([8, 2]);
    this.circleContext.lineWidth = 15;
    this.circleContext.strokeStyle = '#78B51A';
    this.circleContext.stroke();

    if (minutes < 720) {
      let endRad = minutesRadians;

      if (minutes <= 0) {
        endRad = this.degreeToRadian(270);
      }

    this.circleContext.beginPath();
    this.circleContext.arc(centerX, centerY, radius, startRadian, endRad, true);

    //  this.circleContext.lineDashOffset = 9;
    this.circleContext.setLineDash([8, 2]);
    this.circleContext.lineWidth = 15;
    this.circleContext.strokeStyle = '#cccccc';
    this.circleContext.stroke();
    }
  }

  private degreeToRadian(degree: number): number {
    return degree * (Math.PI / 180);
  }

  private radianToDegree(radian: number): number {
    const result = radian * 180 / Math.PI;

    if ( result < 0) {
      return 180 + (result + 180);
    }

    return result;
  }

  private getFromTime(): DateTime {
    let from = DateTime.local();

    if (this.fromMillis) {
      try {
        from = DateTime.fromMillis(parseInt(this.fromMillis, 10));
      } catch (e) {
      }
    }

    return from;
  }

  openDuration() {
    const dialogRef = this.dialog.open(DialogTimeComponent, {
       data: {
         startHourValue : this.hour,
         startMinuteValue: this.minute
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.setDuration(result.hour, result.minute);
    });
  }

  setDuration(hour?: number, minute?: number, date?: DateTime) {
    if (date != null) {
        date = date.plus({hours: hour, minutes: minute});

        this.dateSet = date;
        this.validTo = date;

        const duration = date.diff(this.getFromTime());
        const minutes  = parseInt(((duration.milliseconds / (1000 * 60)) % 60).toString(), 10);
        const hours    = parseInt(((duration.milliseconds / (1000 * 60 * 60)) % 24).toString(), 10);
        const days     = parseInt((duration.milliseconds / (1000 * 60 * 60 * 24)).toString(), 10);

        this.minute = minutes;
        this.hour = hours;
        this.day = days;
    } else if (hour != null && minute != null) {
      this.hour = hour;
      this.minute = minute;

      const validTo = this.getFromTime().plus({minutes: minute, hours: hour});
      this.dateSet = validTo.toLocal();
      this.validTo = validTo;
    }

    this.value = ((this.hour * 60) + this.minute);
    this.redrawCircle();

    this.validToChanged.emit(this.dateSet);
  }

  setTimeDate() {
    const dialogRef = this.dialog.open(DialogDateComponent, {
       data: {
         startHourValue: this.hour,
         startMinuteValue: this.minute,
         startDateValue: this.validTo
        }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hour !== undefined && result.minute !== undefined) {
        this.setDuration(result.hour, result.minute, result.date);
      }
    });
  }
}

@Component({
  selector: 'app-dialog-time',
  templateUrl: 'dialog-time.component.html',
})
export class DialogTimeComponent implements OnInit {
  hours = [];
  min = [];
  startMinuteValue = 0;
  startDateValue: Date;
  startHourValue = 0;

  constructor(
    public dialogRef: MatDialogRef<DialogTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.startMinuteValue = this.data.startMinuteValue;
    this.startHourValue = this.data.startHourValue;

    for ( let i = 0; i < 60; i++) {
      this.min.push(i);
    }
    for ( let i = 0; i < 48; i++) {
      this.hours.push(i);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateAnpr() {
    this.dialogRef.close({hour: this.startHourValue, minute: this.startMinuteValue});
  }
}

@Component({
  selector: 'app-dialog-date',
  templateUrl: 'dialog-date.component.html',
})
export class DialogDateComponent implements OnInit {
  hours = [];
  min = [];
  startMinuteValue = 0;
  startDateValue;
  startHourValue = 0;

  constructor(
    public dialogRef: MatDialogRef<DialogDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.startDateValue = DateTime.local().toJSDate();
    this.startHourValue = this.startDateValue.hour;
    this.startMinuteValue = this.startDateValue.minute;

    for ( let i = 0; i < 60; i++) {
      this.min.push(i);
    }
    for ( let i = 0; i < 24; i++) {
      this.hours.push(i);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveDate() {
    this.startDateValue = DateTime.fromJSDate(this.startDateValue);
    this.dialogRef.close({hour: this.startHourValue, minute: this.startMinuteValue, date: this.startDateValue});
  }
}
