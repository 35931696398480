import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-epayhandler',
  templateUrl: './epayhandler.component.html',
  styleUrls: ['./epayhandler.component.css']
})
export class EpayhandlerComponent implements OnInit {
  private subscription: any;
  tnxid: number;
  order: number;
  amount: number;
  currency: number;
  date: number;
  time: number;
  txnfee: number;
  paymenttype: number;
  cardno: string;
  hash: string;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.subscription = this.route.params.subscribe(params => {
      if (params['tnxid'] !== undefined) {
        this.tnxid = params['tnxid'];
        this.order = params['order'];
        this.amount = params['amount'];
        this.currency = params['currency'];
        this.date = params['date'];
        this.time = params['time'];
        this.txnfee = params['txnfee'];
        this.paymenttype = params['paymenttype'];
        this.cardno = params['cardno'];
        this.hash = params['hash'];
      }
    });

    this.processEpay();
  }

  ngOnInit() {
  }

  processEpay() {
    this.router.navigate(['']);
  }
}
