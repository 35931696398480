import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { IAnprParking, IAnprSearchResult, IAnprOrder, IAnprUserInfo } from '../models/anprPayment';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class AnprpaymentService {

  constructor(private http: HttpClient) { }

  search(plate: string): Observable<IAnprSearchResult> {
    const postData = {
      plate: plate,
      includeNoStop: true,
      includeNoStart: true,
      includeFree: true
    };

    return this.http.post<IAnprSearchResult>(environment.apiBaseUrl + `parking/anpr/search`, postData);
  }

  getParking(parkId: number): Observable<IAnprParking> {
    return this.http.get<IAnprParking>(environment.apiBaseUrl + `parking/anpr/${parkId}`);
  }

  makeOrder(parkId: number, userInfo: IAnprUserInfo): Observable<IAnprOrder> {
    return this.http.post<IAnprOrder>(environment.apiBaseUrl + `parking/anpr/${parkId}/makeorder`, userInfo);
  }

  updateParking(parkId: number, validFrom: DateTime, validTo: DateTime): Observable<{}> {
    const data = {
      starttime: validFrom.toISO(),
      endtime: validTo.toISO()
    };

    return this.http.put(environment.apiBaseUrl + `parking/anpr/${parkId}`, data);
  }
}
