import { Component, OnInit, AfterViewInit, Inject, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { QuickparkService } from '../../services/quickpark.service';
import { EpayService } from '../../services/epay.service';
import { CurrentparkingService } from '../../services/currentparking.service';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-paymentdialog',
  templateUrl: './paymentdialog.component.html',
  styleUrls: ['./paymentdialog.component.css']
})
export class PaymentDialogComponent implements OnInit, AfterViewInit {

    constructor(public dialogRef: MatDialogRef<PaymentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private route: ActivatedRoute,
        private quickParkService: QuickparkService,
        private currentParkingService: CurrentparkingService,
        private epayService: EpayService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }
}
