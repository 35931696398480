import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateTime } from 'luxon';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { interpolateParams } from '@angular/animations/browser/src/util';


@Component({
    selector: 'app-ie-date',
    templateUrl: 'ie-date.component.html',
  })
  export class IEDateComponent implements OnInit {
    value: number;

    day = 0;
    hour = 0;
    minute = 0;
    dateSet: DateTime;
    validTo: DateTime;

    ieHours = [];
    ieMin = [];

    ieMinuteValue = 0;
    ieDateValue;
    ieHourValue = 4;

    @Input('fromMillis')
        fromMillis?: string;

    @Output()
        validToChanged = new EventEmitter<DateTime>();

    constructor () {}

    ngOnInit() {
        this.ieDateValue = DateTime.local().toJSDate();
        for ( let i = 0; i < 60; i++) {
            this.ieMin.push(i);
        }
        for ( let i = 0; i < 24; i++) {
            this.ieHours.push(i);
        }
    }

    saveDate() {
        this.setDuration(this.ieHourValue, this.ieMinuteValue, DateTime.fromJSDate(this.ieDateValue));
    }

    private getFromTime(): DateTime {
        let from = DateTime.local();

        if (this.fromMillis) {
          try {
            from = DateTime.fromMillis(parseInt(this.fromMillis, 10));
          } catch (e) {
          }
        }

        return from;
    }

    setDuration(hour?: number, minute?: number, date?: DateTime) {
        if (date != null) {
            date = date.plus({hours: hour, minutes: minute});

            this.dateSet = date;
            this.validTo = date;

            const duration = date.diff(this.getFromTime());
            const minutes  = parseInt(((duration.milliseconds / (1000 * 60)) % 60).toString(), 10);
            const hours    = parseInt(((duration.milliseconds / (1000 * 60 * 60)) % 24).toString(), 10);
            const days     = parseInt((duration.milliseconds / (1000 * 60 * 60 * 24)).toString(), 10);

            this.minute = minutes;
            this.hour = hours;
            this.day = days;
        } else if (hour != null && minute != null) {
          this.hour = hour;
          this.minute = minute;

          const validTo = this.getFromTime().plus({minutes: minute, hours: hour});
          this.dateSet = validTo.toLocal();
          this.validTo = validTo;
        }

        this.value = ((this.hour * 60) + this.minute);
        this.validToChanged.emit(this.dateSet);
    }
}
