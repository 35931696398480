import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customersupport',
  templateUrl: './customersupport.component.html',
  styleUrls: ['./customersupport.component.css']
})
export class CustomersupportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
