import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ContractService } from 'src/app/services/contract.service';
import { ContractCancelSuccessComponent } from './contractcancelsuccess.component';

@Component({
  selector: 'app-contractcancel',
  templateUrl: './contractcancel.component.html',
  styleUrls: ['./contractcancel.component.css']
})

export class ContractCancelComponent implements OnInit {
  parkingId: any;
  vehicleRegistration: any;
  spot: string;
  confirmBox: string;
  contractParking: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private contractService: ContractService) {
  }

    ngOnInit() {
        this.spot = this.data.spotNum;
        this.parkingId = this.data.parkingId;
        this.vehicleRegistration = this.data.vehicleRegistration;
    }

  cancelContract() {
      // if (this.confirmBox === this.spot) {
        this.contractService.cancelContractParking(this.parkingId, this.vehicleRegistration).subscribe(res => {
            this.dialog.open(ContractCancelSuccessComponent, {
              width: '500px'
            });
        });
      // }
  }
}
