import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { interval } from 'rxjs';
import { CurrentParking } from '../../models/currentparking';
import { ParkingService } from '../../services/parking.service';
import { EventService } from '../../services/event.service';
import { QuickparkService } from '../../services/quickpark.service';
import { LoadingService } from '../../services/loading.service';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CurrentparkingService } from '../../services/currentparking.service';
import { ITimeLimits } from 'src/app/models/parking';
import {transition, trigger, style, animate} from "@angular/animations";

@Component({
  selector: 'app-currentparking',
  // tslint:disable-next-line:use-input-property-decorator
  inputs: ['data'],
  templateUrl: './currentparking.component.html',
  styleUrls: ['./currentparking.component.css'],
  animations: [
    trigger('insertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class CurrentparkingComponent implements OnInit {
  data: CurrentParking;
  timeUntill: string;
  vehicleRegistration: string;
  stopped = false;
  anyParking = false;
  quickpark = false;
  address: string;
  areaNumber: string;
  canStop: boolean;
  canExtend: boolean;
  contractParking: boolean;
  timeLimits?: string;
  constructor(
    private parkingService: ParkingService,
    private dialog: MatDialog,
    private router: Router,
    private currentparkingService: CurrentparkingService,
    private loadingService: LoadingService,
    private cd: ChangeDetectorRef,
    private eventService: EventService
  
  ) { }

  ngOnInit() {
    interval(1000).subscribe( l => {

    if(this.eventService.getUpdateMyParkings()){
      this.cd.markForCheck();
    }
    if (this.data !== undefined && !this.stopped) {
      this.anyParking = true;
      const obj = this.data.data;
      this.vehicleRegistration = obj.vehicleRegistration;
      this.areaNumber = obj.parkingAddress;
      this.canStop = obj.canStop;
      this.canExtend = obj.canExtend;
      this.timeLimits = obj.timeLimits;

      if (obj.parkingType === 4) {
        this.contractParking = true;
      } else {
        this.contractParking = false;
      }
      if (this.contractParking === true) {
        if (obj.endtime != null) {
          const now = DateTime.local();
          const end = DateTime.fromISO(obj.endtime.toString(), {setZone: true}).set({hour: 0, minute : 0 });
          const diff = end.diff(now, ['years', 'months', 'days']).toObject();
        //  const dif = end.diff(now);
        const months = Math.floor(diff.months);
          const days = Math.ceil(diff.days);

          let finalString = 'Udløber om ';

          if (months !== 0 && months > 0) {
            finalString += months + ' Md(r) ';
          }

          if (days !== 0 && days > 0) {
            finalString += days + ' Dag(e)';
          }
          else if(days === 0 && months === 0){
            finalString = 'Udløber i dag';
          }
          else {
            finalString = 'Udløbet';
          }
          this.timeUntill = finalString;
        } else {
          this.timeUntill = 'Fornyes automatisk.';
        }
      }
      if ( obj.transactions != null && obj.transactions.length > 0 && this.contractParking === false) {
        let validTo = null;
        let validFrom = null;
        let to = null;
        let from = null;
        validFrom = obj.transactions[obj.transactions.length - 1].validFrom;
        const fromDate = new Date(validFrom);
        from = DateTime.fromJSDate(fromDate);
        if (obj.transactions[obj.transactions.length - 1].validTo !== null) {
          validTo = obj.transactions[obj.transactions.length - 1].validTo;
          const jsDate = new Date(validTo);
          to = DateTime.fromJSDate(jsDate);
        }

        if (to !== null) {
          const now = DateTime.local();
          const dif = to.diff(now);
          this.timeUntill = dif.toFormat('hh:mm:ss');
          if (to < now) {
            this.timeUntill = '00:00:00';
          }
        } else {
          const dif = DateTime.local().diff(from);
          let time = null;
          time = dif.toFormat('hh:mm:ss');
          this.timeUntill = 'Igangværende';
        }
      }
      this.cd.markForCheck();
    }
    });
  }

  contractParkingInfo() {
    const obj = this.data.data;
    const contractParking = {
      parkingId: obj.parkingNumberLong,
      vehicleRegistration: obj.vehicleRegistration
    };
    this.router.navigate([`/contractparking/${obj.parkingAreaNumber}/edit/${obj.parkingNumberLong}`]);
  }

  stopParking() {
    const obj = this.data.data;
    if (obj.canStop) {
      this.dialog.open(StopparkingComponent, {
        data: { parkingNumber : obj.parkingNumberLong, quickpark : obj.quickpark, customerId : obj.customerId },
        disableClose: true
      });
    }
  }

  public endStoppedParkings(parkingNumber, customerId?, quickpark?) {
    if (quickpark === null || quickpark === false) {
        this.parkingService.stopParking(parkingNumber).subscribe(x => {
          this.loadingService.startLoading();
      });
    } else {
      for (let i = 0; i < 100; i++) {
        const quickPark = JSON.parse(localStorage.getItem(`quickpark${i}`));

        if (quickPark.parkingNumberLong === parkingNumber) {
          localStorage.removeItem(`quickpark${i}`);
          this.currentparkingService.removeLocalCurrentParking(parkingNumber);
          break;
        }
      }
    }

    this.loadingService.stopLoading();
    this.timeUntill = '' + DateTime.DATETIME_SHORT;
    this.anyParking = false;
    this.stopped = true;
  }

  extendParking() {
    const obj = this.data.data;
    if (obj.canExtend && obj.transactions.length > 0) {
      sessionStorage.setItem('endTime', JSON.stringify(obj.transactions[obj.transactions.length - 1].validTo.valueOf()));
      sessionStorage.setItem('parkingNumber', obj.parkingNumberLong.toString());

      this.router.navigate([`/map/${obj.parkingAreakey}`]);
      }
    }
  }


@Component({
  selector: 'app-stopparking-dialog',
  templateUrl: './stopparking.component.html',
  styleUrls: ['./stopparking.component.css']
})

export class StopparkingComponent implements OnInit {
  parking: CurrentParking;
  parkingNumber: number;
  quickPark: boolean;
  customerId: string;

  constructor(public dialogRef: MatDialogRef<StopparkingComponent>,
    private parkingService: ParkingService,
    private quickParkService: QuickparkService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router) {}

  ngOnInit() {
    this.parkingNumber = this.data.parkingNumber;
    this.quickPark = this.data.quickpark;

    if (this.data.customerId !== null) {
      this.customerId = this.data.customerId;
    }
  }

  okay() { // check if quickpark
    if (this.quickPark) {
      this.quickParkService.stop(this.parkingNumber, this.customerId).subscribe(res => {
        this.dialogRef.close();
        this.router.navigate(['']);
        location.reload();
      });
    } else {
      this.parkingService.stopParking(this.parkingNumber).subscribe(res => {
        this.dialogRef.close();
        this.router.navigate(['']);
        location.reload();
      });
    }
  }

  cancel() {
    this.parkingNumber = null;
    this.customerId = null;
    this.dialogRef.close();
  }
}
