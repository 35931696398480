import { Component, OnInit, Inject } from '@angular/core';
import { RouterLink, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { MobileNumberService } from '../../services/mobile-number.service';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { FacilityService } from '../../services/facility.service';

@Component({
  selector: 'app-mobilenumbers',
  templateUrl: './mobilenumbers.component.html',
  styleUrls: ['./mobilenumbers.component.css']
})
export class MobilenumbersComponent implements OnInit {
private mobileNumberService: MobileNumberService;
private facilityService: FacilityService;
mobileNumbers = [];
validationCode: string;
pspots = [];

selectedPspot: string;

smsCode: number;
validTo: DateTime;
plateOutput: string;
pSpotOutput: string;
endtimeOutput: string;

plate = new FormControl('', [
  Validators.required
]);

endtime = new FormControl('', [
  Validators.required,
  Validators.maxLength(4)
]);

  constructor(private mobilenumberService: MobileNumberService, private dialog: MatDialog, private mfacilityService: FacilityService) {
    this.mobileNumberService = mobilenumberService;
    this.facilityService = mfacilityService;
    this.plateOutput = '';
    this.pSpotOutput = '';
    this.endtimeOutput = '';

    dialog.afterAllClosed.subscribe(() => {
      this.getMobiles();
    });
    this.facilityService.getFacilities().subscribe( x => {
      this.pspots = x;
    });
   }

   pspotChange(data) {
     console.log(data);
    const val = this.selectedPspot;
    console.log(val);
    this.pSpotOutput = val;
   }

   plateChange() {
    const val = this.plate.value.trim();
    this.plateOutput = val;
    this.plate.setValue(val);
   }

   endtimeChange() {
    const val = this.endtime.value.trim();
    this.endtimeOutput = val;
    this.endtime.setValue(val);
   }

  ngOnInit() {
  }

  getMobiles() {
    this.mobilenumberService.getNumber().subscribe(result => {
      this.mobileNumbers = [];
      for (const i in result) {
        if (result[i].number !== null) {
          this.mobileNumbers.push(result[i]);
        }
      }
    });
  }

  deleteNumber(number) {
      this.dialog.open(DeleteNumberDialogComponent, {
      data: {number: number}
    });
  }

  addNumber() {
      this.dialog.open(AddNumberDialogComponent, {
    });
   }

   validateNumber(number) {
      this.dialog.open(ValidateNumberDialogComponent, {
      data: {number: number}
    });
   }
}

@Component({
  selector: 'app-addnumber-dialog',
  templateUrl: './addnumber-dialog.component.html',
  styleUrls: ['./addnumber-dialog.component.css']
})
export class AddNumberDialogComponent implements OnInit {
private mobileNumberService: MobileNumberService;

number = new FormControl('', [
  Validators.required
]);

  constructor(public dialogRef: MatDialogRef<AddNumberDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router, private mobilenumberService: MobileNumberService) {
      this.mobileNumberService = mobilenumberService;
  }

  ngOnInit() {
  }

  addNumber() {
    this.mobileNumberService.add(this.number.value).subscribe(result => {
    });

    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-validatenumber-dialog',
  templateUrl: './validatenumber-dialog.component.html'
})
export class ValidateNumberDialogComponent implements OnInit {
private mobileNumberService: MobileNumberService;

number: string;

validationsCode = new FormControl('', [
  Validators.required
]);

    constructor(public dialogRef: MatDialogRef<ValidateNumberDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
      private router: Router, private mobilenumberService: MobileNumberService) {
        this.mobileNumberService = mobilenumberService;
    }

    ngOnInit() {
      this.number = this.data.number;
    }

    validateNumber() {
      this.mobilenumberService.verify(this.number, this.validationsCode.value).subscribe(result => {
      });

      this.dialogRef.close();
    }
}

@Component({
  selector: 'app-deletenumber-dialog',
  templateUrl: './deletenumber-dialog.component.html',
  styleUrls: ['./deletenumber-dialog.component.css']
})
export class DeleteNumberDialogComponent implements OnInit {
private mobileNumberService: MobileNumberService;

number: string;

    constructor(public dialogRef: MatDialogRef<DeleteNumberDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
      private router: Router, private mobilenumberService: MobileNumberService) {
        this.mobileNumberService = mobilenumberService;
    }

  ngOnInit() {
      this.number = this.data.number;
    }

  deleteNumber() {
    this.mobilenumberService.delete(this.number).subscribe(result => {
    });

    this.dialogRef.close();
  }

  regret() {
    this.dialogRef.close();
  }
}

