import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-contactfailed',
    templateUrl: './contactfailed.component.html',
    styleUrls: ['./contactfailed.component.css']
  })
  export class ContactFailedComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ContactFailedComponent>) {}

    ngOnInit() {
    }

    ok() {
      this.dialogRef.close();
    }
  }
