import { Component } from '@angular/core';

@Component({
    selector: 'app-payparkingpaid',
    templateUrl: 'payparkingpaid.component.html',
})
  // tslint:disable-next-line:component-class-suffix
export class PayParkingPaidComponent {

constructor() { }
}
