export enum DiscountCardItemType { Group = 0, Card = 1 }

export interface IDiscountCard {
    id: number;
    type: DiscountCardItemType;
    name: string;
    description?: string;
    imageUri: string;
    validationKeyText?: string;
    cards?: IDiscountCard[];
    groupId?: number;
    groupSelectable?: boolean;
    key: string;
}
