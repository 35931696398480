import { ITimeLimits, IClosingPeriods } from './parking';

export interface IParkingFacility {
    id: number;
    number: string;
    name: string;
    key: string;
    providerKey: string;
    address: string;
    city: string;
    longitude?: number;
    latitude?: number;
    freeMinutes?: number;
    description: string;
    generalComment: string;
    statusComment: string;
    allowStopParking: boolean;
    zip: string;
    country: string;
    countryCode: string;
    additionalState?: number;
    state: number;
    hasInformation: boolean;
    showInformationOnSelect: boolean;
    geoJson: string;
    type: areaType;
    closingTimes?: ITimeLimits;
    closingPeriods?: IClosingPeriods[];
}

export enum areaType {
    area = 1,
    zone
}
