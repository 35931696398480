import { Component, OnInit } from '@angular/core';

import { VehicleregistrationService } from '../../services/vehicleregistration.service';
import { IVehicleRegistration } from '../../models/vehicleregistration';
import { Router, ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { CreatecarComponent } from '../createcar/createcar.component';
import { EditcarComponent } from '../editcar/editcar.component';

import { IProduct } from 'src/app/models/contract';
import { CurrentparkingService } from '../../services/currentparking.service';

@Component({
  selector: 'app-mycars',
  templateUrl: './mycars.component.html',
  styleUrls: ['./mycars.component.css']
})
export class MycarsComponent implements OnInit {
private vehicleRegistration: IVehicleRegistration;
cookie: string;
favoriteCars = [];
secondaryCars = [];
products?: IProduct[] = [];
spot: string;
anyContractParkings: boolean;

  constructor(
    private vehicleService: VehicleregistrationService,
    private currentParkingService: CurrentparkingService,
    private router: Router,
    private dialog: MatDialog) {

    dialog.afterAllClosed.subscribe(() => {
      this.getCars();
    });
   }

  ngOnInit() {
    if (localStorage.getItem('newAccount')) {
      this.cookie = JSON.stringify(localStorage.getItem('newAccount'));
    }

    this.currentParkingService.currentParkings().subscribe(parking => {
      this.anyContractParkings = parking.some(e => e.data.parkingType === 4);
    });
    
  }

  getCars() {
    this.vehicleService.registrations().subscribe(result => {
      this.favoriteCars = [];
      this.secondaryCars = [];
      for (const i in result) {
        if (result[i].isPrimary === true) {
          this.favoriteCars.push(result[i]);
        } else {
          this.secondaryCars.push(result[i]);
        }
      }
    });
  }

  setFavorite(key) {
    this.vehicleService.registrations().subscribe(result => {
      for (const i in result) {
        if (result[i].key === key) {
          this.vehicleService.togglePrimary(key, true).subscribe(x => {
            this.getCars();
          });
        }
      }
    });
  }

  removeFavorite(key) {
    this.vehicleService.registrations().subscribe(result => {
      for (const i in result) {
        if (result[i].key === key) {
          this.vehicleService.togglePrimary(key, false).subscribe(x => {
            this.getCars();
          });
        }
      }
    });
  }

  createCar() {
    this.dialog.open(CreatecarComponent, {
    });
  }

  editCar(key) {
    this.dialog.open(EditcarComponent, {
      data: { key: key }
    });
  }

  next() {
    localStorage.removeItem('newAccount');
    this.router.navigate(['']);
  }
}
