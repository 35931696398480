import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { IVehicleRegistrationCreate } from "src/app/models/vehicleregistration";
import { AuthService } from "src/app/services/auth.service";
import { VehicleregistrationService } from "src/app/services/vehicleregistration.service";


@Component({
    selector: 'app-dmrConfirm',
    templateUrl: './dmrConfirm.component.html',
    styleUrls: ['./dmrConfirm.component.css']
  })
export class DMRConfirmComponent implements OnInit {
  plate: string;
  description: string;
  authService: AuthService;
  vehicleRegistrationService: VehicleregistrationService;
  favorited = true;
  vehicleCreate: IVehicleRegistrationCreate
  descriptionForm = new FormControl({value: this.description, disblaed: false});
    constructor(public dialogRef: MatDialogRef<DMRConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any, authService: AuthService, vehicleRegistrationService: VehicleregistrationService) {
      this.authService = authService;
      this.vehicleRegistrationService = vehicleRegistrationService;
  }
        closeWindow() {
            this.dialogRef.close();
        }
        continue() {
              this.vehicleCreate = {
                country: this.authService.getCountryCode(),
                description: this.descriptionForm.value,
                typed: this.plate,
                isPrimary: this.favorited
              };
         
              this.vehicleRegistrationService.create(this.vehicleCreate).subscribe(result => {
              });
         
              this.dialogRef.close();
        }
        
    ngOnInit() {
      this.plate = this.data.plate;
      this.description = this.data.brand + " " + this.data.model + " " + this.data.variant;
      this.descriptionForm.setValue(this.description)
    }
    setFavorite() {
      if(this.favorited === true)
      { this.favorited = false;}
      else {
        this.favorited = true;
      }
    }
    
  }