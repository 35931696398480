import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
isLoading: boolean;

    constructor() { }

    public startLoading() {
        this.isLoading = true;
    }

    public stopLoading() {
        this.isLoading = false;
    }

    public getLoadingStatus(): boolean {
        return this.isLoading;
    }
}
