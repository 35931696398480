import { Component, OnInit } from '@angular/core';
import { TextService } from '../../services/text.service';
import { Router } from '@angular/router';
import { ParkingGDPRComponent } from '../createparking/createparking.component';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-ForcedTerms',
  templateUrl: './ForcedTerms.component.html',
  styleUrls: ['./ForcedTerms.component.css']
})
export class ForcedTermsComponent implements OnInit {

  public termsText: string;

  constructor(private textService: TextService,
    public router: Router,
    public dialogRef: MatDialogRef<ParkingGDPRComponent>
    ) {
      dialogRef.disableClose = true;
      textService.getText('termsandagree').subscribe((text) => this.termsText = text);
  }

  ngOnInit() {
  }
  ok() {
    this.dialogRef.close();
  }

  cancel() {
    console.log("Hit")
    this.dialogRef.close();
    this.router.navigate(['/']);
  }

}
