import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mybrobizz',
  templateUrl: './mybrobizz.component.html',
  styleUrls: ['./mybrobizz.component.css']
})
export class MybrobizzComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
