import { Component, OnInit, Input, Inject } from '@angular/core';
import { VehicleregistrationService } from 'src/app/services/vehicleregistration.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractAddCarComponent } from './contractaddcar.component';
import{ ChangeDetectorRef } from '@angular/core';

export interface DialogData {
    permit: number;
  }

@Component({
    selector: 'app-contractpickcar',
    templateUrl: './contractpickcar.component.html',
    styleUrls: ['./contractpickcar.component.css']
})

export class ContractPickCarComponent implements OnInit {
    favoriteCars = [];
    secondaryCars = [];
    contractCars = [];
    permit = 0;

    constructor(
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ContractPickCarComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private vehicleService: VehicleregistrationService,
        private cdRef: ChangeDetectorRef ) {
        // dialog.afterAllClosed.subscribe(() => {
        //     this.getCars();
        // });
    }

    ngOnInit() {
        this.cdRef.detectChanges();
        this.permit = this.data.permit;
        this.contractCars = JSON.parse(localStorage.getItem(`contractCars`)) != null ?
         JSON.parse(localStorage.getItem(`contractCars`)) : [];

         this.getCars();
         this.cdRef.detectChanges();
    }

    getCars() {
        this.vehicleService.registrations().subscribe(result => {
          this.favoriteCars = [];
          this.secondaryCars = [];
          for (const i in result) {
            if (result[i].isPrimary === true) {
              this.favoriteCars.push({obj: result[i], selected: this.contractCars.includes(result[i].typed)});
            } else {
              this.secondaryCars.push({obj: result[i], selected: this.contractCars.includes(result[i].typed)});
            }
          }
        });
    }

    createCar() {
        this.dialog.open(ContractAddCarComponent, {}).afterClosed().subscribe(() => {
           this.getCars();
        });
    }

    next() {
        localStorage.setItem(`contractCars`, JSON.stringify(this.contractCars));
        this.dialogRef.close(true);
    }

    selectCar(event, selections) {
        const key = event.option.value;
       
        this.favoriteCars.forEach(element => {
            if (element.obj.typed === key) {
                if (this.contractCars.includes(key)) {
                    element.selected = false;
                } else {
                    element.selected = true;
                }
            }
        });
        this.secondaryCars.forEach(element => {
            if (element.obj.typed === key) {
                if (this.contractCars.includes(key)) {
                    element.selected = false;
                } else {
                    element.selected = true;
                }
            }
        });

        if (!this.contractCars.includes(key)) {
            this.contractCars.push(key);
        } else {
            const index = this.contractCars.indexOf(key);
            if (index > -1) {
                this.contractCars.splice(index, 1);
            }
        }
        this.cdRef.detectChanges();
    }
}
