import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService} from './auth.service';

import { IDiscountCard, DiscountCardItemType } from '../models/discountcard';
import { IRegisterDiscountCardResponse } from '../models/registerDiscoutCardResponse';
import { ICustomerDiscountCard } from '../models/customerDiscountCard';
import { IDiscount } from '../models/discount';

@Injectable({
  providedIn: 'root'
})
export class DiscountcardService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  list(): Observable<IDiscountCard[]> {
    return this.http.get<IDiscountCard[]>(environment.apiBaseUrl + `discount/card`, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    }).pipe(map(cards =>
        cards.filter(item => item.type === DiscountCardItemType.Group || (item.type === DiscountCardItemType.Card && !item.groupId))
      )
    );
  }

  register(itemType: DiscountCardItemType, itemId: number, validationKey?: string): Observable<IRegisterDiscountCardResponse> {
    return this.http.post<IRegisterDiscountCardResponse>(environment.apiBaseUrl + `discount/card`, {
      itemId: itemId,
      type: itemType,
      validationKey: validationKey
    }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  unregister(cardId: number): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + `discount/card/customer/${cardId}`, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  customerCards(): Observable<ICustomerDiscountCard[]> {
    return this.http.get<ICustomerDiscountCard[]>(environment.apiBaseUrl + `discount/card/customer`, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  discounts(cardId: number): Observable<IDiscount[]> {
    return this.http.get<IDiscount[]>(environment.apiBaseUrl + `discount/card/${cardId}/discounts`, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }
}
