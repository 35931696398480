import { DateTime } from 'luxon';
export enum ContractProductPeriod {
  Week = 1,
  Month = 2,
  ThreeMonths = 3,
  SixMonths = 4,
  Year = 5,
  Day = 6,
  TwoDays = 7,
  ThreeDays = 8,
  FourDays = 9,
  FiveDays = 10,
  SixDays = 11,
  TwoWeeks = 12,
  ThreeWeeks = 13

}

export class IContract {
        id: number;
        uId: string;
        createdDate: DateTime;
        updatedDate: DateTime;
        deletedDate: DateTime;
        parkingAreaKey: string;
        vehicleRegistrationCountry: string;
        vehicleRegistrationTyped: string;
        vehicleRegistrationKey: string;
        subjectId: string;
        productId: number;
        periodType: number;
        validFrom: DateTime;
        validTo?: DateTime;
        autoRenew: boolean;
        canExtend: boolean;
        paymentUId: string;
        nextPaymentDate?: DateTime;
        lastPaymentDate?: DateTime;
        cancelledDate?: DateTime;
        subAreas: string[];
}

export class ICreateContractParking {
    productid: number;
    parkingAreaKey: string;
    validFrom: DateTime;
    vehicleRegistration: string;
    vehicleRegistrationCountry: string;
    paymentUid: string;
    autoRenew: boolean;
    code: string;
}

export class IContractParking {
    id: number;
    uId: string;
    parkingAreaKey: string;
    vehicleRegistrationCountry: string;
    vehicleRegistrationTyped: string;
    vehicleRegistrationKey: string;
    subjectId: string;
    productId: number;
    periodType: number;
    validFrom: DateTime;
    validTo?: DateTime;
    autoRenew: boolean;
    paymentUId: string;
    nextPaymentDate?: DateTime;
    lastPaymentDate: DateTime;
    cancelledDate?: DateTime;
}

export class IAvability {
    isAvailable: boolean;
    availableLeft: number;
}

export class IProduct {
    id: number;
    title: string;
    description: string;
    price: number;
    iconUrl: string;
    type: ContractProductPeriod;
    renewable: boolean;
    created: DateTime;
    updated?: DateTime;
    deleted?: DateTime;
    disabled: boolean;
    locked: boolean;
    subAreas: string[] = [];
}

export class IVehicleRegistration {
    timeStamp: DateTime;
    vehicleRegistration: string;
    from: DateTime;
}

export class IContractValidFrom {
    start: DateTime;
}

export class IContractPaymentMethod {
    paymentUid: string;
}

export class IContractAvaliable {
    available: boolean;
}
