import { Component } from '@angular/core';

@Component({
    selector: 'app-resetpasswordapp',
    templateUrl: 'resetpasswordapp.component.html',
})
  // tslint:disable-next-line:component-class-suffix
export class ResetPasswordAppComponent {

constructor() { }
}
