import { Component, OnInit, Inject } from '@angular/core';
import {FacilityService} from '../../services/facility.service';
import {VoucherService} from '../../services/voucher.service';
import {AccountService} from '../../services/account.service';
import {DiscountcardService} from '../../services/discountcard.service';
import { DiscountCardItemType, IDiscountCard } from '../../models/discountcard';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {
availBalance: number;
cards = [];
addCards = [];
code: string;
  constructor(private voucherService: VoucherService, private accountService: AccountService,
     private discountCardService: DiscountcardService, public dialog: MatDialog) { }

  ngOnInit() {
   this.updateBalance();
   this.updateCards();
  }


  viewCard(id: number) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '500px',
      data: { id : id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updateCards();
    });
  }

  updateBalance() {
    const that = this;
    this.accountService.getAccountInfo().subscribe(x => {
        that.availBalance = (x['availableBalance']);
    });
  }

  addBonus() {
    this.voucherService.redeem(this.code).subscribe(x => {
      this.updateBalance();
    });
  }

  addVoucher() {
    const dialogRef = this.dialog.open(VoucherDialogComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updateCards();
    });
  }

  deleteVoucher(cardId: number) {
    this.discountCardService.unregister(cardId).subscribe(x => {
      this.updateCards();
    });
  }

  updateCards() {
    const that = this;
    this.cards = [];
    this.addCards = [];

    this.discountCardService.list().subscribe(x => {
        // tslint:disable-next-line:forin
        for (const i in x) {
          this.addCards.push(x[i]);
        }
    });
    this.discountCardService.customerCards().subscribe(x => {
      // tslint:disable-next-line:forin
      for (const i in x) {
        this.cards.push(x[i]);
      }
    });
  }
}

/*dialog*/
@Component({
  selector: 'app-voucherdialog-dialog',
  templateUrl: 'addCard.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherDialogComponent implements OnInit {

  cards = [];
  cardGroups = [];
  cardStep = true;
  cardTitle = 'Kortnummer';
  currentCard: IDiscountCard;
  isGroup = false;

  cardNumber = new FormControl('cardNumber', [
    Validators.required
  ]);

  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<VoucherDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private discountCardService: DiscountcardService
    ) {
      discountCardService.list().subscribe(x => {
          // tslint:disable-next-line:forin
        for (const i in x) {
          this.cards.push(x[i]);
          console.log(x[i]);
        }
      });
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

 addCard(card: IDiscountCard) {
  this.cardStep = false;
  this.cardTitle = card.validationKeyText;
  this.currentCard = card;
  if(card.groupSelectable != null){
    this.isGroup = !card.groupSelectable;
  } else{
    this.isGroup = false;
  }
  console.log(this.currentCard)
 }


 saveCard() {
  let itemType;

  if (this.currentCard.groupSelectable) {
    itemType = DiscountCardItemType.Group;
  } else {
    itemType = DiscountCardItemType.Card;
  }

   this.discountCardService.register(itemType, this.currentCard.id, this.cardNumber.value).subscribe(x => {
    this.dialogRef.close();
   }, error => {
    this.dialogRef.close();
   });
 }

}
/*dialog*/
@Component({
  selector: 'app-infodialog-dialog',
  templateUrl: 'viewCard.html',
  styleUrls: ['./voucher.component.css']
})

export class InfoDialogComponent implements OnInit {
  title = 'Discount';
  discount = '';
  areas = [];

  constructor(
    public dialogRef: MatDialogRef<VoucherDialogComponent>,
    private discountCardService: DiscountcardService,
    private facilityService: FacilityService,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    ) {}

  ngOnInit() {
    this.discountCardService.discounts(this.data.id).subscribe(x => {
        // tslint:disable-next-line:forin
        for (const i in x) {
          let valueType;

          switch (x[i].discountType) {
            case 0:
              valueType = '%';
              break;

            case 1:
              valueType = '';
              break;

            case 2:
              valueType = 'kr.';
              break;
          }

          if (x[i].allAreas === true) {
            this.areas.push('Alle pladser');
          } else {
            this.facilityService.getFacilities('ADK').subscribe(res => {
              // tslint:disable-next-line:forin
              for (let j in res) {
                for (let k in x[i].areaIds) {
                  if (res[j].id === x[i].areaIds[k]) {
                    this.areas.push(res[j]);
                  }
                }
              }
            });
        }
        this.discount = x[i].value + ' ' + valueType;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
