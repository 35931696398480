import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-contactsuccess',
    templateUrl: './contactsuccess.component.html',
    styleUrls: ['./contactsuccess.component.css']
  })
  export class ContactSuccessComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ContactSuccessComponent>) {}

    ngOnInit() {
    }

    ok() {
      this.dialogRef.close();
    }
  }
