// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.

// FOR TEST ENVIRONMENT
// export const environment = {
//   production: false,
//   apiBaseUrl: 'https://apitest.mobilparkering.dk/v9/',
// //   apiBaseUrl: 'http://localhost:1252/v9/',
//   loginAuthority : 'https://login-mobilparkering-test.azurewebsites.net/',
// //   loginAuthority : 'http://localhost:57097/',
//   loginRedirectUri: '/signin/?',
//   postLogoutRedirectUri: '/?',
//   epayMerchantNumber: 8885333,
//   epayMD5Secret: 'SecretMD5Key',
//   epayCallbackUrl: 'https://api.mobile-parking.eu/providers/epay/ADK/',
//   host: 'https://www.apcoaflow.dk',
//  mapApiKey: 'AIzaSyANaF82IJEz7hTn8G4-UxJ6ADjwTRZ7np4',

// //   mapApiKey: 'AIzaSyBYUj1mlXaJkUSd51ZRzqVMjH0mRdYR5cw',
//   epayCss: 'http://mobilparkering.on-limited.dk/css/epay.css',
//   contractUrl: 'https://udlejning.mobilparkering.dk',
// DMRUrl: 'http://dmr.mobilparkering.on-limited.dk/V1/VehicleRegistration'

// };

// FOR PRODUCTION ENVIRONMENT
// export const environment = {
// production: false,
//   apiBaseUrl: 'https://api.mobile-parking.eu/v9/',
// // apiBaseUrl: 'http://localhost:1252/v8/',
// loginAuthority : 'http://login.mobilparkering.on-limited.dk/',
// // loginAuthority : 'http://localhost:57097/',
// loginRedirectUri: '/signin/?',
// postLogoutRedirectUri: '/?',
// epayMerchantNumber: 8885333,
// epayMD5Secret: 'SecretMD5Key',
// epayCallbackUrl: 'http://newapi.mobilparkering.on-limited.dk/providers/epay/ADK/',
// host: 'http://mobilparkering-staging.azurewebsites.net',
// mapApiKey: 'AIzaSyANaF82IJEz7hTn8G4-UxJ6ADjwTRZ7np4',
// epayCss: 'http://mobilparkering.on-limited.dk/css/epay.css',
// contractUrl: 'https://udlejning.mobilparkering.dk',
// DMRUrl: 'http://dmr.mobilparkering.on-limited.dk/V1/VehicleRegistration'
// };

// FOR PRODUCTION ENVIRONMENT

export const environment = {
 production: true,
 apiBaseUrl: 'https://api.mobile-parking.eu/v9/',
 loginAuthority : 'https://login.apcoaflow.dk/',
 loginRedirectUri: '/signin/?',
 postLogoutRedirectUri: '/?',
 epayMerchantNumber: 5317649,
 epayMD5Secret: 'SecretMD5Key',
 epayCallbackUrl: 'https://api.mobile-parking.eu/providers/epay/ADK/',
 host: 'https://www.apcoaflow.dk',
 mapApiKey: 'AIzaSyANaF82IJEz7hTn8G4-UxJ6ADjwTRZ7np4',
 DMRUrl: 'https://dmr.mobilparkering.dk/V1/VehicleRegistration',
 contractUrl: 'https://udlejning.mobilparkering.dk',
 epayCss: 'https://apcoaflow.dk/css/epay.css'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error'; // Included with Angular CLI.
