import { Component, Inject } from '@angular/core';
import { PromoService } from '../../services/promo.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.css']
})
export class PromoComponent {

  public promoText: string;
  dontShowAgain = false;
  promoId: number;

  constructor(private promoService: PromoService, public dialogRef: MatDialogRef<PromoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.promoService.getActivePromos().subscribe(promo => {
      this.promoId = promo[data.number].Id;
      this.promoText = promo[data.number].Message;
    });
  }

  ok () {
    if (this.dontShowAgain === true) {
      localStorage.setItem(`promo:${this.promoId}`, `${this.promoId}`);
    }

    this.dialogRef.close();
  }
}
