import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { DateTime } from 'luxon';

import { IParkingFacility } from '../models/parkingFacility';
import { IPriceResult } from '../models/priceResult';

import { AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {
  private _url = 'assets/test.json';

  constructor(private http: HttpClient, private authService: AuthService) {

  }

  public getFacilities(providerKey?: string): Observable<IParkingFacility[]> {
    return this.http.get<IParkingFacility[]>(environment.apiBaseUrl + 'parkingareas');
  }

  public getFacility(providerKey: string, facilityKey: string): Observable<IParkingFacility> {
    return Observable.create(observer => {
      this.getFacilities(providerKey)
      .subscribe(facilities => {
        for (const facility of facilities) {
          if (facility.countryCode.toUpperCase() === providerKey.toUpperCase() && facility.key === facilityKey) {
            observer.next(facility);
            break;
          }
        }
        observer.complete();
      });
    });
  }

  public getPrice(
    facilityKey: string,
    startTime: DateTime,
    endTime: DateTime,
    extension: boolean = false,
    fee: any = null,
    voucherCode: string = null,
    paymentUid: string = null,
    vehicleRegistration: string = null
    ): Observable<IPriceResult> {

    const timeFormat = 'yyyy-MM-dd HH:mm:ss';
    let url = environment.apiBaseUrl +
      `parkingareas/${facilityKey}/Price?` +
      `startTime=${startTime.toFormat(timeFormat)}&endTime=${endTime.toFormat(timeFormat)}&forExtend=${extension}`;

    if (voucherCode) {
      url += `&voucher=${voucherCode}`;
    }
    if (paymentUid) {
      url += `&paymentUid=${paymentUid}`;
    }
    if(vehicleRegistration) {
      url += `&vehicleRegistrationKey=DK-${vehicleRegistration}`
    }

    if (this.authService.isAuthenticated()) {
      return this.http.get<IPriceResult>(url, {
        headers: {
          'Authorization': `Bearer ${this.authService.getAuthHeader()}`
        }
      });
    } else {
      console.log(this.http.get<IPriceResult>(url));
      return this.http.get<IPriceResult>(url);

        // .pipe(map<{Voucher: string,
        //   HasDiscount: boolean,
        //   Discount: number,
        //   PriceWithoutDiscount: number,
        //   TotalPrice: number,
        //   EndTime: string,
        //   EndTimeExtendedTo?: DateTime,
        //   IsEndTimeExtended: boolean,
        //   IsExtension: boolean,
        //   Number: string,
        //   Price: number,
        //   StartTime: string,
        //   Fee?: number}, IPriceResult>(data => {
        //   return {
        //     Discount: data.Discount,
        //     EndTime: DateTime.fromISO(data.EndTime),
        //     EndTimeExtendedTo: data.EndTimeExtendedTo,
        //     Fee: data.Fee,
        //     HasDiscount: data.HasDiscount,
        //     IsEndTimeExtended: data.IsEndTimeExtended,
        //     IsExtension: data.IsExtension,
        //     Number: data.Number,
        //     Price: data.Price,
        //     PriceWithoutDiscount: data.PriceWithoutDiscount,
        //     StartTime: DateTime.fromISO(data.StartTime),
        //     TotalPrice: data.TotalPrice,
        //     Voucher: data.Voucher
        //   };
        // }));
    }
  }
}
