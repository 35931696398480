import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PaymentsubscriptionService } from '../../services/paymentsubscription.service';
import { error } from '@angular/compiler/src/util';
import { ActivatedRoute } from '@angular/router';

let companyCard : string;

@Component({
    selector: 'organization-dialog',
    templateUrl: './organization-dialog.component.html',
    styleUrls: ['./organization-dialog.component.css']
  })
  

export class OrganizationDialogComponent implements OnInit {
constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private paymentsubscriptionService: PaymentsubscriptionService,
    public dialogRef: MatDialogRef<OrganizationDialogComponent>
    ) {}
    ngOnInit() {
        companyCard = this.route.snapshot.queryParams["companyCard"];
        if (!companyCard){
            companyCard = "";
        }
        this.organizationForm.controls['code'].setValue(companyCard);
    }
            organizationForm = this.fb.group({
                code: [companyCard, Validators.required]
              });

    onSubmit() {
        if (this.organizationForm.valid) {
            this.paymentsubscriptionService.claimOrganizationCode(this.organizationForm.value.code).subscribe(
                x => this.dialogRef.close(),
                err => {
                    if (err.errorCode == 352) {
                        this.organizationForm.get('code').setErrors({"error": "Koden er ikke gyldig, prøv igen."});
                    }
                    else {
                        this.organizationForm.get('code').setErrors({"error": "Koden er ikke gyldig. Den indtastede kode til Erhvervsparkering er ikke gyldig. Tjek at koden er indtastet korrekt og prøv igen. Kontakt din virksomhed, hvis koden er korrekt indtastet og stadig ikke gyldig"});
                    }
                }
            )
        }
    }
    get code() { return this.organizationForm.get('code'); }
}