import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-contractchangedate',
  templateUrl: './contractchangedate.component.html',
  styleUrls: ['./contractchangedate.component.css']
})
export class ContractChangeDateComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ContractChangeDateComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
    }
    ngOnInit() {

    }

    onNoClick(): void {
      this.dialogRef.close();
    }
}
