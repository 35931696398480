import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './services/error.service';

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {

    constructor(private errorService: ErrorService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {

            console.log(error);
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred. Handle it accordingly.
                console.error('An error occurred:', error.error.message);
              } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                console.error(
                  `Backend returned code ${error.status}, ` +
                  `body was: ${error.error}`);

                if (error.status === 400) {
                    this.errorService.sendError(error.error);
                } else if (error.status === 401) {
                    this.errorService.sendError({errorCode: -1, errorMessage: 'Unauthorized'});
                } else if (error.status === 403) {
                    this.errorService.sendError({errorCode: -2, errorMessage: 'Forbidden'});
                } else if (req.headers.get('X-skipErrors')) {
                    return throwError('error');
                } else {
                    this.errorService.sendError({errorCode: 1, errorMessage: 'Unknown error'});
                }
              }
              
              console.log(req.headers);
              return throwError('error');
        }));
    }
}
