import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-changepasswordsuccess',
    templateUrl: './changepasswordsuccess.component.html'
  })
  export class ChangePasswordSuccessComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ChangePasswordSuccessComponent>) {}

    ngOnInit() {
    }

    ok() {
      this.dialogRef.close();
    }
  }
