import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { EpayService } from '../../services/epay.service';
import { BrobizzService } from '../../services/brobizz.service';
import { PaymentsubscriptionService } from '../../services/paymentsubscription.service';
import { DateTime } from 'luxon';
import { PaymentSubscriptionType, IPaymentSubscription } from '../../models/paymentsubscription';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MeansPaymentDialogComponent } from './paymentdialog.component';
import { OrganizationDialogComponent } from './organization-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NotifyMethod, IEnrollmentCustomerAgreement } from '../../models/brobizz';
import { MatStepper, MatSelectionList, MatListOption, MatSelectionListChange } from '@angular/material';
import { UserService } from '../../services/user.service';
import { SelectionModel } from '@angular/cdk/collections';
let companyCard : string;
@Component({
  selector: 'app-mymeanspayment',
  templateUrl: './mymeanspayment.component.html',
  styleUrls: ['./mymeanspayment.component.css']
})
export class MymeanspaymentComponent implements OnInit {
  hasPrimary = false;
  other = [];
  primary;
  cookie: string;

  constructor(
    private authService: AuthService,
    private paymentsubscriptionService: PaymentsubscriptionService,
    private epayService: EpayService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    companyCard = this.route.snapshot.queryParams["companyCard"];
    if(companyCard)
    {
      this.addEmployeeParking();
    }
    const urlParams = new URLSearchParams(window.location.search);
    const url = 'https://ssl.ditonlinebetalingssystem.dk/integration/ewindow/paymentwindow.js';
    this.loadScript(url);
    this.getCreditCards();

    if (urlParams.has('orderid')) {
      this.authService.authenticate('profile/paymentsubscription');
    }

    if (localStorage.getItem('newAccount')) {
      this.cookie = JSON.stringify(localStorage.getItem('newAccount'));
    }

    this.dialog.afterAllClosed.subscribe(res => {
      this.getCreditCards();
    });
  }

  public loadScript(url: string) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
}


togglePrimary(paymentUId: string) {
  this.paymentsubscriptionService.setSubscriptionAsDefault(paymentUId).subscribe(x => {
    this.getCreditCards();
  });
}


addEmployeeParking() {
  this.dialog.open(OrganizationDialogComponent, { width: '400px', disableClose: true })
  .afterClosed().subscribe(res => {
    this.getCreditCards();
  });
}

deleteCreditCard(paymentUId: string) {
  this.dialog.open(DeleteCardDialogComponent, {
    data: { paymentUId : paymentUId },
    disableClose: true
  }).afterClosed().subscribe(res => {
    this.getCreditCards();
  });
}

getCreditCards() {
  this.paymentsubscriptionService.activeSubscriptions().subscribe(result => {
    this.other = [];
    result.forEach(value => {
      if (value.isDefault ) {
        this.hasPrimary = true;
        this.primary = value;
      } else { // add this to the default one
        this.other.push(value);
      }
    })
  });
}

addBrobizz() {
  this.dialog.open(BrobizzDialogComponent, { width: '400px', disableClose: true });
}

  addCreditCard() {

      if (!this.authService.getSubjectId()) {
        return false;
      }

    const options = {
      countryCode: this.authService.getCountryCode(),
      orderId: `CC${('0000000' + this.authService.getSubjectId()).slice(-7)}${DateTime.utc().toFormat('yyMMddHHmm')}`,
      amount: 0,
      subscription: true,
      instantCallback: true,
      callbackParams: {
        'type': 5,
        'sub': this.authService.getSubjectId()
      },
      instantCapture: false
    };

    const opt: any = {
        merchantnumber: environment.epayMerchantNumber,
        currency: 'DKK',
        amount: Math.floor(options.amount * 100),
        orderid: options.orderId,
        mobile: 1,
        accepturl: `${environment.host}/profile/paymentsubscription`,
        subscription: options.subscription === true ? 1 : 0,
        instantcapture: options.instantCapture === true ? 1 : 0,
        instantcallback: options.instantCallback === true ? 1 : 0,
        windowstate: 3,
        subscriptiontype: 'cardonfile'
        // iframeheight: 420,
        // iframewidth: '100%',
        // cssurl: environment.epayCss
      };

      let callbackUrl = environment.epayCallbackUrl;

      if (options.callbackParams) {
        callbackUrl += '?';

        // tslint:disable-next-line:forin
        for (const key in options.callbackParams) {
          const value = options.callbackParams[key];
          callbackUrl += `${key}=${value}&`;
        }
      }

      opt.callbackurl = callbackUrl;

      this.epayService.hashParams(opt).subscribe(res => {
        opt.hash = res.hash;

        const paymentWindow = new PaymentWindow(opt);
        paymentWindow.open();
      });

    // this.dialog.open(MeansPaymentDialogComponent, {
    //   disableClose: true,
    //   width: '450px',
    //   height: '540px'
    // }).afterOpen().subscribe(x => {
    //   const opt: any = {
    //     merchantnumber: environment.epayMerchantNumber,
    //     currency: 'DKK',
    //     amount: Math.floor(options.amount * 100),
    //     orderid: options.orderId,
    //     mobile: 0,
    //     accepturl: `${environment.host}/profile/paymentsubscription`,
    //     subscription: options.subscription === true ? 1 : 0,
    //     instantcapture: options.instantCapture === true ? 1 : 0,
    //     instantcallback: options.instantCallback === true ? 1 : 0,
    //     windowstate: 4,
    //     iframeheight: 420,
    //     iframewidth: '100%',
    //     cssurl: environment.epayCss
    //   };

    //   let callbackUrl = environment.epayCallbackUrl;

    //   if (options.callbackParams) {
    //     callbackUrl += '?';

    //     // tslint:disable-next-line:forin
    //     for (const key in options.callbackParams) {
    //       const value = options.callbackParams[key];
    //       callbackUrl += `${key}=${value}&`;
    //     }
    //   }

    //   opt.callbackurl = callbackUrl;

    //   this.epayService.hashParams(opt).subscribe(res => {
    //     opt.hash = res.hash;

    //     const paymentWindow = new PaymentWindow(opt);
    //     paymentWindow.append('payment-div');
    //     paymentWindow.open();
    //   });
    //});
}
  next() {
    this.router.navigate(['profile/mycars']);
  }
}

@Component({
  selector: 'app-deletecard-dialog',
  templateUrl: './deletecard-dialog.component.html',
  styleUrls: ['./deletecard-dialog.component.css']
})

export class DeleteCardDialogComponent implements OnInit {
private paymentsubscriptionService: PaymentsubscriptionService;
card: IPaymentSubscription;
paymentUId: string;
type: number;
brand: string;
number: string;
expires: DateTime;
agreementType: number;
@Output() updateView = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<DeleteCardDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router, private paymentSubscriptionService: PaymentsubscriptionService) {
      this.paymentsubscriptionService = paymentSubscriptionService;
     }

  ngOnInit() {
    this.paymentUId = this.data.paymentUId;
    this.getCard();
  }

  getCard() {
    this.paymentsubscriptionService.activeSubscriptions().subscribe(result => {
      this.card = result.find(x => x.paymentUId === this.paymentUId);

      this.brand = this.card.displayName;
      this.type = this.card.type;
      this.number = this.card.detailText;
      this.expires = this.card.expireDate;
    });
  }

  regret(): void {
    this.dialogRef.close();
  }

  deleteCard() {
    this.paymentsubscriptionService.deleteSubscription(this.paymentUId).subscribe(x => {
      this.updateView.emit();
      this.dialogRef.close();
    });
  }
}

enum Contact {
  Email,
  Mobile
}

@Component({
  selector: 'brobizz-dialog',
  templateUrl: './brobizz-dialog.component.html',
  styleUrls: ['./brobizz-dialog.component.css']
})
export class BrobizzDialogComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private broBizzService: BrobizzService,
    private userService: UserService
  ) {}

  form: FormGroup;
  userContacts: {name: string, type: Contact }[] = this.getUser();
  isValid = true;
  error: {msg: string, success: boolean } = {msg: 'Denne e-mail er ikke tilknyttet BroBizz', success: false };
  @ViewChild(MatSelectionList) contacts: MatSelectionList;

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(''),
      selectedOptions: new FormControl()
    });
    this.contacts.selectedOptions = new SelectionModel<MatListOption>(false);
  }


  getUser(): {name: string, type: Contact}[] {
    let obj = [];

    this.userService.getUserDetails().subscribe(x => {
      if (x.email != null) {
        obj.push({name: x.email, type: Contact.Email});
      }
      // if (x.number != null) {
      //   obj.push({name: x.number, type: Contact.Mobile});
      // }
    });
    return obj;
  }

  validate() {
    if (this.form.get('selectedOptions').value != null && this.form.get('selectedOptions').value.length > 0) {
      this.isValid = false;
      switch (this.form.get('selectedOptions').value[0].type) {
        case Contact.Email:
            this.broBizzService.validateEmail(this.form.get('selectedOptions').value[0].name).subscribe(x => {
              this.broBizzService.enroll({customerNumber: x.customerNumber, notifyMethod: NotifyMethod.Email}).subscribe(res => {
                this.dialog.closeAll();
                this.dialog.open(BrobizzDialogComponent1, {
                width: '400px',
                disableClose: true,
                data: { token : res.token }
              });
              this.error.success = false;
              this.isValid = true;
              }
            )},
            error => {
              this.contacts.deselectAll();
              this.error.success = true;
              this.isValid = true;
            });
        break;
        case Contact.Mobile:
          this.broBizzService.validateMobile({mobileNumberCountry: '+45', mobileNumber: this.form.get('selectedOptions').value[0].name})
          .subscribe(res => {
            this.broBizzService.enroll({customerNumber: res.customerNumber, notifyMethod: NotifyMethod.Sms}).subscribe(x => {
              this.dialog.closeAll();
              this.dialog.open(BrobizzDialogComponent1, {
                width: '400px',
                disableClose: true,
                data: { token : x.token }
              });
            });
          },
          error => {
            this.contacts.deselectAll();
            this.error.success = true;
            this.isValid = true;
          });
        break;
      }
    }
    if (this.form.get('email').valid && this.form.get('email').value.length > 0) {
      this.isValid = false;
      this.broBizzService.validateEmail(this.form.get('email').value).subscribe(x => {
        this.broBizzService.enroll({customerNumber: x.customerNumber, notifyMethod: NotifyMethod.Email}).subscribe(res => {
          this.dialog.closeAll();
          this.dialog.open(BrobizzDialogComponent1, {
            width: '400px',
            disableClose: true,
            data: { token : res.token }
          });
        });
      },
      error => {
          this.error.success = true;
          this.isValid = true;
        
      });
    }
  }
}

@Component({
  selector: 'brobizz-dialog1',
  templateUrl: './brobizz-dialog.component.1.html',
  styleUrls: ['./brobizz-dialog.component.css']
})

export class BrobizzDialogComponent1 implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private broBizzService: BrobizzService,
  ) {}

  isValid = true;
  error: {msg: string, success: boolean } = {msg: 'Vi har sendt dig et aktiveringslink', success: false };

  ngOnInit() {
  }

  enrollVerifycation() {
    this.isValid = false;
    this.broBizzService.agreements(this.data.token).subscribe(x => {
      this.dialog.closeAll();
      this.dialog.open(BrobizzDialogComponent2, {
        width: '400px',
        id: 'test',
        disableClose: true,
        data: { customerAgreements : x, token: this.data.token }
      });
      this.error.success = false;
      },
      error => {
        this.error.success = true;
        this.isValid = true;
      }
    );
  }
}

@Component({
  selector: 'brobizz-dialog2',
  templateUrl: './brobizz-dialog.component.2.html',
  styleUrls: ['./brobizz-dialog.component.css']
})

export class BrobizzDialogComponent2 implements OnInit, AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {token: string, customerAgreements: IEnrollmentCustomerAgreement[]},
    private dialog: MatDialog,
    private broBizzService: BrobizzService,
    private paymentsubscriptionService: PaymentsubscriptionService
  ) {}
  isValid = true;
  error: {msg: string, success: boolean } = {msg: 'Der skete desværre en fejl.', success: false };

  form: FormGroup;
  payByPlateAgreements: IEnrollmentCustomerAgreement[] = this.data.customerAgreements.filter(x => { return x.agreementType === 1});
  brobizzAgreements: IEnrollmentCustomerAgreement[] = this.data.customerAgreements.filter(x => { return x.agreementType === 0});
  agreementId: string;

  @ViewChild(MatSelectionList) agreements: MatSelectionList;
  @ViewChild(MatSelectionList) agreements2: MatSelectionList;

  ngOnInit() {
    this.form = new FormGroup({
      selectedOptions: new FormControl()
    });
  }

  ngAfterViewInit(): void {
  }

  authorize() {
    if (this.form.get('selectedOptions').value != null && this.form.get('selectedOptions').value.length > 0) {
      this.isValid = false;
      this.broBizzService.authorize(this.data.token, this.form.get('selectedOptions').value[0]).subscribe(x => {
        this.dialog.closeAll();
        this.dialog.open(BrobizzDialogComponent3, {
          width: '400px',
          disableClose: true
        });
        this.paymentsubscriptionService.paymentSubscriptionsChanged.next();
        this.error.success = true;
        this.isValid = true;
      },
      error => {
        this.agreements.deselectAll();
        this.agreements2.deselectAll();
        this.error.success = true;
        this.isValid = true;
      });
    }
  }
}

@Component({
  selector: 'brobizz-dialog3',
  templateUrl: './brobizz-dialog.component.3.html',
  styleUrls: ['./brobizz-dialog.component.css']
})

export class BrobizzDialogComponent3 implements OnInit {
  constructor(private paymentsubscriptionService: PaymentsubscriptionService) {
  }


  ngOnInit() {
  }

  reload() {
    this.paymentsubscriptionService.activeSubscriptions().subscribe();
  }
}
