import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, HostListener } from '@angular/core';
import { MouseEvent, AgmMap, MapTypeStyle } from '@agm/core';
import { FacilityService } from '../../services/facility.service';
import { ClusterStyle, ClusterOptions } from '@agm/js-marker-clusterer/services/google-clusterer-types';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import { CurrentparkingService } from '../../services/currentparking.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentParking } from '../../models/currentparking';
import { GeocodeService } from '../../services/geocode.service';
import { LoadingService } from '../../services/loading.service';
import { PromoService } from '../../services/promo.service';
import { PromoComponent } from '../promo/promo.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { areaType, IParkingFacility } from 'src/app/models/parkingFacility';
import { AuthService } from 'src/app/services/auth.service';
import { VehicleregistrationService } from 'src/app/services/vehicleregistration.service';
import { PaymentsubscriptionService } from 'src/app/services/paymentsubscription.service';
import { MapOptions, MapTypeControlOptions } from '@agm/core/services/google-maps-types';
import { ParkSliderComponent, isClosingTimeFromToValid } from '../parkslider/parkslider.component';
import { MapQuickParkComponent } from './mapquickpark.component';
import { MapANPRParkingComponent } from './mapanprparking.component';
import { MapClosingHoursComponent } from './mapclosinghours.component';
import { environment } from '../../../environments/environment';
import { DateTime } from 'luxon';
import { IPromo } from 'src/app/models/promo';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit {
  private subscription: any;
  private currentArea: any;
  private currentFacility: any;
  private currentFacilitys: IParkingFacility[];
  isMobileView: boolean;
  public markers = [];
  public zones = [];
  public polygons = [];
  location: {lat: number, lng: number};
  lat: number;
  lng: number;
  userLat: number;
  userLng: number;
  showNotification = false;
  areaName = '';
  area = '';
  areaKey: string;
  provider: string;
  parkingZone: string;
  hasContract = false;
  isAnpr = false;
  areaHasInformation = false;
  dir = undefined;
  public show = false;
  geoJsonObject: Object;
  isZone = false;
  areaProvider: string;
  zoom: number;
  markerClicked = false;
  polyVisible = true;
  createParking = false;
  zoomVal = 16;
  geoEnabled = false;

  @ViewChild('agmMap') public agmMap: AgmMap;
  @ViewChild('parkSlider') public parkSlider: ElementRef<ParkSliderComponent>;

  myControl: FormControl = new FormControl();
  public searchFacilities = [];
  public filteredSearchFacilities = [];

  public mapOptions: MapOptions[] = [
    {
      gestureHandling: 'greedy'
    }
  ];

  public mapStyles: MapTypeStyle[] = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    }
  ];

  public clusterStyles: ClusterStyle[] = [
    {
      anchor: [10, 18],
      textSize: 12,
      textColor: '#000000',
      url: 'assets/images/m1.png',
      height: 53,
      width: 65
    }
  ];

  public options = {
    suppressMarkers: true,
    draggable: false,
    polylineOptions : {
      strokeColor: '#0C3559'
    }
  };

  public polyOptions = {
    draggable: false,
    editable: false
  };

  constructor(
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private authService: AuthService,
    private facilityService: FacilityService,
    private vehicleService: VehicleregistrationService,
    private paymentService: PaymentsubscriptionService,
    private currentParkings: CurrentparkingService,
    private currentParkingService: CurrentparkingService,
    private router: Router,
    private media: ObservableMedia,
    private geocodeService: GeocodeService,
    private loadingService: LoadingService,
    private promoService: PromoService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef) {
      this.subscription = this.route.params.subscribe(params => {
        if (sessionStorage.getItem('endTime') !== null) {
          if (params['areaKey'] !== undefined) {
            this.areaKey = params['areaKey'];
            this.createParking = true;
          }
        }
      });
    }

  public polyStyle = {
    fillColor: '#78B51C',
    strokeColor: '#78B51C',
    strokeWeight: 3,
    zIndex: 2,
    icon: 'assets/images/noMarker.png',
    visible: this.polyVisible
  };

  public zoneStyle = {
    zIndex: 1,
  };

  filteredOptions: Observable<string[]>;

  ngOnInit() {

    if(this.authService.isAuthenticated())
    {
      console.log("logget ind - vis terms")
    } else{
      console.log("ikke logget ind")
    }



    this.isMobileView = (this.media.isActive('xs') || this.media.isActive('sm'));
    const urlParams = new URLSearchParams(window.location.search);
    this.loadingService.stopLoading();
    console.log(urlParams)
    if (urlParams.has('orderid')) {
      if (localStorage.getItem(`pendingQuickPark`) !== null) {
      const quickPark = JSON.parse(localStorage.getItem(`pendingQuickPark`));
      this.currentParkingService.addLocalCurrentParking(new CurrentParking(quickPark, this.currentParkingService));
        for (let i = 0; i < 100; i++) {
          if (localStorage.getItem(`quickpark${i}`) === null) {
            localStorage.setItem(`quickpark${i}`, JSON.stringify(quickPark));
            localStorage.removeItem(`pendingQuickPark`);

            this.dialog.open(MapQuickParkComponent, {
              disableClose: true
            });

            break;
          }
        }
      }
    }

    navigator.geolocation.watchPosition(position => this.positionChange(position.coords));

    this.myControl.valueChanges.subscribe(
      x => this.setSearchFacilities(x)
    );

    this.setSearchFacilities('');

    const that = this;
    that.lat = 55.674211; // Apcoa Danmark
    that.lng = 9.596305;
    that.agmMap.zoom = 14;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        that.lat = position.coords.latitude;
        that.lng = position.coords.longitude;
      });
    }

    this.currentParkings.currentParkings().subscribe(res => {
    });

    this.facilityService.getFacilities().subscribe(facilities => {
      this.currentFacilitys = facilities;
      // tslint:disable-next-line:forin
      for (const i in facilities) {
        let distance = null;
        this.isGeolocationEnabled();

        if (this.geoEnabled === true) {
          distance = this.calculateDistance(facilities[i].latitude, that.lat, facilities[i].longitude, that.lng);
        }

        this.cd.markForCheck();
        this.searchFacilities.push({
          name: facilities[i].name,
          city: facilities[i].city,
          address: facilities[i].address,
          area: facilities[i].number,
          lat: facilities[i].latitude,
          lng: facilities[i].longitude,
          key: facilities[i].key,
          provider: facilities[i].providerKey,
          // tslint:disable-next-line:no-bitwise
          isAnpr: (facilities[i].state & 8) !== 0 ? true : false,
          index: i,
          distance: distance
        });

        if (facilities[i].geoJson !== null) {
          const data = JSON.parse(facilities[i].geoJson);

          for (let y = 0; y < data.features.length; y++) {
            if (data.features[y].geometry.type === 'Point') {
                this.markers.push({
                index: i,
                lat: data.features[y].geometry.coordinates[1],
                lng: data.features[y].geometry.coordinates[0],
                key: facilities[i].key,
                provider: facilities[i].providerKey,
                // tslint:disable-next-line:no-bitwise
                hasContract: (facilities[i].state & 1024) !== 0 ? true : false,
                // tslint:disable-next-line:no-bitwise
                isAnpr: (facilities[i].state & 8) !== 0 ? true : false,
                area: facilities[i].number,
                areaName: data.features[y].properties.Name,
                hasInformation: facilities[i].hasInformation
              });
            }
          }
        } else {
          this.markers.push({
            index: i,
            lat: facilities[i].latitude,
            lng: facilities[i].longitude,
            key: facilities[i].key,
            provider: facilities[i].providerKey,
            // tslint:disable-next-line:no-bitwise
            hasContract: (facilities[i].state & 1024) !== 0 ? true : false,
            // tslint:disable-next-line:no-bitwise
            isAnpr: (facilities[i].state & 8) !== 0 ? true : false,
            area: facilities[i].number,
            areaName: facilities[i].address + ', ' + facilities[i].zip + ' ' + facilities[i].city,
            hasInformation: facilities[i].hasInformation
          });
        }

        if (facilities[i].type === areaType.area) {
          this.polygons.push({
            // title: facilities[i].address,
            geoJson: JSON.parse(facilities[i].geoJson),
            lat: facilities[i].latitude,
            lng: facilities[i].longitude,
            key: facilities[i].key,
            provider: facilities[i].providerKey,
            // tslint:disable-next-line:no-bitwise
            hasContract: (facilities[i].state & 1024) !== 0 ? true : false,
            // tslint:disable-next-line:no-bitwise
            isAnpr: (facilities[i].state & 8) !== 0 ? true : false,
            area: facilities[i].number,
            areaName: facilities[i].address + ', ' + facilities[i].zip + ' ' + facilities[i].city,
            hasInformation: facilities[i].hasInformation
          });
        }

        if (facilities[i].type === areaType.zone) {
          this.zones.push({
            key: facilities[i].key,
            provider: facilities[i].providerKey,
            geoJson: JSON.parse(facilities[i].geoJson),
            areaProvider: facilities[i].providerKey,
            parkingZone: facilities[i].name + ' zone',
            areaName: facilities[i].city + ' - ' + facilities[i].generalComment,
            hasInformation: facilities[i].hasInformation,
          });
        }
      }
      const areaParam = this.route.snapshot.queryParamMap.get('createParking');

      if (areaParam) {
          const marker = this.markers.find(x => x.area === areaParam);
          this.markerOpen(marker);
      }

    });

    this.cardCheck();
    this.carCheck();
    this.promoCheck(that.lat, that.lng);
    this.refreshMap();

    this.cd.markForCheck();
  }




  setSearchFacilities(x) {
    this.filteredSearchFacilities = this.filter(x);
  }

  clickedMarker(title: string, index: number) {
    this.ref.detectChanges();
    let marker = this.markers.find(e => e.index == index);
    this.currentFacility = marker;
    this.markerClicked = true;
    const that = this;
    const area = marker.area;
    const areaName = marker.areaName;
    const hasContract = marker.hasContract;
    const isAnpr = marker.isAnpr;
    this.isZone = false;
    this.currentArea = areaName;
    this.showNotification = true;
    this.areaName = areaName;
    this.hasContract = hasContract;
    this.isAnpr = isAnpr;
    this.area = area;
    this.lat = marker.lat;
    this.lng = marker.lng;
    this.areaHasInformation = marker.hasInformation;
    this.areaKey = marker.key;


    console.log(this.zoomVal)




    this.createParking = false;

    // this.markers[index].iconUrl = {url: 'assets/images/pspot-selected.svg'};

    navigator.geolocation.getCurrentPosition(function(position) {
      that.show = true;
      that.dir = {
        origin: { lat: position.coords.latitude, lng: position.coords.longitude },
        destination: { lat: marker.lat, lng: marker.lng }
      };
    });
    this.ref.detectChanges();

    if(this.zoomVal  < 16){
      while(this.zoomVal <= 16){
        this.zoomVal = this.zoomVal + 0.5;

        console.log(this.zoomVal)
      }
    }
  }


  markerOpen(marker: any) {
    // let marker = this.markers.find(e => e.index == index);
    this.markerClicked = true;
    const that = this;
    const area = marker.area;
    const areaName = marker.areaName;
    const hasContract = marker.hasContract;
    const isAnpr = marker.isAnpr;
    this.isZone = false;
    this.currentArea = areaName;
    this.showNotification = true;
    this.areaName = areaName;
    this.hasContract = hasContract;
    this.isAnpr = isAnpr;
    this.area = area;
    this.lat = marker.lat;
    this.lng = marker.lng;
    this.areaHasInformation = marker.hasInformation;
    this.areaKey = marker.key;

    this.createParking = true;

    // this.markers[index].iconUrl = {url: 'assets/images/pspot-selected.svg'};

    navigator.geolocation.getCurrentPosition(function(position) {
      that.show = true;
      that.dir = {
        origin: { lat: position.coords.latitude, lng: position.coords.longitude },
        destination: { lat: marker.lat, lng: marker.lng }
      };
    });
  }

  clickedPolygon(title: string, index: number) {
    const that = this;
    this.isZone = false;
    this.showNotification = true;
    this.areaName = this.polygons[index].areaName;
    this.currentArea = this.areaName;
    this.hasContract = this.polygons[index].hasContract;
    this.isAnpr = this.polygons[index].isAnpr;
    this.area = this.polygons[index].area;
    this.lat = this.polygons[index].lat;
    this.lng = this.polygons[index].lng;
    this.areaHasInformation = this.polygons[index].hasInformation;
    this.areaKey = this.polygons[index].key;
    this.provider = this.polygons[index].provider;

    this.createParking = false;

    // this.markers[index].url = 'assets/images/pspot-selected.svg';

    navigator.geolocation.getCurrentPosition(function(position) {
      that.show = true;
      that.dir = {
        origin: { lat: position.coords.latitude, lng: position.coords.longitude },
        destination: { lat: that.polygons[index].lat, lng: that.polygons[index].lng }
      };
    });
  }

  clickedZone(title: string, index: number) {
    const that = this;
    this.isZone = true;
    this.showNotification = true;
    this.areaName = this.zones[index].areaName;
    this.currentArea = this.areaName;
    this.area = this.zones[index].area;
    this.lat = this.zones[index].lat;
    this.lng = this.zones[index].lng;
    this.areaHasInformation = this.zones[index].hasInformation;
    this.areaProvider = this.zones[index].areaProvider;
    this.areaKey = this.zones[index].key;
    this.parkingZone = this.zones[index].parkingZone;

    this.createParking = false;

    // navigator.geolocation.getCurrentPosition(function(position) {
    //   that.show = true;
    //   that.dir = {
    //     origin: { lat: position.coords.latitude, lng: position.coords.longitude },
    //     destination: { lat: that.polygons[index].lat, lng: that.polygons[index].lng }
    //   };
    // });
  }

  clickedSearchBar() {
    this.createParking = false;
  }

  clickMarker(area: string) {
  }

  mapClicked($event: MouseEvent) {
    this.markerClicked = false;
    this.showNotification = false;
    this.show = false;
    this.createParking = false;
  }

  styleFunction(feat) {
    return {
      fillColor: feat.getProperty('fill'),
      fillOpacity: feat.getProperty('fill-opacity'),
      strokeColor: feat.getProperty('stroke'),
      strokeWeight: feat.getProperty('stroke-width'),
      strokeOpacity: feat.getProperty('stroke-opacity')
    };
  }

  gotoDirections() {
    window.open('https://www.google.com/maps/?q=' + this.lat + ',' + this.lng, '_blank');
  }

  gotoCreateContract() {
    if(this.authService.isAuthenticated())
    {
      this.authService.createLoginToken().subscribe(x => this.redirectUrl(x.token));
    }
    else {
      this.redirectUrl("");
    }
    // this.router.navigate([`/contractparking/${this.area}`]);
  }

  redirectUrl(token) {
    window.location.href = environment.contractUrl + "/?areakey=ADK-" + this.area + "&redirect=webapp&mat=" + token;
  }

  gotoCreateParking() {
    let currentArea = this.currentFacilitys.find(x => x.key == this.areaKey);
    if(currentArea == null)
      return;
    if (!isClosingTimeFromToValid(DateTime.local(), currentArea)) {
      this.dialog.open(MapClosingHoursComponent, {
        data: currentArea,
        width: '600px',
      });
      return;
    }
    if (this.isAnpr) {
      this.dialog.open(MapANPRParkingComponent, {});
      this.ref.detectChanges();
    } else {
      this.createParking = true;
      this.ref.detectChanges();
    }

    this.showNotification = false;
    // this.router.navigate([`/createparking/${this.areaKey}`]);
  }

  calculateDistance(lat1: number, lat2: number, long1: number, long2: number) {
    const p = 0.017453292519943295;    // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((lat1 - lat2) * p) / 2 + c(lat2 * p) * c ((lat1) * p) * (1 - c(((long1 - long2) * p))) / 2;
    const dis = (12742 * Math.asin(Math.sqrt(a))); // 2 * R; R = 6371 km
    return parseInt(dis.toString(), 10);
  }

  filter(val: string): string[] {
    return this.searchFacilities.filter(option => {
      val = val.toLowerCase();
      if (option.lat !== null && option.lng !== null) {
        // if (this.searchFacilities.indexOf(val)) {
        //   return option.city.toLowerCase().includes(val.toLocaleLowerCase());
        // }
        
        if (Number(val)) {
          return option.area.includes(val);
        } else {
            return option.address.toLowerCase().includes(val.toLowerCase());
          }
        }
      }
    );
  }

  zoomChanged(event) {

    this.zoomVal = this.agmMap.zoom;
    if (this.agmMap.zoom < 13) {
      this.polyVisible = false;
    } else {
      this.polyVisible = true;
    }
  }

  positionChange(coords: Coordinates) {
    this.userLat = coords.latitude;
    this.userLng = coords.longitude;

    // tslint:disable-next-line:forin
    for (const i in this.searchFacilities) {
      const current = this.searchFacilities[i];
      current.distance = this.calculateDistance(current.lat, this.userLat, current.lng, this.userLng);
    }
    this.setSearchFacilities('');
  }

  geocodeLookup(address: string) {

      this.geocodeService.geocodeAddress(address).subscribe(res => {
        this.lat = res.lat;
        this.lng = res.lng;
        this.ref.detectChanges();
      });

  }

  resetLocation() {
    this.lat = this.userLat;
    this.lng = this.userLng;
    this.ref.detectChanges();
  }

  isGeolocationEnabled() {
    const self = this;
    if (!navigator.geolocation)  {
      self.geoEnabled = false;
    } else {
    navigator.geolocation.getCurrentPosition (function() {
          self.geoEnabled = true;
        },
        function (error) {
          if (error.code === error.PERMISSION_DENIED || error.code === error.POSITION_UNAVAILABLE || error.code === error.TIMEOUT) {
            self.geoEnabled = false;
          } else {
              self.geoEnabled = true;
            }
        });
      }
    }

    promoCheck(lat, lng) {
      this.promoService.getActivePromos().subscribe((promo:any) => {
        for (let index = 0; index < promo.length; index++) {
          if (promo[index] && localStorage.getItem(`promo:${promo[index].Id}`) == null) {
            const rad = promo[index].Radius;
            const pLat = promo[index].Lat;
            const pLng = promo[index].Lng;

            var ky = 40000 / 360;
            var kx = Math.cos(Math.PI * lat / 180.0) * ky;
            var dx = Math.abs(pLng - lng) * kx;
            var dy = Math.abs(pLat - lat) * ky;
            var d = Math.sqrt(dx * dx + dy * dy);
            if (d <= rad) {
              this.dialog.open(PromoComponent, {
                data: {
                  number: index.toString(),
                },
                width: '420px'
              });
            }
          }
        }
      });
    }

    carCheck() {
      if (this.authService.isAuthenticated() === true) {
        if (localStorage.getItem('mapcar') === null) {
          this.vehicleService.registrations().subscribe(res => {
            if (res.length <= 0) {
                this.dialog.open(MapCarReminderComponent, {
                width: '420px'
              });
            }
          });
        }
      }
    }

    cardCheck() {
      if (this.authService.isAuthenticated() === true) {
        if (localStorage.getItem('mapcard') === null) {
          this.paymentService.activeSubscriptions().subscribe(res => {
            if (res.length <= 0) {
              this.dialog.open(MapCardReminderComponent, {
              width: '420px'
              });
            }
          });
        }
      }
    }

    toRad(value) {
      return value * Math.PI / 180;
    }

    refreshMap() {
      this.cd.detectChanges();
    }

    // @HostListener('document:click', ['$event'])
    clickout(event) {
      this.createParking = event;
    }
  }

  @Component({
    selector: 'app-mapcarreminder',
    templateUrl: './mapcarreminder.component.html',
    styleUrls: ['./mapcarreminder.component.css']
  })
  export class MapCarReminderComponent implements OnInit {
    dontShowAgain = false;

    constructor(public dialogRef: MatDialogRef<MapCarReminderComponent>) {}

    ngOnInit() {}

    ok() {
      if (this.dontShowAgain === true) {
        localStorage.setItem('mapcar', '');
      }

      this.dialogRef.close();
    }
  }


  @Component({
    selector: 'app-mapcardreminder',
    templateUrl: './mapcardreminder.component.html',
    styleUrls: ['./mapcardreminder.component.css']
  })
  export class MapCardReminderComponent implements OnInit {
    dontShowAgain = false;

    constructor(public dialogRef: MatDialogRef<MapCardReminderComponent>) {}

    ngOnInit() {}

    ok() {
      if (this.dontShowAgain === true) {
        localStorage.setItem('mapcard', '');
      }

      this.dialogRef.close();
    }
  }

