import { Injectable } from '@angular/core';
import { IAuthToken } from '../models/authToken';
import { DateTime } from 'luxon';

import { environment } from '../../environments/environment';
import { UserManager } from 'oidc-client';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  timer: any;
  public authToken: IAuthToken;
  public authHeader: string;

  private _changeEmail = false;

  private options;

  constructor(private http: HttpClient,
    private router: Router) {

    let port = '';
    if (window.location.port) {
      port = `:${window.location.port}`;
    }

    const url = `${window.location.protocol}//${window.location.hostname}${port}`;

    this.options = {
      authority: environment.loginAuthority,
      client_id: 'mp.web.app',
      redirect_uri: `${url}${environment.loginRedirectUri}`,
      response_type: 'id_token token',
      scope: 'openid profile appapi',
      post_logout_redirect_uri: `${url}${environment.postLogoutRedirectUri}`,
    };

    if (sessionStorage.getItem('auth') && sessionStorage.getItem('authHeader')) {
      this.authToken = JSON.parse(sessionStorage.getItem('auth'));
      this.authHeader = sessionStorage.getItem('authHeader');
    }

    if (this.authToken) {
      // this._authToken = token;
      // this._authToken.Expires = moment(this._authToken.Expires);
      // this._authToken.Created = moment(this._authToken.Created);

      // this.updateAuthToken();

      // const now = DateTime.local();
      // if (authToken.Expires > now.minus({minutes : 10})) {
      //   this.timer = setInterval(() => {
      //     this.updateAuthToken();
      // }, 1000 * 60 * 10); // update each 10 minutes.
      //    this._refreshTimer = this.$timeout(() => this.updateAuthToken(),
      // moment.duration(this._authToken.Expires.diff(now)).asMilliseconds() - (10 * 60 * 1000));
      // } else {
      //   this.updateAuthToken();
      //   if (this.timer) {
      //     clearInterval(this.timer);
      //   }
      // }

    }
  }

  createLoginToken(): Observable<any> {
    return this.http.get<any>(environment.apiBaseUrl + `User/LoginToken`, {
      headers: {
        'Authorization': `Bearer ${this.getAuthHeader()}`
      }
    });
  }

  public isAuthenticated(): boolean {
    return this.authToken && this.authToken.expires > DateTime.utc().valueOf();
  }

  public getAuthHeader(): string {
    return this.authHeader;
  }

  public getName(): string {
    if (!this.getAuthToken()) {
      return '';
    }

    return this.authToken.name;
  }

  public getSubjectId(): string {
    if (!this.getAuthToken()) {
      return '';
    }

    return this.authToken.sub;
  }

  public getCountryCode(): string {
    if (!this.getAuthToken()) {
      return '';
    }

    return 'DK'; // hardcoded untill now.
  }

  private getAuthToken(): IAuthToken {
    return this.authToken;
  }

  public authenticate(redirectTo?: string): void {
    const mgr = new UserManager(this.options);

    if (redirectTo) {
      mgr.signinRedirect({
        state: {
          redirectTo: redirectTo
        }
      });
    } else {
      mgr.signinRedirect();
    }
  }

  public authenticateWithToken(mat: string, redirectPath: string): void {
   
    const mgr = new UserManager(this.options);
    mgr.signinRedirect({ state: {
      redirectTo: redirectPath
    }, extraQueryParams: {
      mat: mat
    }}).then((user) => {
    });
  }

  public handleCallback(): void {
    const mgr = new UserManager(this.options);
    mgr.signinRedirectCallback(window.location.href).then(loggedUser => {
      mgr.getUser().then(user => {
        if (user) {
          this.authHeader = user.access_token;
          this.authToken = {
            created: DateTime.utc().valueOf(),
            expires: user.expires_at * 1000,
            name: user.profile.name,
            sub: user.profile.sub
          };

          sessionStorage.setItem('auth', JSON.stringify(this.authToken));
          sessionStorage.setItem('authHeader', this.authHeader);

          if (loggedUser.state && loggedUser.state.redirectTo) {
            this.router.navigate([loggedUser.state.redirectTo]);
          } else {
            this.router.navigate(['/']);
          }
       }
      });
    });
  }

  public signout(): void {
    // console.log('signout');
    const mgr = new UserManager(this.options);
     mgr.signoutRedirect().then(function() {
     });

     delete this.authToken;

      delete localStorage.auth;
      delete sessionStorage.auth;

      this.router.navigate(['']);
  }


  private updateAuthToken(): void {
    // const remember: boolean = localStorage.auth.remember;
    // const privateUser: boolean = localStorage.auth.private;

    // const storage: any = remember ? localStorage : sessionStorage;
    // const webkey: string = storage.auth.webkey;


    // this.http.post<string>(environment.apiBaseUrl + `Auth/App/${privateUser ? 'private' : 'employee'}`, { // vent med at fixe denne.
    //   Credentials: webkey
    // }).toPromise().then((result: any) => {

    //   this.authToken = result.data;  // result.data;.fromMillis(this.authToken.Expires)
    //   // this.authToken.Expires = new DateTime.fromMillis();
    //   // this.authToken.Created = moment(this.authToken.Created);

    //   delete storage.auth.authToken;

    //   storage.auth.authToken = this.authToken;
    //   storage.auth.authTokenHeaderValue = btoa(result.data); // eller atob
    // });
  }


  private clearTokenData(): void {
    if (localStorage.getItem('auth')) {
      delete localStorage.auth.authToken;
      delete localStorage.auth.authTokenHeaderValue;
    }

    if (sessionStorage.auth) {
      delete sessionStorage.auth.authToken;
      delete sessionStorage.auth.authTokenHeaderValue;
    }
  }

  public emailNotification(): boolean {
    return this._changeEmail;
  }
}
