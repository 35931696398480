import { DateTime } from 'luxon';
import { CurrentparkingService } from '../services/currentparking.service';
import { interval, Observable, Subscription } from 'rxjs';
import { IParkingTransaction, ITimeLimits } from '../models/parking';

export enum CurrentParkingMode { Payed, Pending }

export class ICurrentParkingData {
    mode: CurrentParkingMode;
    customerId?: string;
    orderId?: string;
    starttime: DateTime;
    endtime?: DateTime;
    parkingAreakey: string;
    parkingAreaNumber: string;
    parkingAreaCountry: string;
    vehicleRegistration: string;
    vehicleRegistrationKey: string;
    parkingNumber?: string;
    parkingNumberLong?: number;
    quickpark: boolean;
    transactions: IParkingTransaction[];
    canExtend = false;
    canStop = false;
    canDelete ? = false;
    parkingType: number;
    parkingAddress?: string;
    allDay: boolean;
    timeLimits: string;
    // public GetParkingAreaKey(): string {
    //     return `${this.parkingAreaCountry.toUpperCase()}-${this.parkingAreaNumber.toUpperCase()}`;
    // }
}

export class QuickparkVerifiedResult {
    verified: boolean;
    parkingId: number;
}

export class CurrentParking {
    private _quickparkVerify: Observable<number>;
    private _quickparkVerirySubscription: Subscription;

    constructor(public data: ICurrentParkingData, private currentParkingService?: CurrentparkingService) {
        if (data.quickpark && !currentParkingService) {
            throw new Error('Missing currentParkingService');
        }

        if (data.quickpark) {
            this._quickparkVerify = interval(5000);
            this._quickparkVerirySubscription = this._quickparkVerify.subscribe(cnt => this._verifyQuickpark(cnt));
        }
    }

    private _verifyQuickpark(retries: number) {
        // console.log('verify');
        this.currentParkingService.verifyQuickpark(this.data.customerId, this.data.orderId).subscribe((result) => {
            if (result.verified === true) {
                this._quickparkVerirySubscription.unsubscribe();
                this.data.mode = CurrentParkingMode.Payed;
                // console.log('verified');
            }
        });

        if (retries > 1000) { // Unable to verify
            this._quickparkVerirySubscription.unsubscribe();
        }
    }

    public getValidFrom(): DateTime {
        return this.data.transactions[0].validFrom;
    }

    public getValidTo(): DateTime {
        return this.data.transactions[this.data.transactions.length - 1].validTo;
    }

    public isActive() {
        if (this.data.quickpark === true) {
            return this.getValidTo().valueOf() > DateTime.utc().valueOf();
        }

        return true;
    }
}
