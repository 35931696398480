import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { IVehicleRegistrationCreate, IVehicleRegistration } from '../models/vehicleregistration';
import {EmptyObservable} from 'rxjs/observable/EmptyObservable';
import { DMRInfoObject } from '../models/DMRInfo';

@Injectable({
  providedIn: 'root'
})
export class VehicleregistrationService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  create(info: IVehicleRegistrationCreate): Observable<{}> {
    console.log(info);
    return this.http.post(environment.apiBaseUrl + `vehicleregistration`, info, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  registrations(): Observable<IVehicleRegistration[]> {
   if (!this.authService.isAuthenticated()) {
      return new EmptyObservable();
   }
    return this.http.get<IVehicleRegistration[]>(environment.apiBaseUrl + `vehicleregistration`, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  DMRInfo(plate: string): Observable<DMRInfoObject> {
    if (!this.authService.isAuthenticated()) {
       return new EmptyObservable();
    }
     return this.http.get<DMRInfoObject>(environment.DMRUrl +"/"+ plate + "/public", {
       headers: {
         'Authorization': `Bearer ${this.authService.getAuthHeader()}`,
         'X-skipErrors' : 'X-skipErrors'
       }
     });
   }

  togglePrimary(vehicleKey: string, isPrimary: boolean): Observable<{}> {
    return this.http.post(environment.apiBaseUrl + `vehicleregistration/${vehicleKey}/primary`, { isPrimary: isPrimary}, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  updateDescription(vehicleKey: string, description: string): Observable<{}> {
    return this.http.post(environment.apiBaseUrl + `vehicleregistration/${vehicleKey}/`, { description: description }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  delete(vehicleKey: string): Observable<{}> {
    return this.http.delete(environment.apiBaseUrl + `vehicleregistration/${vehicleKey}/`, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }
}
