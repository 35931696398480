import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, from, of, throwError } from 'rxjs';
import { IAccountInfo } from '../models/accountInfo';
import { map, catchError, switchMap, single } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {Headers, RequestOptions,} from '@angular/http';
import { AuthService} from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getAccountInfo(countryCode?: string): Observable<IAccountInfo[]> {
    return this.http.get<IAccountInfo[]>(environment.apiBaseUrl + 'Account', {
        headers: {'Authorization': `Bearer ${this.authService.getAuthHeader()}`}})
      .pipe(map(data => {
        return data;
      }));
  }

  public deleteAccount(userGuid: any, reminderGuid: any): Observable<boolean> {
    return this.http.get<any>(+ `User/CloseAccountAnonymous/` + userGuid + '/' + reminderGuid, {})
      .pipe(map(data => {
        return data.isDeleted;
      }));
  }

  public keepAccount(userGuid: any, reminderGuid: any): Observable<boolean> {
    return this.http.get<any>(+ `User/KeepAccountAnonymous/` + userGuid + '/' + reminderGuid, {})
      .pipe(map(data => {
        return data.isKept;
      }));
  }

}
