import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { DMRConfirmComponent } from "../dmrConfirm/dmrConfirm.component";
import { CreatecarComponent } from "../createcar/createcar.component";

@Component({
    selector: 'app-dmr',
    templateUrl: './dmr.component.html',
    styleUrls: ['./dmr.component.css']
  })
export class DMRComponent implements OnInit {
    plate: string;
    description: string;
    constructor(public dialogRef: MatDialogRef<DMRComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
    
    }
    regNum = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(`^\\S*$`)
      ]);
      
        closeWindow() {
            this.dialogRef.close();
            this.dialog.open(CreatecarComponent, { data:{
                plate: this.data.plate,
            }});
        }
        continue() {
            this.dialogRef.close();
            if (!this.data.brand){
                this.dialog.open(DMRConfirmComponent, { data:{
                    plate: this.data.plate,
                    brand: "",
                    fuel: "",
                    model: "",
                    variant: ""
                }
            })}
            else {
                this.dialog.open(DMRConfirmComponent, { data:{
                    plate: this.data.plate,
                    brand: this.data.brand,
                    fuel: this.data.fuel,
                    model: this.data.model,
                    variant: this.data.variant
                }
            });
        }
    }
    ngOnInit() {
        if (!this.data.brand){
            this.description = "Vi fandt ikke din nummerplade - Tjek venligst at nummerpladen er korrekt indtastet"
        }
        else{
            if(this.data.color === "Ukendt"){
                this.data.color = "";
            }
            if(this.data.brand === "Ukendt"){
                this.data.brand = "";
            }   
            if(this.data.model === "Ukendt"){
                this.data.model = "";
            }
            if(this.data.variant === "Ukendt"){
                this.data.variant = "";
            }
            this.description = this.data.color + " " + this.data.brand + " " + this.data.model + " " + this.data.variant;
        }
        this.plate = this.data.plate;
    }
  }