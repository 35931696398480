import { Component, OnInit } from '@angular/core';
import { TextService } from '../../services/text.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  public userText: string;

  constructor(private textService: TextService) {

    textService.getText('termsandagree').subscribe((text) => this.userText = text);
  }

  ngOnInit() {
  }
}
