import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { IContactAnpr, IContact } from '../models/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  anpr(info: IContactAnpr): Observable<{}> {
    return this.http.post(environment.apiBaseUrl + `contact/anpr`, info);
  }

  send(info: IContact): Observable<{}> {
    return this.http.post(environment.apiBaseUrl + `contact`, info);
  }
}
