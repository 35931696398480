import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { PaymentDialogComponent } from './paymentdialog.component';

import { QuickparkService } from '../../services/quickpark.service';
import { EpayService } from '../../services/epay.service';
import { CurrentparkingService } from '../../services/currentparking.service';
import { CurrentParking, ICurrentParkingData, CurrentParkingMode } from '../../models/currentparking';
// import { IParkingTransaction } from '../../models/parking';
import { environment } from '../../../environments/environment';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-quickpark',
  templateUrl: './quickpark.component.html',
  styleUrls: ['./quickpark.component.css']
})
export class QuickParkComponent implements OnInit {
private subscription: any;

firstname = new FormControl('', [
    Validators.required
]);

lastname = new FormControl('', [
    Validators.required
]);

email = new FormControl('', [
    Validators.required,
    Validators.email
]);

mobile = new FormControl('', [
    Validators.minLength(8),
    Validators.pattern('^[0-9]*$')
]);

id: string;
regNum: string;
millis: number;
allowSMS: boolean;
acceptTerms: boolean;
discountVoucher: string;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private quickParkService: QuickparkService,
        private currentParkingService: CurrentparkingService,
        private dialog: MatDialog,
        private epayService: EpayService,
        private loadingService: LoadingService) {

        this.subscription = this.route.params.subscribe(params => {
            this.id = params['id'];
            this.regNum = params['regNum'];
            this.millis = params['millis'];

            if (params['voucher'] !== undefined) {
                this.discountVoucher = params['voucher'];
            }
        });
    }

    ngOnInit() {
        const url = 'https://ssl.ditonlinebetalingssystem.dk/integration/ewindow/paymentwindow.js';
        this.loadScript(url);
    }

    public loadScript(url: string) {
        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    createQuickParking() {
        if (this.firstname != null && this.lastname != null &&
            this.email.valid === true && this.mobile.valid === true &&
            this.acceptTerms === true) {
            this.showEpay();
        } else {
            this.dialog.open(QuickparkFailureComponent, {
            });
        }
    }

    showEpay() {
        const now = DateTime.local();
        this.loadingService.startLoading();

        this.quickParkService.create({
            firstname: this.firstname.value,
            lastname: this.lastname.value,
            email: this.email.value,
            parkingAreaKey: this.id,
            allowContact: false,
            vehicleRegistrationKey: 'DK-' + this.regNum,
            endTime: DateTime.fromMillis(+this.millis),
            smsReminder: this.allowSMS,
            reminderNumber: this.allowSMS ? this.mobile.value : '',
            voucherCode: this.discountVoucher,
            // countryCode: this.countryCode
        }).subscribe(pending => {
            const t = +pending.totalPrice;

            if (t <= 0) {
                this.router.navigate(['']);
            } else {
                const dialogRef = this.dialog.open(PaymentDialogComponent, {
                    disableClose: true,
                    width: '430px'
                  }).afterOpen().subscribe(x => {
                    const options: any = {
                        merchantnumber: environment.epayMerchantNumber,
                        currency: 'DKK',
                        amount: Math.floor((t) * 100),
                        orderid: pending.orderNumber,
                        instantcapture: 0,
                        instantcallback: 1,
                        mobile: 1,
                        accepturl: `${environment.host}`, // baseUrl + 'accept',
                        cancelurl: `${environment.host}/park/create/${'ADK'}/${this.id}/info`,
                        callbackurl: `${environment.epayCallbackUrl}?user_attr_1=3&user_attr_3=${pending.id}&sub=${pending.customerId}`,
                        ownreceipt: 1,
                        windowstate: 3,
                        // iframeheight: 400,
                        // iframewidth: '100%',
                        // cssurl: environment.epayCss,
                        paymenttype: '1,3,4,29'
                    };

                    this.epayService.hashParams(options).subscribe(result => {
                        options.hash = result.hash;
                        const quickPark = {
                            starttime: pending.validFrom,
                            endtime: pending.validTo,
                            canDelete: true,
                            canExtend: true,
                            canStop: true,
                            customerId: pending.customerId,
                            mode: CurrentParkingMode.Pending,
                            orderId: pending.orderNumber,
                            parkingAreaKey: pending.parkingAreaKey,
                            quickpark: true,
                            parkingNumberLong: pending.id,
                            transactions: [
                                {
                                    created: now,
                                    discount: pending.discount,
                                    hasDiscount: pending.discount > 0,
                                    parkingPrice: pending.totalPrice,
                                    validFrom: pending.validFrom,
                                    validTo: pending.validTo
                                }
                            ],
                            vehicleRegistration: this.regNum,
                            vehicleRegistrationKey: 'DK-' + this.regNum.toUpperCase()
                        };
                        localStorage.setItem(`pendingQuickPark`, JSON.stringify(quickPark));

                        const paymentWindow = new PaymentWindow(options);
                        // paymentWindow.append('payment-div');
                        paymentWindow.open();
                });
                }
            );
        }
        }
    );
}
}

@Component({
    selector: 'app-quickparkfailure',
    templateUrl: './quickparkfailure.component.html',
    styleUrls: ['./quickparkfailure.component.css']
  })
  export class QuickparkFailureComponent implements OnInit {
    constructor(
      public dialogRef: MatDialogRef<QuickparkFailureComponent>
    ) {}

    ngOnInit() {
    }

    ok() {
      this.dialogRef.close();
    }
  }
