import {Component, OnInit, Inject} from '@angular/core';
import {DateTime} from 'luxon';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContractService} from 'src/app/services/contract.service';
import {ICreateContractParking, IContract, IProduct, ContractProductPeriod} from 'src/app/models/contract';
import {PaymentsubscriptionService} from 'src/app/services/paymentsubscription.service';

@Component({
  selector: 'app-contractconfirmextenddialog',
  templateUrl: './contractconfirmextend.component.html',
  styleUrls: ['./contractconfirmextend.component.css']
})
export class ContractConfirmExtendComponent implements OnInit {
  parkingId: string;
  paymentCard: any;
  product: any;
  form: any;
  model = new ICreateContractParking();
  end: DateTime;
  loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contractService: ContractService,
    private paymentSubscriptionService: PaymentsubscriptionService,
    private dialogRef: MatDialogRef<ContractConfirmExtendComponent>) {
  }

  ngOnInit() {
    this.parkingId = this.data.parkingId;
    this.product = this.data.product as IProduct;
    this.form = this.data.contract as IContract;

    this.paymentSubscriptionService.getSubscription(this.form.paymentUId).subscribe(res => {
      this.paymentCard = res;
    });

    this.end = this.getEndDateBasedOnStartDate(this.form.validTo, this.product);
  }

  extendContract() {
    this.loading = true;
    this.contractService.extendContractParking(this.parkingId, this.form.vehicleRegistrationTyped)
      .subscribe(res => {
        this.loading = false;
        this.dialogRef.close(this.end);
      }, error => this.loading = false);
  }


  getEndDateBasedOnStartDate(ends: DateTime, product: IProduct): DateTime {
    let end = DateTime.fromISO(ends.toString());
    switch (product.type) {
      case ContractProductPeriod.Day:
        end = end.plus({days: 1});
        break;

      case ContractProductPeriod.TwoDays:
        end = end.plus({days: 2});
        break;

      case ContractProductPeriod.ThreeDays:
        end = end.plus({days: 3});
        break;

      case ContractProductPeriod.FourDays:
        end = end.plus({days: 4});
        break;

      case ContractProductPeriod.FiveDays:
        end = end.plus({days: 5});
        break;

      case ContractProductPeriod.SixDays:
        end = end.plus({days: 6});
        break;

      case ContractProductPeriod.Week:
        end = end.plus({days: 7});
        break;

      case ContractProductPeriod.TwoWeeks:
        end = end.plus({days: 14});
        break;

      case ContractProductPeriod.ThreeWeeks:
        end = end.plus({days: 21});
        break;

      case ContractProductPeriod.Month:
        end = end.plus({months: 1});
        break;

      case ContractProductPeriod.ThreeMonths:
        end = end.plus({months: 3});
        break;

      case ContractProductPeriod.SixMonths:
        end = end.plus({months: 6});
        break;

      case ContractProductPeriod.Year:
        end = end.plus({years: 1});
        break;
    }

    return end;
  }
}
