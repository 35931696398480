import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from '../../../node_modules/rxjs';
import { environment } from '../../environments/environment';
import { ICreateVoucherParking, IVoucherParking } from '../models/parking';

@Injectable({
    providedIn: 'root'
})
export class PermitService {

constructor(private http: HttpClient) { }

    createVoucherParking(parking: ICreateVoucherParking): Observable<IVoucherParking> {
        return this.http.post<IVoucherParking>(environment.apiBaseUrl + `permit/voucher?email=${parking.email}&licensePlate=${parking.licensePlate}&voucherCode=${parking.voucherCode}`, null);
    }

    createParkFromVoucher(voucherParking: IVoucherParking): Observable<string> {
        return this.http.post<string>(environment.apiBaseUrl + `permit/voucher/confirm`, voucherParking);
    }
}
