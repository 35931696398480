import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { LoadingService } from '../../services/loading.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';

import { ICreateUser, IUpdateUser, IUser } from '../../models/user';

import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material'
import { ParkingGDPRComponent } from '../createparking/createparking.component';

@Component({
  selector: 'app-createaccount',
    // tslint:disable-next-line:use-input-property-decorator
  inputs: ['redirect'],
  templateUrl: './createaccount.component.html',
  styleUrls: ['./createaccount.component.css']
})
export class CreateaccountComponent implements OnInit {
  redirect: boolean;
  email = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  firstname = new FormControl('', [
    Validators.required
  ]);

  lastname = new FormControl('', [
    Validators.required
  ]);

  password = new FormControl('', [
    Validators.required,
    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')
  ]);

  confirmPass = new FormControl('', [
    Validators.required
  ]);

  phoneNumber = new FormControl('', [
  ]);

  loginForm: FormGroup = new FormGroup(
    {
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      password: this.password,
      confirmPass: this.confirmPass
    }
  );

  createUser: ICreateUser;
  countryCode = 'DK';
  phone: string;
  // phoneNumber: string;
  allowContact = false;
  terms: boolean;
  isAvaliable = false;
  btnEnabled = true;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private authService: AuthService,
    private loadingService: LoadingService
    ) {
  }

  ngOnInit() {
    this.dialog.open(ParkingGDPRComponent, {
      disableClose: true,
      width: '350px',
    });
  }

  create(): void {
    this.btnEnabled = false;
    this.createUser = {
      password: this.password.value,
      countryCode: this.countryCode,
      firstname: this.firstname.value,
      lastname: this.lastname.value,
      email: this.email.value,
      allowContact: this.allowContact,
      number: this.phoneNumber.value,
      redirectLogin: true
    };

    if (this.loginForm.valid === true && this.password.value === this.confirmPass.value) {
      this.userService.isEmailAvailable(this.email.value).subscribe(result => {
        if (result === true) {
          this.userService.createUser(this.createUser).subscribe(res => {
            localStorage.setItem('newAccount', 'newaccount');
            this.loadingService.startLoading();
        if (this.redirect !== undefined && this.redirect === true) {
          let redirectTo = window.location.pathname.replace('/login', '');

          redirectTo = redirectTo.replace('/createAccount', '');
          this.authService.authenticateWithToken(res.value, redirectTo);
        } else {

            this.dialog.open(AccountSuccessComponent, {
              disableClose: true,
              data: {
                res: res
              }
            });
          }

            this.btnEnabled = true;
          });

          this.loadingService.stopLoading();
        } else {
            this.dialog.open(AccountFailureComponent, {
              width: '500px'
            });

            this.btnEnabled = true;
            this.loadingService.stopLoading();
        }
      });
      } else {
          this.dialog.open(AccountFailureComponent, {
            width: '500px'
          });

          this.btnEnabled = true;
          this.loadingService.stopLoading();
      }
    }
  }

  @Component({
    selector: 'app-accountsuccess',
    templateUrl: './accountsuccess.component.html',
    styleUrls: ['./accountsuccess.component.css']
  })
  export class AccountSuccessComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<AccountSuccessComponent>, private authService: AuthService,
      @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
    }

    redirect() {
      let redirectTo = window.location.pathname.replace('/login', '');

      redirectTo = redirectTo.replace('/createaccount', '');
      this.authService.authenticateWithToken(this.data.res.value, redirectTo);
      this.dialogRef.close();
    }
  }

  @Component({
    selector: 'app-accountfailure',
    templateUrl: './accountfailure.component.html',
    styleUrls: ['./accountfailure.component.css']
  })
  export class AccountFailureComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<AccountFailureComponent>, private authService: AuthService) {}

    ngOnInit() {
    }

    ok() {
      this.dialogRef.close();
    }
  }
