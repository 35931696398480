import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService} from './auth.service';

import { ICreateUser, IUpdateUser, IUser } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  createUser(user: ICreateUser): Observable<any> {
    return this.http.post<any>(environment.apiBaseUrl + `user`, user);
  }

  updateUser(user: IUpdateUser): Observable<{}> {
    return this.http.put(environment.apiBaseUrl + `user`, user, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  getUserDetails(): Observable<IUser> {
    return this.http.get<IUser>(environment.apiBaseUrl + `user`, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  isEmailAvailable(email: string): Observable<boolean> {
    return this.http.get<{isUsernameValid: boolean, isEmailValid: boolean}>(
      environment.apiBaseUrl + `user/availability?username=notused&email=${email}`)
      .pipe(map(data => data.isEmailValid));
  }

  changePassword(currentPassword: string, newPassword: string): Observable<{}> {
    return this.http.put(environment.apiBaseUrl + `user/changepassword/web`, { Current: currentPassword, New: newPassword }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  closeAccount(): Observable<{}> {
    return this.http.post(environment.apiBaseUrl + `user/closeAccount`, {}, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  closeAccountAnonymous(userGuid: string): Observable<{}> {
    return this.http.get(environment.apiBaseUrl + `user/CloseAccountAnonymous/${userGuid}`, {
    });
  }

  requestPasswordReset(email: string): Observable<{}> {
    return this.http.get(environment.apiBaseUrl + `user/password/request/user/${email}/?web=true`);
  }

  passwordReset(token: string, identifier: string, password: string): Observable<{}> {
    const data = { identifier: identifier, password: password };
    return this.http.post(environment.apiBaseUrl + `user/password/request/user/${token}`, data);
  }
}
