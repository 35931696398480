import { Component, OnInit, Inject } from '@angular/core';
import { PaymentsubscriptionService } from 'src/app/services/paymentsubscription.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { DateTime } from 'luxon';
import { ContractPaymentDialogComponent } from './contractpaymentdialog.component';
import { environment } from 'src/environments/environment';
import { EpayService } from 'src/app/services/epay.service';

@Component({
    selector: 'app-contractpaymentcard',
    templateUrl: './contractpaymentcard.component.html',
    styleUrls: ['./contractpaymentcard.component.css']
})

export class ContractPaymentCardComponent implements OnInit {
    hasPrimary = false;
    areaKey: string;
    parkingId: string;
    other = [];
    primary;

    constructor(
        private authService: AuthService,
        private epayService: EpayService,
        private paymentsubscriptionService: PaymentsubscriptionService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ContractPaymentCardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        const url = 'https://ssl.ditonlinebetalingssystem.dk/integration/ewindow/paymentwindow.js';
        this.loadScript(url);
        this.areaKey = this.data.areaKey;
        this.parkingId = this.data.parkingId;
        this.getCreditCards();
    }

    public loadScript(url: string) {
        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    getCreditCards() {
      this.paymentsubscriptionService.activeSubscriptions().subscribe(x => {
        x.forEach(element => {
        console.log(element)

          if (element.isDefault) {
            if (element.type === 0) {
              this.hasPrimary = true;
              this.primary = element;
            }
          } else { // add this to the default one
            if (element.type === 0) {
              console.log("den kommer her ")
              this.other.push(element);
            }
          }
        });
      });
    }

    selectCard(paymentUId, detailText) {
        const paymentCard = {
            paymentUId: paymentUId,
            detailText: detailText
        };

        localStorage.setItem(`contractPayment`, JSON.stringify(paymentCard));
        this.dialogRef.close(true);
    }

    selectOtherCard(event) {
      const paymentCard = {
        paymentUId: event.option.value.paymentUId,
        detailText: event.option.value.detailText
      };
      localStorage.setItem(`contractPayment`, JSON.stringify(paymentCard));
      this.dialogRef.close(true);
    }

    addCreditCard() {
        const options = {
          countryCode: this.authService.getCountryCode(),
          orderId: `CC${('0000000' + this.authService.getSubjectId()).slice(-7)}${DateTime.utc().toFormat('yyMMddHHmm')}`,
          amount: 0,
          subscription: true,
          instantCallback: true,
          callbackParams: {
            'type': 5,
            'sub': this.authService.getSubjectId()
          },
          instantCapture: false
        };

        this.dialog.open(ContractPaymentDialogComponent, {
          disableClose: true,
          width: '430px'
        }).afterOpen().subscribe(x => {
          const opt: any = {
            merchantnumber: environment.epayMerchantNumber,
            currency: 'DKK',
            amount: Math.floor(options.amount * 100),
            orderid: options.orderId,
            mobile: 0,
            accepturl: `${environment.host}/contractparking/${this.areaKey}/edit/${this.parkingId}`,
            subscription: options.subscription === true ? 1 : 0,
            instantcapture: options.instantCapture === true ? 1 : 0,
            instantcallback: options.instantCallback === true ? 1 : 0,
            windowstate: 3,
            subscriptiontype: 'cardonfile',
            // iframeheight: 400,
            // iframewidth: '100%',
            //cssurl: environment.epayCss
          };

          let callbackUrl = environment.epayCallbackUrl;

          if (options.callbackParams) {
            callbackUrl += '?';

            // tslint:disable-next-line:forin
            for (const key in options.callbackParams) {
              const value = options.callbackParams[key];
              callbackUrl += `${key}=${value}&`;
            }
          }

          opt.callbackurl = callbackUrl;

          this.epayService.hashParams(opt).subscribe(res => {
            opt.hash = res.hash;

            const paymentWindow = new PaymentWindow(opt);
            paymentWindow.append('payment-div');
            paymentWindow.open();
          });
        });
    }
}
