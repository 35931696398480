import { Component, OnInit, Input } from '@angular/core';
import { DateTime } from 'luxon';
import {MatDialogRef} from '@angular/material';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { IProduct } from 'src/app/models/contract';
import { ContractService } from 'src/app/services/contract.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FacilityService } from 'src/app/services/facility.service';
import { AuthService } from 'src/app/services/auth.service';
import { IParkingFacility } from '../../models/parkingFacility';

@Component({
  selector: 'app-contractlogin',
  templateUrl: './contractlogin.component.html',
  styleUrls: ['./contractlogin.component.css']
})

export class ContractLoginComponent implements OnInit {
  currentFacility: IParkingFacility;
  spot: string;
  private subscription: any;
  form: any;

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private contractService: ContractService,
    private facilityService: FacilityService) {
      this.subscription = this.route.params.subscribe(params => {
        this.spot = params['spotNum'];
      });
    }

  ngOnInit() {
    this.facilityService.getFacility('DK', this.spot).subscribe(result => {
      this.currentFacility = result;
    });
    this.form = JSON.parse(localStorage.getItem('contractparking')) as object;


    const divs = document.getElementById('mainRow');
    divs.scrollIntoView();

    console.log(this.form);
  }

  login() {
    this.authService.authenticate('contractparking/' + this.spot);
  }
}
