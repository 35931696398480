import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { VehicleregistrationService } from '../../services/vehicleregistration.service';
import { IVehicleRegistrationCreate, IVehicleRegistration } from '../../models/vehicleregistration';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contractaddcar',
  templateUrl: './contractaddcar.component.html',
  styleUrls: ['./contractaddcar.component.css']
})
export class ContractAddCarComponent implements OnInit {
  private authService: AuthService;
  private vehicleRegistrationService: VehicleregistrationService;
  private vehicleCreate: IVehicleRegistrationCreate;
  favorited = true;

  regNum = new FormControl('', [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(9),
    Validators.pattern(`^[a-zA-Z0-9æøåäëïöüÆØÅÄËÏÖÜ]*$`)
  ]);

  description = new FormControl('', [
    Validators.required
  ]);

  favoriteCars = [];
  secondaryCars = [];

  constructor(authService: AuthService, vehicleRegistrationService: VehicleregistrationService,
    private router: Router, public dialogRef: MatDialogRef<ContractAddCarComponent>) {
    this.authService = authService;
    this.vehicleRegistrationService = vehicleRegistrationService;
  }

  createCar() {
    if (this.regNum.valid === true) {
      this.vehicleCreate = {
        country: this.authService.getCountryCode(),
        description: this.description.value,
        typed: this.regNum.value,
        isPrimary: this.favorited
      };

      this.vehicleRegistrationService.create(this.vehicleCreate).subscribe(result => {
      });

      this.dialogRef.close();
    }
  }

  setFavorite() {
    if (this.favorited === true) {
      this.favorited = false;
    } else {
      this.favorited = true;
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }
}
