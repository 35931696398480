import { Component, OnInit , ChangeDetectorRef} from '@angular/core';
import {CurrentparkingService} from '../../services/currentparking.service';
import {EventService} from '../../services/event.service';
import {AuthService} from '../../services/auth.service';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import { CurrentParking } from '../../models/currentparking';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTime, Duration } from 'luxon';
import { IfStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { delay } from 'q';
@Component({
  selector: 'app-current-parkings',
  templateUrl: './current-parkings.component.html',
  styleUrls: ['./current-parkings.component.css']
})
export class CurrentParkingsComponent implements OnInit {
isMobileView: boolean;
subscriptionMedia: any;
parkingOne: any;
parkingTwo: any;
currentParkings: any[];
hide = true;
  constructor(private media: ObservableMedia, private currentParkingService: CurrentparkingService,
    private authService: AuthService,
    private router: Router,
    private eventService: EventService,
    private cd: ChangeDetectorRef
  ) { 
this.eventService.updateMyParkings().subscribe(x => this.updateParkingCallback(x)  );


  }

  ngOnInit() {

    this.isMobileView = (this.media.isActive('xs') || this.media.isActive('sm'));
    this.subscriptionMedia = this.media.subscribe((change: MediaChange) => {
      this.isMobileView = (change.mqAlias === 'xs' || change.mqAlias === 'sm');
    });
    if (this.authService.isAuthenticated) {
    this.currentParkingService.currentParkings().subscribe(x => {
      this.currentParkings = x;
      const allParkings: CurrentParking[] = [];
      x.forEach(park => {
        let end = null;
        if (park.data.endtime != null) { end = DateTime.fromISO(park.data.endtime.toString(), {setZone: true}).set({hour: 0, minute : 0 });}
        if (park.data.parkingType !== 4 &&
           (end > DateTime.fromISO(DateTime.local().toString(), {setZone: true}).set({hour: 0, minute : 0 })
            || park.data.endtime == null)) {
          allParkings.push(park);
        }
      });
      if (allParkings.length >= 1) {
        this.hide = false;
        this.parkingOne = allParkings[0];
      }
      if (allParkings.length > 1) {
        this.parkingTwo = allParkings[1];
      }
    });
  }



  interval(1000).subscribe( l => {
      this.updateParkingCallback(this.eventService.getUpdateMyParkings());
  });

    interval(30000).subscribe( l => {
      this.currentParkingService.currentParkings().subscribe(x => {
        this.currentParkings = x;
        let allParkings: CurrentParking[] = [];
        x.forEach(park => {
          let end = null
          if(park.data.endtime != null) { end = DateTime.fromISO(park.data.endtime.toString(), {setZone: true}).set({hour: 0, minute : 0 });}
          if(park.data.parkingType != 4 && (end > DateTime.fromISO(DateTime.local().toString(), {setZone: true}).set({hour: 0, minute : 0 }) || park.data.endtime == null)) {
            allParkings.push(park)
          }
        });
        if (allParkings.length >= 1) {
          this.hide = false;
          
          this.parkingOne = allParkings[0];
        }
        if (allParkings.length > 1) {
          this.parkingTwo = allParkings[1];
        }
      });
    });
  }



  updateParkingCallback(update: boolean) {

    if (update) {
// console.log('JA DET VIRKER');

this.eventService.setUpdateMyParkings(false);

this.currentParkingService.currentParkings().subscribe(x => {
  this.currentParkings = x;
  const allParkings: CurrentParking[] = [];
  x.forEach(park => {
    let end = null;
    if (park.data.endtime != null) { end = DateTime.fromISO(park.data.endtime.toString(), {setZone: true}).set({hour: 0, minute : 0 }); }
    if (park.data.parkingType !== 4 &&
      (end > DateTime.fromISO(DateTime.local().toString(), {setZone: true}).set({hour: 0, minute : 0 }) || park.data.endtime == null)) {
      allParkings.push(park);
    }
  });
  if (allParkings.length >= 1) {
    this.hide = false;
    this.parkingOne = allParkings[0];
    console.log(this.parkingOne);
  }
  if (allParkings.length > 1) {
    this.parkingTwo = allParkings[1];
  }

 
  
});


    }



  }
  viewAllParkings() {
    this.router.navigate(['/profile/currentparkings/']);
  }
}
