import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable } from '../../../node_modules/rxjs';

@Injectable({
    providedIn: 'root'
  })
export class EpayService {
    constructor(private http: HttpClient) {}

    hashParams(params): Observable<{hash: string}> {
        let hash = '';

        Object.keys(params).forEach(key => hash += params[key]);
        return this.http.post<{hash: string}>(environment.apiBaseUrl + 'PaymentSubscription/getSecret?epayParameters=', `"${hash}"`, {
            headers: {
                'content-type': 'application/json'
            }
        });
    }
}
