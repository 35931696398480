import { Component, OnInit, Inject } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { AnprpaymentService } from '../../services/anprpayment.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { IAnprUserInfo } from '../../models/anprPayment';
import { DateTime } from 'luxon';
import { Location } from '@angular/common';
// import { userInfo } from 'os';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EpayService } from '../../services/epay.service';
import { Subscription } from 'rxjs';
import { PayParkingPaymentDialogComponent } from './paymentdialog.component';
// import { OptionState } from '../../models/EpayPaymentWindow';

@Component({
  selector: 'app-payparking',
  templateUrl: './payparking.component.html',
  styleUrls: ['./payparking.component.css']
})
export class PayParkingComponent implements OnInit {
  private sub: Subscription;

  // firstName = new FormControl('firstName', [
  //   Validators.required
  // ]);
  // lastName = new FormControl('lastName', [
  //   Validators.required
  // ]);

  email = new FormControl('', [
    Validators.email
  ]);

  id: number;

  constructor(private anprPaymentService: AnprpaymentService, public dialog: MatDialog, private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private epayService: EpayService) {

      this.sub = this.route.paramMap
      .subscribe(params => {
        this.id = +params.get('id');
      });
     }

  ngOnInit() {
    const url = 'https://ssl.ditonlinebetalingssystem.dk/integration/ewindow/paymentwindow.js';
    this.loadScript(url);
  }

  public loadScript(url: string) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
}

    pay() {
      const id = this.id;
      const info: IAnprUserInfo = {
      // firstname: this.firstName.value,
      // lastname: this.lastName.value,
      email: this.email.value,
      // allowContact: this.allowContact
    };

    this.dialog.open(PayParkingPaymentDialogComponent, {
      disableClose: true,
      width: '430px'
    }).afterOpen().subscribe(x => {
      const _environment = environment;
      this.anprPaymentService.makeOrder(id, info).subscribe(order => {
        if (order.totalAmount !== 0) {
          const countryCode = order.facilityCountryCode.toUpperCase();
          if (countryCode === 'DK') {

            const options: any = {
              merchantnumber: _environment.epayMerchantNumber,
              currency: 'DKK',
              amount: order.totalAmount,
              orderid: order.orderNumber,
              instantcapture: 1, // enabled
              instantcallback: 1, // enabled
              accepturl: `${_environment.host}/paidparking`, // baseUrl + 'accept',
              cancelurl: `${_environment.host}/paidparking/${id}`,
              callbackurl: `${_environment.epayCallbackUrl}?user_attr_1=4&did=${order.debitorId}&email=${this.email.value}`,
              ownreceipt: 1,
              windowstate: 3,
              // iframeheight: 400,
              // iframewidth: '100%',
              // cssurl: environment.epayCss,
              paymenttype: '1,3,4,29',
            };

            this.epayService.hashParams(options).subscribe(result => {
              options.hash = result.hash;
              const paymentWindow = new PaymentWindow(options);
              // paymentWindow.append('payment-div');
              paymentWindow.open();
            });
          }
        } else {
          this.router.navigate([`/paidparking`]);
          this.dialog.closeAll();
        }
      });
    });
  }
}
