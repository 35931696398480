import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-mapanprparking',
    templateUrl: './mapanprparking.component.html',
    styleUrls: ['./mapanprparking.component.css']
  })
  export class MapANPRParkingComponent implements OnInit {
    dontShowAgain = false;

    constructor(public dialogRef: MatDialogRef<MapANPRParkingComponent>) {}

    ngOnInit() {}

    ok() {
      this.dialogRef.close();
    }
  }
