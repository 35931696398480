import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErrorHttpInterceptor } from './error-http-interceptor';
import { LoaderHttpInterceptor } from './loader-http-interceptor';
import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
import localeDa from '@angular/common/locales/da';

registerLocaleData(localeDa);

import { AppRoutingModule } from './app-routing.module';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule, ClusterManager } from '@agm/js-marker-clusterer';
import { OrganizationDialogComponent } from './Views/mymeanspayment/organization-dialog.component';
import { AppComponent } from './app.component';
import { TopmenuComponent } from './Views/topmenu/topmenu.component';
import { MapComponent, MapCarReminderComponent, MapCardReminderComponent } from './Views/map/map.component';
import { CreateaccountComponent } from './Views/createaccount/createaccount.component';
import { TermsComponent } from './Views/terms/terms.component';
import { MyaccountComponent } from './Views/myaccount/myaccount.component';
import { CreatecarComponent } from './Views/createcar/createcar.component';
import { EditcarComponent } from './Views/editcar/editcar.component';
import { MycarsComponent } from './Views/mycars/mycars.component';
import { MybrobizzComponent } from './Views/mybrobizz/mybrobizz.component';
import { MymeanspaymentComponent } from './Views/mymeanspayment/mymeanspayment.component';
import { VoucherComponent } from './Views/voucher/voucher.component';
import { MyparkingsComponent } from './Views/myparkings/myparkings.component';
import { ParkingDetailsComponent } from './Views/parkingdetails/parkingdetails.component';
import { RegistercardComponent } from './Views/registercard/registercard.component';
import { ErrorComponent } from './Views/error/error.component';
import { MyaccountmenuComponent } from './Views/myaccountmenu/myaccountmenu.component';
import { CreateparkingComponent,
        CreateparkingvoucherSuccessComponent,
        CreateparkingvoucherFailureComponent } from './Views/createparking/createparking.component';
import { PayAndCheckoutComponent } from './Views/payandcheckout/payandcheckout.component';
import { DialogOverviewExampleDialog } from './Views/payandcheckout/payandcheckout.component';
import { VoucherDialogComponent } from './Views/voucher/voucher.component';
import { InfoDialogComponent } from './Views/voucher/voucher.component';
import { CustomersupportComponent } from './Views/customersupport/customersupport.component';
import { QuickParkComponent } from './Views/createparking/quickpark.component';
import { SigninComponent } from './Views/signin/signin.component';
import { PayParkingComponent } from './Views/payandcheckout/payparking.component';
import { DialogTimeComponent, DialogDateComponent } from './Views/parking-time/parking-time.component';
import { DeleteCarDialogComponent} from './Views/editcar/editcar.component';
import { DeleteCardDialogComponent } from './Views/mymeanspayment/mymeanspayment.component';
import { AddNumberDialogComponent } from './Views/mobilenumbers/mobilenumbers.component';
import { StopparkingComponent } from './Views/currentparking/currentparking.component';
import { ValidateNumberDialogComponent } from './Views/mobilenumbers/mobilenumbers.component';
import { DeleteNumberDialogComponent } from './Views/mobilenumbers/mobilenumbers.component';
import { CookieComponent } from './Views/cookie/cookie.component';
import { DeleteAccountDiaglogComponent } from './Views/myaccount/myaccount.component';
import { BrobizzDialogComponent, BrobizzDialogComponent1, BrobizzDialogComponent2, BrobizzDialogComponent3  } from './Views/mymeanspayment/mymeanspayment.component';
import { EditAccountSuccessDiaglogComponent } from './Views/myaccount/myaccount.component';
import { EditAccountFailureDiaglogComponent } from './Views/myaccount/myaccount.component';
import { PaymentDialogComponent } from './Views/createparking/paymentdialog.component';
import { MeansPaymentDialogComponent } from './Views/mymeanspayment/paymentdialog.component';
import { ParkingFailedComponent } from './Views/createparking/createparking.component';
import { QuickparkFailureComponent } from './Views/createparking/quickpark.component';
import { AppNudgeComponent } from './Views/appnudge/appnudge.component';
import { PayParkingPaidComponent } from './Views/payandcheckout/payparkingpaid.component';
import { PayParkingPaymentDialogComponent } from './Views/payandcheckout/paymentdialog.component';
import { ParkingGDPRComponent } from './Views/createparking/createparking.component';
import { IENudgeComponent } from './Views/ienudge/ienudge.component';
import { IEDateComponent } from './Views/ie-date/ie-date.component';
import { ResetPasswordAppComponent } from './Views/resetpasswordapp/resetpasswordapp.component';
import { ContactFormComponent } from './Views/contactform/contactform.component';
import { ContactSuccessComponent } from './Views/contactform/contactsuccess.component';
import { ContactFailedComponent } from './Views/contactform/contactfailed.component';
import { ChangePasswordSuccessComponent } from './Views/changepassword/changepasswordsuccess.component';
import { ParkingCardNeededComponent } from './Views/createparking/createparking.component';
import { PromoComponent } from './Views/promo/promo.component';
import { DMRComponent } from './Views/dmr/dmr.component';
import { DMRConfirmComponent } from "./Views/dmrConfirm/dmrConfirm.component";
import { VoucherParkingComponent } from './Views/voucherparking/voucherparking.component';
import { VoucherParkingSuccessComponent } from './Views/voucherparking/voucherparkingsuccess.component';
import { VoucherParkingConfirmationComponent } from './Views/voucherparking/voucherparking.component';
import { VoucherParkingDoneComponent } from './Views/voucherparking/voucherparkingdone.component';
import { VoucherParkingWarningComponent } from './Views/voucherparking/voucherparking.component';
import { ContractParkingComponent } from './Views/contract/contractparking.component';
import { ContractLoginComponent } from './Views/contract/contractlogin.component';
import { ContractPickCarComponent } from './Views/contract/contractpickcar.component';
import { ContractPaymentCardComponent } from './Views/contract/contractpaymentcard.component';
import { ContractAddCarComponent } from './Views/contract/contractaddcar.component';
import { ContractPaymentDialogComponent } from './Views/contract/contractpaymentdialog.component';
import { ContractCheckoutComponent } from './Views/contract/contractcheckout.componet';
import { ContractConfirmComponent } from './Views/contract/contractconfirm.component';
import { ContractParkingEditComponent } from './Views/contract/contractparkingedit.component';
import { ContractCancelComponent } from './Views/contract/contractcancel.component';
import { ContractCancelSuccessComponent } from './Views/contract/contractcancelsuccess.component';
import { ContractChangeDateComponent } from './Views/contract/contractchangedate.component';
import { ContractChangeLicenseplateComponent } from './Views/contract/contractchangelicenseplate.component';
import { ContractConfirmExtendComponent } from './Views/contract/contractconfirmextend.component';
import { ContractPasswordComponent } from './Views/contract/contractpassword.component';
import { ParkSliderComponent } from './Views/parkslider/parkslider.component';
import { SliderZoneNoticeComponent } from './Views/parkslider/sliderzonenotice.component';
import { SliderDateErrorComponent } from './Views/parkslider/sliderdateerror.component';
import { SliderDatePickerComponent } from './Views/parkslider/sliderdatepicker.component';
import { MapQuickParkComponent } from './Views/map/mapquickpark.component';
import { MapANPRParkingComponent } from './Views/map/mapanprparking.component';
import { CheckOutSendComponent } from './Views/payandcheckout/checkoutsend.component';
import { DeactivateLandingComponent } from './Views/deactivatelanding/deactivatelanding.component';
import { SignoutDialogComponent } from './Views/signoutdialog/signoutdialog.component';
import { ContractAddressDialogComponent } from './Views/contract/contractaddressdialog.component';
import { MapClosingHoursComponent } from './Views/map/mapclosinghours.component';
import { SliderClosingHoursComponent } from './Views/parkslider/sliderclosinghours.component';
import { ContractChangeValidFromComponent } from './Views/contract/contractchangevalidfrom.component'

import {
  MatDialogModule,
  MatSelectionListChange,
  MatListOption,
  MatButtonToggleModule,
  MatSidenavModule,
  MatSidenav,
  MatSidenavContainer,
  MatDatepickerModule, MAT_DATE_LOCALE,
  MatFormFieldModule,
  MatSidenavContent,
  MatToolbarModule,
  MatAutocompleteModule,
  MatInputModule,
  MatButtonModule,
  MatNativeDateModule,
  MatSelectModule,
  MatCheckboxModule,
  MatIconModule, MatListModule,
  MatStepperModule,
  MatProgressBarModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatGridListModule,
  MatCardModule,
  MatExpansionModule,
  MatTabsModule,
  MatSliderModule
} from '@angular/material';


import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AgmDirectionModule } from 'agm-direction';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { group } from '@angular/animations';
import { CurrentParkingsComponent } from './Views/current-parkings/current-parkings.component';
import { CurrentparkingComponent } from './Views/currentparking/currentparking.component';
import { CurrentparkingsoverviewComponent } from './Views/currentparkingsoverview/currentparkingsoverview.component';
import { MobilenumbersComponent } from './Views/mobilenumbers/mobilenumbers.component';
import { ServiceErrorComponent } from './Views/service-error/service-error.component';
import { GdprtermsComponent } from './Views/gdprterms/gdprterms.component';
import { ChangepasswordComponent } from './Views/changepassword/changepassword.component';
import { ParkingTimeComponent } from './Views/parking-time/parking-time.component';
import { RedirectComponent } from './Views/redirect/redirect.component';
import { AccountSuccessComponent } from './Views/createaccount/createaccount.component';
import { AccountFailureComponent } from './Views/createaccount/createaccount.component';
import { EpayhandlerComponent } from './Views/epayhandler/epayhandler.component';
import { LeaseMapComponent } from './Views/leasemap/leasemap.component';
import { ForcedTermsComponent } from './Views/ForcedTerms/forcedterms.component';

@NgModule({
  declarations: [
    AppComponent,
    OrganizationDialogComponent,
    TopmenuComponent,
    ForcedTermsComponent,
    MapComponent,
    CreateaccountComponent,
    TermsComponent,
    MyaccountComponent,
    CreatecarComponent,
    EditcarComponent,
    MycarsComponent,
    MybrobizzComponent,
    MymeanspaymentComponent,
    VoucherComponent,
    MyparkingsComponent,
    RegistercardComponent,
    ErrorComponent,
    MyaccountmenuComponent,
    CreateparkingComponent,
    CustomersupportComponent,
    PayAndCheckoutComponent,
    DialogOverviewExampleDialog,
    SigninComponent,
    PayParkingComponent,
    VoucherDialogComponent,
    InfoDialogComponent,
    ParkingDetailsComponent,
    QuickParkComponent,
    CurrentParkingsComponent,
    CurrentparkingComponent,
    MobilenumbersComponent,
    ServiceErrorComponent,
    CurrentparkingsoverviewComponent,
    GdprtermsComponent,
    DialogTimeComponent,
    DialogDateComponent,
    ChangepasswordComponent,
    ParkingTimeComponent,
    DMRComponent,
    DMRConfirmComponent,
    DeleteCarDialogComponent,
    DeleteCardDialogComponent,
    AddNumberDialogComponent,
    ValidateNumberDialogComponent,
    DeleteNumberDialogComponent,
    RedirectComponent,
    StopparkingComponent,
    CookieComponent,
    DeleteAccountDiaglogComponent,
    AccountSuccessComponent,
    CreateparkingvoucherSuccessComponent,
    CreateparkingvoucherFailureComponent,
    EpayhandlerComponent,
    AccountFailureComponent,
    BrobizzDialogComponent,
    BrobizzDialogComponent1,
    BrobizzDialogComponent2,
    BrobizzDialogComponent3,
    EditAccountSuccessDiaglogComponent,
    EditAccountFailureDiaglogComponent,
    MeansPaymentDialogComponent,
    PaymentDialogComponent,
    ParkingFailedComponent,
    QuickparkFailureComponent,
    AppNudgeComponent,
    PayParkingPaidComponent,
    PayParkingPaymentDialogComponent,
    ParkingGDPRComponent,
    IENudgeComponent,
    IEDateComponent,
    ResetPasswordAppComponent,
    ContactFormComponent,
    ContactSuccessComponent,
    ContactFailedComponent,
    ChangePasswordSuccessComponent,
    ParkingCardNeededComponent,
    PromoComponent,
    VoucherParkingComponent,
    VoucherParkingSuccessComponent,
    VoucherParkingConfirmationComponent,
    VoucherParkingDoneComponent,
    VoucherParkingWarningComponent,
    ContractParkingComponent,
    ContractLoginComponent,
    ContractPickCarComponent,
    ContractPaymentCardComponent,
    ContractAddCarComponent,
    LeaseMapComponent,
    ContractCheckoutComponent,
    ContractConfirmComponent,
    ContractPaymentDialogComponent,
    ContractParkingEditComponent,
    ContractCancelComponent,
    ContractCancelSuccessComponent,
    ContractChangeDateComponent,
    ContractChangeLicenseplateComponent,
    ContractConfirmExtendComponent,
    ContractPasswordComponent,
    MapCarReminderComponent,
    MapCardReminderComponent,
    ParkSliderComponent,
    SliderZoneNoticeComponent,
    SliderDateErrorComponent,
    SliderDatePickerComponent,
    MapQuickParkComponent,
    MapANPRParkingComponent,
    CheckOutSendComponent,
    DeactivateLandingComponent,
    SignoutDialogComponent,
    ContractAddressDialogComponent,
    MapClosingHoursComponent,
    SliderClosingHoursComponent,
    ContractChangeValidFromComponent
    // CustomersupportComponent
  ],
  entryComponents: [
    DialogOverviewExampleDialog,
    VoucherDialogComponent,
    InfoDialogComponent,
    DialogTimeComponent,
    DialogDateComponent,
    ForcedTermsComponent,
    EditcarComponent,
    CreatecarComponent,
    DeleteCarDialogComponent,
    DeleteCardDialogComponent,
    AddNumberDialogComponent,
    ValidateNumberDialogComponent,
    DeleteNumberDialogComponent,
    StopparkingComponent,
    CookieComponent,
    OrganizationDialogComponent,
    DeleteAccountDiaglogComponent,
    AccountSuccessComponent,
    CreateparkingvoucherSuccessComponent,
    CreateparkingvoucherFailureComponent,
    AccountFailureComponent,
    BrobizzDialogComponent,
    BrobizzDialogComponent1,
    DMRComponent,
    DMRConfirmComponent,
    BrobizzDialogComponent2,
    BrobizzDialogComponent3,
    EditAccountSuccessDiaglogComponent,
    EditAccountFailureDiaglogComponent,
    MeansPaymentDialogComponent,
    PaymentDialogComponent,
    ParkingFailedComponent,
    QuickparkFailureComponent,
    AppNudgeComponent,
    PayParkingPaymentDialogComponent,
    ParkingGDPRComponent,
    IENudgeComponent,
    ContactSuccessComponent,
    ContactFailedComponent,
    ChangePasswordSuccessComponent,
    ParkingCardNeededComponent,
    PromoComponent,
    VoucherParkingSuccessComponent,
    VoucherParkingConfirmationComponent,
    VoucherParkingWarningComponent,
    ContractPickCarComponent,
    ContractPaymentCardComponent,
    ContractAddCarComponent,
    ContractPaymentDialogComponent,
    ContractCancelComponent,
    ContractCancelSuccessComponent,
    ContractChangeDateComponent,
    ContractChangeLicenseplateComponent,
    ContractConfirmExtendComponent,
    ContractPasswordComponent,
    MapCarReminderComponent,
    MapCardReminderComponent,
    SliderZoneNoticeComponent,
    SliderDateErrorComponent,
    SliderDatePickerComponent,
    MapQuickParkComponent,
    MapANPRParkingComponent,
    CheckOutSendComponent,
    SignoutDialogComponent,
    ContractAddressDialogComponent,
    MapClosingHoursComponent,
    SliderClosingHoursComponent,
    ContractChangeValidFromComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: `${environment.mapApiKey}`
    }),
    AgmJsMarkerClustererModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    FlexLayoutModule,
    AgmJsMarkerClustererModule,
    AgmDirectionModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatListModule,
    InfiniteScrollModule,
    MatProgressBarModule,
    MatStepperModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatCardModule,
    MatExpansionModule,
    MatTabsModule,
    MatSliderModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'da-DK'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderHttpInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'da-DK' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



