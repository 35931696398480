import { AuthService } from '../../services/auth.service';
import { Component, NgModule, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  constructor( private authService: AuthService) {

  }

  ngOnInit() {

    const mat = this.getParamValueQueryString('mat');

    if (!mat) {
      this.authService.handleCallback();
    } else {
      const redirectTo: string = this.getParamValueQueryString('redirectTo');
      this.authService.authenticateWithToken(mat, redirectTo);
    }
  }

  private  getParamValueQueryString( paramName ) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
}

