import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'signoutdialog',
  templateUrl: './signoutdialog.component.html',
  styleUrls: []
})
export class SignoutDialogComponent implements OnInit {

    constructor(
        private router: Router,
        private authService: AuthService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
      )
      {

      }

  ngOnInit() {

  }

  signout() {
    this.data.loggedIn = false;
    this.authService.signout();
  }
}


