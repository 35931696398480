import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Output, EventEmitter} from '@angular/core';

import { Observable, from, of, throwError, Subject } from 'rxjs';
import { map, catchError, switchMap, single, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { IPaymentSubscription } from '../models/paymentsubscription';
import {EmptyObservable} from 'rxjs/observable/EmptyObservable';

@Injectable({
  providedIn: 'root'
})

export class PaymentsubscriptionService {
  public paymentSubscriptionsChanged = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}
  private httpOptions = {
    headers: {
      'Authorization': `Bearer ${this.authService.getAuthHeader()}`
    }
  };

  activeSubscriptions(): Observable<IPaymentSubscription[]> {
    if (!this.authService.isAuthenticated()) {
      return new EmptyObservable();
   }
    return this.http.get<IPaymentSubscription[]>(environment.apiBaseUrl + `paymentsubscription/active`, this.httpOptions);
  }

  allSubscriptions(): Observable<IPaymentSubscription[]> {
    return this.http.get<IPaymentSubscription[]>(environment.apiBaseUrl + `paymentsubscription`, this.httpOptions);
  }

  getSubscription(paymentUId: string): Observable<IPaymentSubscription> {
    return this.http.get<IPaymentSubscription>(environment.apiBaseUrl + `paymentsubscription/${paymentUId}`, this.httpOptions);
  }

  setSubscriptionAsDefault(paymentUId: string): Observable<{}> {
    return this.http.post(environment.apiBaseUrl + `paymentsubscription/${paymentUId}/default`, {}, this.httpOptions);
  }

  deleteSubscription(paymentUId: string): Observable<{}> {
    return this.http.delete(environment.apiBaseUrl + `paymentsubscription/${paymentUId}`, this.httpOptions);
  }

  claimOrganizationCode(organizationCode: string): Observable<{}> {
    return this.http.post(environment.apiBaseUrl + `paymentsubscription/register/organization`,
     {OrganizationCode : organizationCode}, this.httpOptions);
  }
}
