import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PermitService } from '../../services/permit.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { VoucherParkingSuccessComponent } from './voucherparkingsuccess.component';
import { FacilityService } from 'src/app/services/facility.service';
import { ParkingGDPRComponent } from '../createparking/createparking.component';

@Component({
    selector: 'app-voucherparking',
    templateUrl: './voucherparking.component.html',
    styleUrls: ['./voucherparking.component.css']
})
export class VoucherParkingComponent implements OnInit {
    email = new FormControl('', [
        Validators.email
    ]);

    license = new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(9),
        Validators.pattern(`^[a-zA-Z0-9æøåäëïöüÆØÅÄËÏÖÜ]*$`)
    ]);

    voucher = new FormControl('', [
        Validators.required
    ]);

    constructor(private permitService: PermitService, private dialog: MatDialog) { }

    ngOnInit() {
        this.dialog.open(ParkingGDPRComponent, {
            disableClose: true,
            width: '350px',
        });
    }

    create() {
        const createVoucherParking = {
            email: this.email.value,
            licensePlate: this.license.value,
            voucherCode: this.voucher.value
        };

        if (this.license.value !== '' && this.license.errors === null && this.voucher.value !== '') {
            if (this.email.value !== '') {
                this.permitService.createVoucherParking(createVoucherParking).subscribe(voucherParking => {
                    this.dialog.open(VoucherParkingConfirmationComponent, {
                        disableClose: true,
                        data: { voucherParking: voucherParking }
                    });
                });
            } else {
                this.dialog.open(VoucherParkingWarningComponent, {
                    disableClose: true,
                    data: { createVoucherParking: createVoucherParking }
                });
            }
        }
    }
}

@Component({
    selector: 'app-voucherparkingconfirmation',
    templateUrl: './voucherparkingconfirmation.component.html',
    styleUrls: ['./voucherparkingconfirmation.component.css']
})
export class VoucherParkingConfirmationComponent implements OnInit {
    constructor(
        private facilityService: FacilityService,
        private permitService: PermitService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<VoucherParkingConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any) { }

    areas = [];

    email = this.data.voucherParking.email;
    license = this.data.voucherParking.vehicleRegistration;
    startTime = this.data.voucherParking.startTime;
    endTime = this.data.voucherParking.endTime;
    parkingAreas = this.data.voucherParking.parkingAreas;
    allAreas = this.data.voucherParking.allAreas;

    confirm() {
        this.permitService.createParkFromVoucher(this.data.voucherParking).subscribe(res => {
            this.dialogRef.close();
            this.dialog.open(VoucherParkingSuccessComponent, {
                disableClose: true,
                data: {
                    parkingNumber: res
                }
            });
        });
    }

    ngOnInit() {
        if (this.allAreas === false) {
            for (const area of this.parkingAreas) {
                this.areas.push(area.parkingAreaKey.replace('ADK-', '') + ' - ' + area.address);
            }
        } else {
            this.areas.push('Alle parkeringspladser.');
        }
    }
}

@Component({
    selector: 'app-voucherparkingwarning',
    templateUrl: './voucherparkingwarning.component.html',
    styleUrls: ['./voucherparkingwarning.component.css']
})
export class VoucherParkingWarningComponent {
    constructor(
        private permitService: PermitService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private data: any) { }

    confirm() {
        this.permitService.createVoucherParking(this.data.createVoucherParking).subscribe(voucherParking => {
            this.dialog.open(VoucherParkingConfirmationComponent, {
                disableClose: true,
                data: { voucherParking: voucherParking }
            });
        });
    }
}
