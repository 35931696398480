import { Component, OnInit } from '@angular/core';
import { CurrentparkingService } from '../../services/currentparking.service';
import { DateTime } from 'luxon';
@Component({
  selector: 'app-currentparkingsoverview',
  templateUrl: './currentparkingsoverview.component.html',
  styleUrls: ['./currentparkingsoverview.component.css']
})
export class CurrentparkingsoverviewComponent implements OnInit {
  private contractParkings = [];
  public normalParkings = [];

  constructor(private currentParkingService: CurrentparkingService) { }

  ngOnInit() {
    this.currentParkingService.currentParkings().subscribe(parking => {
      for (let i = 0; i < parking.length; i++) {
        if (parking[i].data.parkingType === 4) {
          this.contractParkings.push(parking[i]);
        } else {
          let jsdate : any = parking[i].data.transactions[parking[i].data.transactions.length - 1].validTo;
          let to = DateTime.fromJSDate(new Date(jsdate));
          if(to > DateTime.local()) {
            this.normalParkings.push(parking[i]);
          }
        }
      }
    });
  }
}
