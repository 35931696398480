import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contractcancelsuccess',
  templateUrl: './contractcancelsuccess.component.html',
  styleUrls: ['./contractcancelsuccess.component.css']
})

export class ContractCancelSuccessComponent implements OnInit {
  constructor(private dialog: MatDialog, private router: Router) {}

    ngOnInit() {}

    ok() {
      this.dialog.closeAll();
      this.router.navigate(['/profile/currentparkings']);
    }
}
