import { Component, OnInit } from '@angular/core';

import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css']
})
export class MyaccountComponent implements OnInit {

  private _currentEmail: string;
  private _allowContact: boolean;

  email = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  firstname = new FormControl('', [
    Validators.required
  ]);

  lastname = new FormControl('', [
    Validators.required
  ]);

  phoneNumber = new FormControl('', [
  ]);

  mobileNumber: string;

  constructor(private userService: UserService, private authService: AuthService,
    public dialog: MatDialog) {

    if (authService.isAuthenticated) {
      this.getUser();
    }
   }

  ngOnInit() {
  }

  getUser() {
    this.userService.getUserDetails().subscribe(result => {
      this.firstname.setValue(result.firstname);
      this.lastname.setValue(result.lastname);
      this.email.setValue(result.email);
      this._currentEmail = result.email;
      this._allowContact = result.allowContact;
      this.phoneNumber.setValue(result.number);
      this._currentEmail = result.email;
    });
  }

  update() {
    const updateUser = {
      firstname: this.firstname.value,
      lastname: this.lastname.value,
      email: this.email.value,
      allowContact: this._allowContact,
      number: this.phoneNumber.value
    };

    if (this._currentEmail !== this.email.value) {
      this.userService.isEmailAvailable(this.email.value).subscribe(result => {
        const isAvaliable = result;

        if (isAvaliable) {
          this.userService.updateUser(updateUser).subscribe(x => {
            this.dialog.open(EditAccountSuccessDiaglogComponent, {
            });
          });
        } else {
          this.dialog.open(EditAccountFailureDiaglogComponent, {
          });
        }
    });
    } else {
      this.userService.updateUser(updateUser).subscribe(x => {
        this.dialog.open(EditAccountSuccessDiaglogComponent, {
        });
      });
    }
  }

  openDeleteModal() {
    this.dialog.open(DeleteAccountDiaglogComponent, {
    });
  }
}

@Component({
  selector: 'app-deleteaccount',
  templateUrl: './deleteaccount.component.html',
  styleUrls: ['./deleteaccount.component.css']
})
export class DeleteAccountDiaglogComponent implements OnInit {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    public dialogRef: MatDialogRef<DeleteAccountDiaglogComponent>) {
  }

  ngOnInit() {
  }

  deleteAccount() {
    this.userService.closeAccount().subscribe(x => {
      this.authService.signout();
      this.dialogRef.close();
      this.router.navigate(['deactivate/landingpage']);
    });
  }
}

@Component({
  selector: 'app-editaccountsuccess',
  templateUrl: './editaccountsuccess.component.html',
  styleUrls: ['./editaccountsuccess.component.css']
})
export class EditAccountSuccessDiaglogComponent implements OnInit {
  constructor(private userService: UserService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<EditAccountSuccessDiaglogComponent>) {
  }

  ngOnInit() {
  }

  ok() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-editaccountfailure',
  templateUrl: './editaccountfailure.component.html',
  styleUrls: ['./editaccountfailure.component.css']
})
export class EditAccountFailureDiaglogComponent implements OnInit {
  constructor(private userService: UserService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<EditAccountFailureDiaglogComponent>) {
  }

  ngOnInit() {
  }

  ok() {
    this.dialogRef.close();
  }
}
