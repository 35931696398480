import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-contractchangevalidfrom',
  templateUrl: './contractchangevalidfrom.component.html',
  styleUrls: ['./contractchangevalidfrom.component.css']
})

export class ContractChangeValidFromComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ContractChangeValidFromComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
            }
            ngOnInit(){

            }

            onNoClick(): void {
              this.dialogRef.close();
            }
}
