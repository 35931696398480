import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { VoucherParkingComponent } from './voucherparking.component';

@Component({
    selector: 'app-voucherparkingsuccess',
    templateUrl: './voucherparkingsuccess.component.html',
    styleUrls: ['./voucherparkingsuccess.component.css']
  })
  export class VoucherParkingSuccessComponent {
    constructor(private router: Router, public dialogRef: MatDialogRef<VoucherParkingComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log('data', this.data);
    }

    ok() {
      this.router.navigate(['voucherparkingdone']);
    }
  }
