import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { IParking, ICreateParking, ICreateParkingResult } from '../models/parking';
import { DateTime } from 'luxon';



@Injectable({
  providedIn: 'root'
})
export class ParkingService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  getParking(id: number): Observable<IParking> {
    return this.http.get<IParking>(environment.apiBaseUrl + `parking/${id}`, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  getParkings(take: number = 20, offset: number = 0): Observable<IParking[]> {
    return this.http.get<IParking[]>(environment.apiBaseUrl + `parking?Take=${take}&idoffset=${offset}`, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  createParking(parkInfo: ICreateParking): Observable<ICreateParkingResult> {
    const postData = {
      parkingAreaKey: parkInfo.parkingAreaKey,
      facilityCountryCode: parkInfo.facilityCountryCode,
      vehicleRegistrationKey: parkInfo.vehicleRegistrationKey,
      endTime: parkInfo.validTo.toFormat('yyyy-MM-dd HH:mm:ss'),
      voucherCode: parkInfo.voucherCode,
      metaData : {
        'Origin': 'WebApp',
      },
      paymentUId: parkInfo.paymentUId
    };

    return this.http.post<ICreateParkingResult>(environment.apiBaseUrl + `parking`, postData, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  extendParking(parkId: number, extendTo: DateTime, voucherCode?: string, paymentUId?: string): Observable<ICreateParkingResult> {
    const postData = {
      paymentUId: paymentUId,
      Endtime: extendTo.toFormat('yyyy-MM-dd HH:mm:ss'),
      voucherCode: voucherCode
    };

    return this.http.post<ICreateParkingResult>(environment.apiBaseUrl + `parking/${parkId}/extend`, postData, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  stopParking(parkId: number): Observable<{}> {
    return this.http.post(environment.apiBaseUrl + `parking/${parkId}/stop`, {}, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  downloadPdf(parkId: string, Uid : string){
    window.location.href =`https://api.mobile-parking.eu/tasks/pdf/PdfReciept/${parkId}/${Uid}`;
  }
}
