
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { ParkingService } from './services/parking.service';
import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';
import { CurrentparkingService } from './services/currentparking.service';
import { CurrentParking, ICurrentParkingData, CurrentParkingMode } from './models/currentparking';
import { environment } from '../environments/environment';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import { ErrorService } from './services/error.service';
import { ServiceError } from './models/error';
import { timer, interval } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PagespinnerService } from './services/pagespinner.service';
import { PromoService } from './services/promo.service';
import { DateTime } from 'luxon';

import { AppNudgeComponent } from './Views/appnudge/appnudge.component';
import { IENudgeComponent } from './Views/ienudge/ienudge.component';
import { PromoComponent } from './Views/promo/promo.component';
import { SignoutDialogComponent } from './Views/signoutdialog/signoutdialog.component';
import { MatSidenav } from '../../node_modules/@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  public isMobileView: boolean;
  title = 'Apcoa Flow';

  subscriptionMedia: any;
  sidenav: any;

  currentError?: ServiceError;
  showError = false;

  showSpinner = false;

  activeParkings = false;

  parkings = [];

  loggedIn = false;

  @ViewChild('sidenav') public sideNav: MatSidenav;

  constructor(public media: ObservableMedia,
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private errorService: ErrorService,
    private parkingService: ParkingService,
    private pagespinnerService: PagespinnerService,
    private currentParkingService: CurrentparkingService,
    private dialog: MatDialog,
    private promoService: PromoService,
  ) {
    this.showError = false;

    pagespinnerService.StateChange.subscribe((state) => {
      this.showSpinner = state;
    });

    errorService.errorSubject.subscribe((error) => {
      this.currentError = error;
      this.showError = true;

      timer(20000).subscribe(() => {
        this.showError = false;
      });
    });

    interval(10000).subscribe(res => {
      if (this.authService.isAuthenticated() === true) {
        this.loggedIn = true;
      }

      if (this.loggedIn === true && this.authService.isAuthenticated() === false) {
        this.loggedIn = false;
        router.navigate(['']);
      }


      this.currentParkingService.currentParkings().subscribe(result => {
        if (result.length > 0) {
          this.activeParkings = true;
        }
      });


      this.showSpinner = this.loadingService.getLoadingStatus();
    });
  }

  ngOnInit(): void {
    this.isMobileView = (this.media.isActive('xs') || this.media.isActive('sm'));

    this.subscriptionMedia = this.media.subscribe((change: MediaChange) => {
      this.isMobileView = (change.mqAlias === 'xs' || change.mqAlias === 'sm');
    });

    this.currentParkingService.currentParkings().subscribe(result => {
      if (result.length > 0) {
        this.activeParkings = true;
      }
    });

    if (this.isMobileView === true) {
      this.router.events.subscribe(event => {
        this.sideNav.close();
      });
    }

    this.checkForLocalParkings();
    this.appNudge();
    this.ieCheck();
  }

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  login() {
      this.authService.authenticate();
  }

  signout() {
    this.dialog.open(SignoutDialogComponent, 
      {
        width: '400px',
        data: { loggedIn : this.loggedIn }
      });
    // this.loggedIn = false;
    // this.authService.signout();
  }



  appNudge() {
    if  (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
      if (localStorage.getItem('smartphoneIgnored') === null && localStorage.getItem('cookieAcceptance') !== null) {
        this.dialog.open(AppNudgeComponent, {
          disableClose: true,
          width: '350px'
        });
      }
    }
  }

  ieCheck() {
    if  (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.userAgent.indexOf('Trident/') > -1) {
      this.dialog.open(IENudgeComponent, {
        disableClose: true
      });
    }
  }


  onActivate(event) {
}

  checkForLocalParkings() {
    for (let i = 0; i < 100; i++) {
      if (localStorage.getItem(`quickpark${i}`) !== null) {
        const quickpark = JSON.parse(localStorage.getItem(`quickpark${i}`));
        this.currentParkingService.addLocalCurrentParking(new CurrentParking(quickpark, this.currentParkingService));
      }
    }
  }
}
