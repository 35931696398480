import { Component, OnInit } from '@angular/core';
import { ParkingService } from '../../services/parking.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-myparkings',
  templateUrl: './myparkings.component.html',
  styleUrls: ['./myparkings.component.css']
})

export class MyparkingsComponent implements OnInit {
  parkings = [];

  batch = 10;         // size of each query
  lastKey = 0;      // key to offset next query from
  offset = 0;
  finished = false;  // boolean when end of database is reached

  constructor(private parkingService: ParkingService,
    private router: Router) { }

  ngOnInit() {
    this.getParkings();
  }

  onScroll () {
    this.getParkings();
  }

  private getParkings(key?) {
    if (this.finished) { return; }

    this.parkingService
      .getParkings(this.batch, this.lastKey)
      .subscribe(parkings => {
        /// set the lastKey in preparation for next query
        this.lastKey = parkings[parkings.length - 1].parkingNumberLong;
        const newParkings = parkings;

        
        /// Get current parkings in BehaviorSubject
        const currentParkings = this.parkings;
        /// If data is identical, stop making queries
        if (currentParkings.length > 0 && this.lastKey === currentParkings[currentParkings.length - 1].parkingNumberLong) {
          this.finished = true;
        }/// Concatenate new parkings to current parkings
        this.parkings = this.parkings.concat(newParkings);

        // tslint:disable-next-line:forin
        for (const i in this.parkings) {

          if (this.parkings[i].transactions !== null &&  this.parkings[i].transactions.length > 0) {
            this.parkings[i].validTo = this.parkings[i].endtime;
          } else {
            this.parkings[i].validTo = '';
          }

        if (this.parkings[i].parkingType === 4) {
          console.log(this.parkings[i]);
        }
        }
    });
  }

  goToParkingDetails(parkingNumber: number) {
    this.router.navigate(['/parkingdetails/' + parkingNumber]);
  }
}
