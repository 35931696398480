import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-checkoutsend',
    templateUrl: './checkoutsend.component.html',
    styleUrls: ['./checkoutsend.component.css']
  })
  export class CheckOutSendComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<CheckOutSendComponent>) {}

    ngOnInit() {
    }

    ok() {
      this.dialogRef.close();
    }
  }
