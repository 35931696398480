import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { VehicleregistrationService } from '../../services/vehicleregistration.service';
import { IVehicleRegistrationCreate, IVehicleRegistration } from '../../models/vehicleregistration';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-editcar',
  templateUrl: './editcar.component.html',
  styleUrls: ['./editcar.component.css']
})
export class EditcarComponent implements OnInit {
private subscription: any;
private vehicleRegistrationService: VehicleregistrationService;

vehicle: IVehicleRegistration;
key: string;
regNum: string;
description: string;
favorited;

  constructor(private route: ActivatedRoute, vehicleRegistrationService: VehicleregistrationService,
    private router: Router, public dialogRef: MatDialogRef<EditcarComponent>,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.vehicleRegistrationService = vehicleRegistrationService;

    this.key = data.key;
    this.getCar();
   }

   getCar() {
    this.vehicleRegistrationService.registrations().subscribe(result => {
      this.vehicle = result.find(x => x.key === this.key);
      this.regNum = this.vehicle.typed;
      this.description = this.vehicle.description;
      this.favorited = this.vehicle.isPrimary;
    });
   }

   updateDescription() {
    this.vehicleRegistrationService.updateDescription(this.key, this.description).subscribe(result => {
      if (this.favorited === false && this.vehicle.isPrimary === true) {
        this.vehicleRegistrationService.togglePrimary(this.key, false).subscribe(res => {
        });
      } else if (this.favorited === true && this.vehicle.isPrimary === false) {
        this.vehicleRegistrationService.togglePrimary(this.key, true).subscribe(res => {
        });
      }
    });

    this.dialogRef.close();
   }

   deleteCar() {
    this.dialogRef.close();
    this.dialog.open(DeleteCarDialogComponent, {
      data: { key : this.key }
    });
   }

   setFavorite() {
    if (this.favorited === true) {
      this.favorited = false;
    } else {
      this.favorited = true;
    }
  }

  ngOnInit() {
    this.getCar();
  }
}

@Component({
  selector: 'app-deletecar-dialog',
  templateUrl: './deletecar-dialog.component.html',
  styleUrls: ['./deletecar-dialog.component.css']
})

export class DeleteCarDialogComponent implements OnInit {
private vehicleRegistrationService: VehicleregistrationService;

subscription: any;
car: IVehicleRegistration;
key: string;
typed: string;
description: string;


  constructor(private dialogRef: MatDialogRef<DeleteCarDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router, vehicleRegistrationService: VehicleregistrationService,
    public  editDialog: MatDialog) {
    this.vehicleRegistrationService = vehicleRegistrationService;
     }

  ngOnInit() {
    this.key = this.data.key;
    this.getCar();
  }

  getCar() {
    this.vehicleRegistrationService.registrations().subscribe(result => {
      this.car = result.find(x => x.key === this.key);
      this.typed = this.car.typed;
      this.description = this.car.description;
    });
  }

  regret(): void {
    this.dialogRef.close();
    this.editDialog.open(EditcarComponent, {
      data: { key: this.key }
    });
  }

  deleteCar() {
    this.vehicleRegistrationService.delete(this.key).subscribe(result => {
      this.dialogRef.close();
     });
  }
}
