import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MouseEvent, AgmMap } from '@agm/core';
import { FacilityService } from '../../services/facility.service';
import { ClusterStyle, ClusterOptions } from '@agm/js-marker-clusterer/services/google-clusterer-types';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import { CurrentparkingService } from '../../services/currentparking.service';
import { Router } from '@angular/router';
import { CurrentParking } from '../../models/currentparking';
import { GeocodeService } from '../../services/geocode.service';
import { LoadingService } from '../../services/loading.service';
import { PromoService } from '../../services/promo.service';
import { PromoComponent } from '../promo/promo.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ContractService } from 'src/app/services/contract.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-leasemap',
  templateUrl: './leasemap.component.html',
  styleUrls: ['./leasemap.component.css']
})
export class LeaseMapComponent implements OnInit {
  private currentArea: any;
  public markers = [];
  isMobileView: boolean;
  public polygons = [];
  title = 'Apcoa Flow';
  location: {lat: number, lng: number};
  lat: number;
  lng: number;
  userLat: number;
  userLng: number;
  showNotification = false;
  areaName = '';
  area = '';
  isAnpr = false;
  areaHasInformation = false;
  dir = undefined;
  public show = false;
  geoJsonObject: Object;

  markerClicked = false;

  geoEnabled = false;

  @ViewChild('agmMap') public agmMap: AgmMap;

  myControl: FormControl = new FormControl();
  public searchFacilities = [];
  public filteredSearchFacilities = [];

  public clusterStyles: ClusterStyle[] = [
    {
      anchor: [10, 18],
      textSize: 9,
      textColor: '#000000',
      url: 'assets/images/m1.png',
      height: 53,
      width: 65
    }
  ];

  public options = {
    suppressMarkers: true,
    draggable: false,
    polylineOptions : {
      strokeColor: '#0C3559'
    }
  };

  public polyOptions = {
    draggable: false,
    editable: false,
  };

  constructor(
    private ref: ChangeDetectorRef,
    private contractService: ContractService,
    private facilityService: FacilityService,
    private authService: AuthService,
    private currentParkings: CurrentparkingService,
    private currentParkingService: CurrentparkingService,
    private router: Router,
    private media: ObservableMedia,
    private geocodeService: GeocodeService,
    private loadingService: LoadingService,
    private promoService: PromoService,
    private dialog: MatDialog) {}

  public polyStyle = {
    fillColor: '#78B51C',
    strokeColor: '#78B51C',
    strokeWeight: 5,
    icon: 'assets/images/pspot.png',
    visible: true
  };

  filteredOptions: Observable<string[]>;

    ngOnInit() {
      this.isMobileView = (this.media.isActive('xs') || this.media.isActive('sm'));
      const urlParams = new URLSearchParams(window.location.search);

      navigator.geolocation.watchPosition(position => this.positionChange(position.coords));

      this.myControl.valueChanges.subscribe(
        x => this.setSearchFacilities(x)
      );

      this.setSearchFacilities('');

      const that = this;
      that.lat = 55.674211; // Apcoa Danmark
      that.lng = 9.596305;
      that.agmMap.zoom = 14;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          that.lat = position.coords.latitude;
          that.lng = position.coords.longitude;
        });
      }

      this.currentParkings.currentParkings().subscribe(res => {
      });

      this.contractService.getParkingAreas().subscribe(facilities => {
        // tslint:disable-next-line:forin
        for (const i in facilities) {
          let distance = null;
          this.isGeolocationEnabled();

          if (this.geoEnabled === true) {
            distance = this.calculateDistance(facilities[i].latitude, that.lat, facilities[i].longitude, that.lng);
          }

          this.searchFacilities.push({
            city: facilities[i].city,
            address: facilities[i].address,
            area: facilities[i].number,
            lat: facilities[i].latitude,
            lng: facilities[i].longitude,
            // tslint:disable-next-line:no-bitwise
            isAnpr: (facilities[i].state & 4) !== 0 ? true : false,
            index: i,
            distance: distance
          });

          this.markers.push({
            title: facilities[i].address,
            lat: facilities[i].latitude,
            lng: facilities[i].longitude,
            // tslint:disable-next-line:no-bitwise
            isAnpr: (facilities[i].state & 4) !== 0 ? true : false,
            area: facilities[i].number,
            areaName: facilities[i].address + ', ' + facilities[i].zip + ' ' + facilities[i].city,
            hasInformation: facilities[i].hasInformation
          });

          // POLYGONS
          // if (facilities[i].geoJson === null) {
          //   this.markers.push({
          //     title: facilities[i].address,
          //     lat: facilities[i].latitude,
          //     lng: facilities[i].longitude,
          //     // tslint:disable-next-line:no-bitwise
          //     isAnpr: (facilities[i].state & 4) !== 0 ? true : false,
          //     area: facilities[i].number,
          //     areaName: facilities[i].address + ', ' + facilities[i].zip + ' ' + facilities[i].city,
          //     hasInformation: facilities[i].hasInformation
          //   });
          // } else {
          //   this.polygons.push({
          //     title: facilities[i].address,
          //     geoJson: JSON.parse(facilities[i].geoJson),
          //     lat: facilities[i].latitude,
          //     lng: facilities[i].longitude,
          //     // tslint:disable-next-line:no-bitwise
          //     isAnpr: (facilities[i].state & 4) !== 0 ? true : false,
          //     area: facilities[i].number,
          //     areaName: facilities[i].address + ', ' + facilities[i].zip + ' ' + facilities[i].city,
          //     hasInformation: facilities[i].hasInformation
          //   });
          // }
        }
      });

      this.promoCheck(that.lat, that.lng);
    }

  setSearchFacilities(x) {
    this.filteredSearchFacilities = this.filter(x);
  }

  clickedMarker(title: string, index: number) {
    this.markerClicked = true;
    const that = this;
    const area = this.markers[index].area;
    const areaName = this.markers[index].areaName;
    const hasContract = this.markers[index].hasContract;
    const isAnpr = this.markers[index].isAnpr;
    this.currentArea = areaName;
    this.showNotification = true;
    this.areaName = areaName;
    this.isAnpr = isAnpr;
    this.area = area;
    this.lat = this.markers[index].lat;
    this.lng = this.markers[index].lng;
    this.areaHasInformation = this.markers[index].hasInformation;

    // this.markers[index].url = 'assets/images/pspot-selected.svg';

    navigator.geolocation.getCurrentPosition(function(position) {
      that.show = true;
      that.dir = {
        origin: { lat: position.coords.latitude, lng: position.coords.longitude },
        destination: { lat: that.markers[index].lat, lng: that.markers[index].lng }
      };
    });
  }

  clickedPolygon(title: string, index: number) {
    const that = this;
    const area = this.polygons[index].area;
    const areaName = this.polygons[index].areaName;
    this.currentArea = areaName;
    this.showNotification = true;
    this.areaName = areaName;
    this.area = area;
    this.lat = this.polygons[index].lat;
    this.lng = this.polygons[index].lng;
    this.areaHasInformation = this.polygons[index].hasInformation;

    // this.markers[index].url = 'assets/images/pspot-selected.svg';

    navigator.geolocation.getCurrentPosition(function(position) {
      that.show = true;
      that.dir = {
        origin: { lat: position.coords.latitude, lng: position.coords.longitude },
        destination: { lat: that.polygons[index].lat, lng: that.polygons[index].lng }
      };
    });
  }


  clickMarker(area: string) {
  }

  mapClicked($event: MouseEvent) {
    this.markerClicked = false;
    this.showNotification = false;
    this.show = false;
  }

  gotoDirections() {
    window.open('https://www.google.com/maps/?q=' + this.lat + ',' + this.lng, '_blank');
  }

  gotoCreateContract() {
    if(this.authService.isAuthenticated())
    {
      this.authService.createLoginToken().subscribe(x => this.redirectUrl(x.token));
    }
    else {
      this.redirectUrl("");
    }
    // this.router.navigate([`/contractparking/${this.area}`]);
  }
  redirectUrl(token) {
    window.location.href = environment.contractUrl + "/?areakey=ADK-" + this.area + "&redirect=webapp&mat=" + token;
  }


  calculateDistance(lat1: number, lat2: number, long1: number, long2: number) {
    const p = 0.017453292519943295;    // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((lat1 - lat2) * p) / 2 + c(lat2 * p) * c ((lat1) * p) * (1 - c(((long1 - long2) * p))) / 2;
    const dis = (12742 * Math.asin(Math.sqrt(a))); // 2 * R; R = 6371 km
    return parseInt(dis.toString(), 10);
  }

  filter(val: string): string[] {
    return this.searchFacilities.filter(option => {
      val = val.toLowerCase();

      // if (this.searchFacilities.indexOf(val)) {
      //   return option.city.toLowerCase().includes(val.toLocaleLowerCase());
      // }
      if (val === option.city.toLowerCase()) {
        return option.city.toLowerCase().includes(val.toLocaleLowerCase());
      }
      if (Number(val)) {
        return option.area.includes(val);
      } else {
          return option.address.toLowerCase().includes(val.toLowerCase());
        }
      }
    );
  }

  positionChange(coords: Coordinates) {
    this.userLat = coords.latitude;
    this.userLng = coords.longitude;

    // tslint:disable-next-line:forin
    for (const i in this.searchFacilities) {
      const current = this.searchFacilities[i];
      current.distance = this.calculateDistance(current.lat, this.userLat, current.lng, this.userLng);
    }
    this.setSearchFacilities('');
  }

  geocodeLookup(address: string) {
    this.geocodeService.geocodeAddress(address).subscribe(res => {
      this.lat = res.lat;
      this.lng = res.lng;
      this.ref.detectChanges();
    });
  }

  resetLocation() {
    this.lat = this.userLat;
    this.lng = this.userLng;
    this.ref.detectChanges();
  }

  isGeolocationEnabled() {
    const self = this;
    if (!navigator.geolocation)  {
      self.geoEnabled = false;
    } else {
    navigator.geolocation.getCurrentPosition (function() {
          self.geoEnabled = true;
        },
        function (error) {
          if (error.code === error.PERMISSION_DENIED || error.code === error.POSITION_UNAVAILABLE || error.code === error.TIMEOUT) {
            self.geoEnabled = false;
          } else {
              self.geoEnabled = true;
            }
        });
      }
    }

    promoCheck(lat, lng) {
      this.promoService.getActivePromos().subscribe(promo => {
        if (localStorage.getItem(`promo:${promo[0].Id}`) == null) {
          const rad = promo[0].Radius;
          const pLat = promo[0].Lat;
          const pLng = promo[0].Lng;

          const dLat = this.toRad(pLat - lat);
          const dLon = this.toRad(pLng - lng);
          const lat1 = this.toRad(lat);
          const lat2 = this.toRad(pLat);

          const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          const d = rad * c;
          if (d <= rad) {
            this.dialog.open(PromoComponent, {
              width: '420px'
            });
          }
        }
      });
    }

    toRad(value) {
      return value * Math.PI / 180;
    }
  }


