import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { IProduct, IAvability, ICreateContractParking, IContractParking, IContract, IVehicleRegistration, IContractValidFrom, IContractPaymentMethod, IContractAvaliable } from '../models/contract';
import { AuthService } from './auth.service';
import { IParkingFacility } from '../models/parkingFacility';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

    constructor(private http: HttpClient, private authService: AuthService) { }

    createContractParking(createParking: ICreateContractParking): Observable<IContractParking> {
        return this.http.post<IContractParking>(environment.apiBaseUrl + `ContractParking`, createParking, {
            headers: {
                'Authorization': `Bearer ${this.authService.getAuthHeader()}`
            }
        });
    }

    getAvability(startDate, endDate, areaKey): Observable<IAvability> {
        return this.http.get<IAvability>(environment.apiBaseUrl +
            `contractparking/Availability?start=${startDate}&end=${endDate}&areaKey=ADK-${areaKey}`
        );
    }

    getProducts(areaKey): Observable<IProduct[]> {
        return this.http.get<IProduct[]>(environment.apiBaseUrl + `ContractParking/products/adk-${areaKey}`);
    }

    getProduct(productId): Observable<IProduct> {
        return this.http.get<IProduct>(environment.apiBaseUrl + `ContractParking/product/${productId}`);
    }

    getParkingAreas(): Observable<IParkingFacility[]> {
        return this.http.get<IParkingFacility[]>(environment.apiBaseUrl + `ParkingAreas?additionalState=1024`);
    }

    getPlateHistory(parkingId): Observable<IVehicleRegistration[]> {
        return this.http.get<IVehicleRegistration[]>(environment.apiBaseUrl + `ContractParking/${parkingId}/Plate/history`, {
            headers: {
                'Authorization': `Bearer ${this.authService.getAuthHeader()}`
            }
        });
    }

    changePlate(parkingId, newVehicleRegistration): Observable<any> {
        return this.http.post<any>(environment.apiBaseUrl + `contractparking/${parkingId}/Plate/${newVehicleRegistration}`, {}, {
            headers: {
                'Authorization': `Bearer ${this.authService.getAuthHeader()}`
            }
        });
    }

    changeValidFrom(parkingId, vehicleRegistration, validFrom: IContractValidFrom): Observable<string> {
        return this.http.post<string>(environment.apiBaseUrl +
            `contractparking/${parkingId}/${vehicleRegistration}/ChangeValidFrom`, {
                start: validFrom
            },
            {
                headers: {'Authorization': `Bearer ${this.authService.getAuthHeader()}`}
            });
    }

    changePaymentMethod(parkingNumber, paymentMethod: IContractPaymentMethod): Observable<string> {
        return this.http.post<string>(environment.apiBaseUrl + `ContractParking/${parkingNumber}/ChangePayment`, {
            PaymentUid: paymentMethod
        },
        {
            headers: {'Authorization': `Bearer ${this.authService.getAuthHeader()}`}
        });
    }

    extendContractParking(parkingId, vehicleRegistration): Observable<string> {
        return this.http.post<string>(environment.apiBaseUrl + `contractparking/${parkingId}/${vehicleRegistration}/Extend`, {}, {
            headers: {
                'Authorization': `Bearer ${this.authService.getAuthHeader()}`
            }
        });
    }

    cancelContractParking(parkingId, vehicleRegistration): Observable<string> {
        return this.http.post<string>(environment.apiBaseUrl + `contractparking/${parkingId}/${vehicleRegistration}/Cancel`, {}, {
            headers: {
                'Authorization': `Bearer ${this.authService.getAuthHeader()}`
            }
        });
    }

    getContractParking(parkingId): Observable<IContract> {
        return this.http.get<IContract>(environment.apiBaseUrl + `contractparking/${parkingId}`, {
            headers: {
                'Authorization': `Bearer ${this.authService.getAuthHeader()}`
            }
        });
    }

    checkCodeAvaliable(code, productId): Observable<IContractAvaliable> {
        return this.http.get<IContractAvaliable>(environment.apiBaseUrl +
            `contractparking/CodeAvailable?code=${code}&productId=${productId}`);
    }
}
