import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService} from './auth.service';

import { IMobileNumber } from '../models/mobileNumber';

@Injectable({
  providedIn: 'root'
})
export class MobileNumberService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getNumber(): Observable<IMobileNumber[]> {
    return this.http.get<IMobileNumber[]>(environment.apiBaseUrl + 'MobileNumbers', {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  public add(number: string): Observable<void> {
    return this.http.post<void>(environment.apiBaseUrl + 'MobileNumbers', { number: number }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  public delete(number: string): Observable<void> {
    return this.http.delete<void>(environment.apiBaseUrl + `MobileNumbers/${number}`, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  public verify(number: string, code: string): Observable<void> {
    return this.http.post<void>(environment.apiBaseUrl + `MobileNumbers/${number}/Verify`, { code: code }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }
}
