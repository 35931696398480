import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { IParkingFacility } from 'src/app/models/parkingFacility';
import { ITimeLimits } from 'src/app/models/parking';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-mapclosinghours',
    templateUrl: './mapclosinghours.component.html',
    styleUrls: ['./mapclosinghours.component.css']

  })

  export class MapClosingHoursComponent implements OnInit {
    constructor(
      public dialogRef: MatDialogRef<MapClosingHoursComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IParkingFacility) {}
    times: any;
    periods: {from: string, to: string}[];
    ngOnInit() {
      this.times = {};
      this.periods = [];
      if (this.data.closingPeriods != null && this.data.closingPeriods != undefined) {
        this.data.closingPeriods.forEach((e, i) => {
          this.periods.push({from : DateTime.fromISO(e.from).toFormat("dd.MM.yyyy"), to: DateTime.fromISO(e.to).toFormat("dd.MM.yyyy")});
        })
      }
      if (this.data.closingTimes != null && this.data.closingTimes != undefined && this.data.closingTimes.Monday != null && this.data.closingTimes.Monday != undefined) {
        // this.times.Monday = [{from: DateTime.fromISO(this.data.closingTimes.Monday[0].from).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Monday[0].to).toFormat("HH:mm")}];
        this.times.Monday = [{from: DateTime.fromISO(this.data.closingTimes.Monday[0].from).startOf('day').toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Monday[0].from).toFormat("HH:mm")}, {from: DateTime.fromISO(this.data.closingTimes.Monday[0].to).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Monday[0].to).endOf('day').toFormat("HH:mm")}];
      }
      if (this.data.closingTimes != null && this.data.closingTimes != undefined && this.data.closingTimes.Tuesday != null && this.data.closingTimes.Tuesday != undefined) {
        // this.times.Tuesday = [{from: DateTime.fromISO(this.data.closingTimes.Tuesday[0].from).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Tuesday[0].to).toFormat("HH:mm")}];
        this.times.Tuesday = [{from: DateTime.fromISO(this.data.closingTimes.Tuesday[0].from).startOf('day').toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Tuesday[0].from).toFormat("HH:mm")}, {from: DateTime.fromISO(this.data.closingTimes.Tuesday[0].to).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Tuesday[0].to).endOf('day').toFormat("HH:mm")}];
      }
      if (this.data.closingTimes != null && this.data.closingTimes != undefined && this.data.closingTimes.Wednesday != null && this.data.closingTimes.Wednesday != undefined) {
        // this.times.Wednesday = [{from: DateTime.fromISO(this.data.closingTimes.Wednesday[0].from).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Wednesday[0].to).toFormat("HH:mm")}];
        this.times.Wednesday = [{from: DateTime.fromISO(this.data.closingTimes.Wednesday[0].from).startOf('day').toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Wednesday[0].from).toFormat("HH:mm")}, {from: DateTime.fromISO(this.data.closingTimes.Wednesday[0].to).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Wednesday[0].to).endOf('day').toFormat("HH:mm")}];
      }
      if (this.data.closingTimes != null && this.data.closingTimes != undefined && this.data.closingTimes.Thursday != null && this.data.closingTimes.Thursday != undefined) {
        // this.times.Thursday = [{from: DateTime.fromISO(this.data.closingTimes.Thursday[0].from).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Thursday[0].to).toFormat("HH:mm")}];
        this.times.Thursday = [{from: DateTime.fromISO(this.data.closingTimes.Thursday[0].from).startOf('day').toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Thursday[0].from).toFormat("HH:mm")}, {from: DateTime.fromISO(this.data.closingTimes.Thursday[0].to).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Thursday[0].to).endOf('day').toFormat("HH:mm")}];
      }
      if (this.data.closingTimes != null && this.data.closingTimes != undefined && this.data.closingTimes.Friday != null && this.data.closingTimes.Friday != undefined) {
        // this.times.Friday = [{from: DateTime.fromISO(this.data.closingTimes.Friday[0].from).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Friday[0].to).toFormat("HH:mm")}];
        this.times.Friday = [{from: DateTime.fromISO(this.data.closingTimes.Friday[0].from).startOf('day').toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Friday[0].from).toFormat("HH:mm")}, {from: DateTime.fromISO(this.data.closingTimes.Friday[0].to).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Friday[0].to).endOf('day').toFormat("HH:mm")}];
      }
      if (this.data.closingTimes != null && this.data.closingTimes != undefined && this.data.closingTimes.Saturday != null && this.data.closingTimes.Saturday != undefined) {
        // this.times.Saturday = [{from: DateTime.fromISO(this.data.closingTimes.Saturday[0].from).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Saturday[0].to).toFormat("HH:mm")}];
        this.times.Saturday = [{from: DateTime.fromISO(this.data.closingTimes.Saturday[0].from).startOf('day').toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Saturday[0].from).toFormat("HH:mm")}, {from: DateTime.fromISO(this.data.closingTimes.Saturday[0].to).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Saturday[0].to).endOf('day').toFormat("HH:mm")}];
      }
      if (this.data.closingTimes != null && this.data.closingTimes != undefined && this.data.closingTimes.Sunday != null && this.data.closingTimes.Sunday != undefined) {
        // this.times.Sunday = [{from: DateTime.fromISO(this.data.closingTimes.Sunday[0].from).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Sunday[0].to).toFormat("HH:mm")}];
        this.times.Sunday = [{from: DateTime.fromISO(this.data.closingTimes.Sunday[0].from).startOf('day').toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Sunday[0].from).toFormat("HH:mm")}, {from: DateTime.fromISO(this.data.closingTimes.Sunday[0].to).toFormat("HH:mm"), to: DateTime.fromISO(this.data.closingTimes.Sunday[0].to).endOf('day').toFormat("HH:mm")}];
      }
    }
  }
