import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  redeem(code: string): Observable<{}> {
    return this.http.post(environment.apiBaseUrl + `discount/voucher/redeem`, {
      code: code
    }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }
}
