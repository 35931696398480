import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { IVehicleRegistration } from '../../models/vehicleregistration';
import { IPaymentSubscription } from '../../models/paymentsubscription';
import { PaymentsubscriptionService } from '../../services/paymentsubscription.service';
import { VehicleregistrationService } from '../../services/vehicleregistration.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private vehicleRegistrationService: VehicleregistrationService,
    private paymentService: PaymentsubscriptionService,
    private router: Router) {
      this.getUserStatus();
    }

  ngOnInit() {
  }

  getUserStatus() {
    // let cars = 0;
    // let cards = 0;

    // if (this.authService.isAuthenticated() === true) {
    //   this.vehicleRegistrationService.registrations().subscribe(car => {
    //     cars = car.length;
    //     this.paymentService.activeSubscriptions().subscribe(card => {
    //       cards = card.length;

    //       if (cars === 0 && cards === 0) {
    //           this.router.navigate(['profile/paymentsubscription']);
    //       }
    //     });
    //   });
    // }
  }
}
