import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { VehicleregistrationService } from '../../services/vehicleregistration.service';
import { IVehicleRegistrationCreate, IVehicleRegistration } from '../../models/vehicleregistration';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DMRConfirmComponent } from '../dmrConfirm/dmrConfirm.component';
import { DMRComponent } from '../dmr/dmr.component';
import { HttpClient } from '@angular/common/http';
import { DMRInfoObject } from 'src/app/models/DMRInfo';

@Component({
  selector: 'app-createcar',
  templateUrl: './createcar.component.html',
  styleUrls: ['./createcar.component.css']
})
export class CreatecarComponent implements OnInit {
  private authService: AuthService;
  private vehicleCreate: IVehicleRegistrationCreate;
  favorited = true;

  regNum = new FormControl('', [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(9),
    Validators.pattern(`^[a-zA-Z0-9æøåäëïöüÆØÅÄËÏÖÜ]*$`)
  ]);

  description = new FormControl('', [
    Validators.required
  ]);

  favoriteCars = [];
  secondaryCars = [];
  dmrInfo: DMRInfoObject;
  constructor(authService: AuthService, private vehicleRegistrationService: VehicleregistrationService, private http: HttpClient,
    private router: Router, private dialog: MatDialog, public dialogRef: MatDialogRef<CreatecarComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.authService = authService;
  }

  createCar() {
    if (this.regNum.valid === true) {
      if (this.regNum.valid) {
        this.dialogRef.close();
        this.vehicleRegistrationService.DMRInfo(this.regNum.value)
          .subscribe(result => {
            this.dmrInfo = result;
            this.dialog.open(DMRComponent, {
              data: {
                plate: this.regNum.value,
                color: this.dmrInfo.color,
                brand: this.dmrInfo.brand,
                fuel: this.dmrInfo.fuel,
                model: this.dmrInfo.typeName,
                variant: this.dmrInfo.variant
              }
            });
          }, error => {
            this.dialog.open(DMRComponent, {
              data: {
                plate: this.regNum.value,
                color: null,
                brand: null,
                fuel: null,
                model: null,
                variant: null
              }
            });
          }
          );
      }
    }
  }

  setFavorite() {
    if (this.favorited === true) {
      this.favorited = false;
    } else {
      this.favorited = true;
    }
  }

  nextPage() {
    this.router.navigate(['']);
  }
  closeWindow() {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (!this.data) { }
    else { this.regNum.setValue(this.data.plate) }
  }
}
