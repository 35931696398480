import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractProductPeriod, IAvability, IContract, IProduct, IVehicleRegistration } from 'src/app/models/contract';
import { ContractService } from 'src/app/services/contract.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FacilityService } from 'src/app/services/facility.service';
import { PaymentsubscriptionService } from 'src/app/services/paymentsubscription.service';
import { IPaymentSubscription } from 'src/app/models/paymentsubscription';
import { AuthService } from 'src/app/services/auth.service';
import { IParkingFacility } from '../../models/parkingFacility';
import { MatDialog } from '@angular/material/dialog';
import { ContractPickCarComponent } from './contractpickcar.component';
import { ContractPaymentCardComponent } from './contractpaymentcard.component';
import { ErrorService } from '../../services/error.service';
import { ServiceError } from '../../models/error';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ContractChangeDateComponent } from './contractchangedate.component';
import { ContractChangeLicenseplateComponent } from './contractchangelicenseplate.component';
import { ContractChangeValidFromComponent } from './contractchangevalidfrom.component';

import { ContractConfirmExtendComponent } from './contractconfirmextend.component';
import { ContractCancelComponent } from './contractcancel.component';

import { ContractAddressDialogComponent } from './contractaddressdialog.component';

import { UserService } from '../../services/user.service';
import { IUser } from '../../models/user';

@Component({
    selector: 'app-contractparkingedit',
    templateUrl: './contractparkingedit.component.html',
    styleUrls: ['./contractparkingedit.component.css']
})
export class ContractParkingEditComponent implements OnInit {
    private subscription: any;
    parkingId: number;
    spot: string;
    facility: IParkingFacility;
    contract: IContract;
    paymentSubscription: IPaymentSubscription;
    products?: IProduct[];
    product: IProduct;
    daysleft = '';
    plates: IVehicleRegistration[] = [];
    minDate = DateTime.local().plus({ days: 1 }).toString();
    maxDate = DateTime.local().plus({ days: 1, months: 3 }).toString();

    user: IUser;

    form = new FormGroup({
        validFrom: new FormControl(''),
        plade: new FormControl(''),
        card: new FormControl(''),
    });

    constructor(private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private authService: AuthService,
        private errorService: ErrorService,
        private contractService: ContractService,
        private paymentService: PaymentsubscriptionService,
        private facilityService: FacilityService,
        private userService: UserService) { }

    ngOnInit() {
        this.subscription = this.route.params.subscribe(params => {
            this.spot = params['spotNum'];
            this.parkingId = params['parkingId'];
            this.facilityService.getFacilities('ADK').subscribe(result => {
                for (let i = 0; i < result.length; i++) {
                    if (result[i].number === this.spot) {
                        this.facility = result[i];
                    }
                }
            });

            this.contractService.getContractParking(this.parkingId).subscribe(result => {
                this.contract = result;
                this.form.patchValue({ plade: result.vehicleRegistrationTyped });
                // localStorage.setItem(`contractCars`, JSON.stringify([this.contract.vehicleRegistrationTyped]));
                this.contractService.getProduct(this.contract.productId).subscribe(product => {
                    this.product = product;
                    let area = this.contract.parkingAreaKey.replace("ADK-", "");
                    this.product.subAreas = [];
                    this.product.subAreas.push(area);
                    this.product.subAreas = this.product.subAreas.concat(this.contract.subAreas);
                    const now = DateTime.local();
                    const from = DateTime.fromISO(result.validFrom.toString());
                    console.log({ from })
                    console.log({ fromFormatted: from.toJSDate().toLocaleDateString("da-DK", { year: "numeric", month: "2-digit", day: "2-digit", }).replace(/\./g, "-") })
                    this.form.patchValue({ validFrom: result.validFrom });
                    if (from <= DateTime.local()) {
                        this.form.controls.validFrom.disable();
                        let to = DateTime.local();
                        if (result.validTo == null) {
                            to = DateTime.fromISO(result.nextPaymentDate.toString(), { setZone: true });
                        } else {
                            to = DateTime.fromISO(result.validTo.toString(), { setZone: true });
                        }
                        const daysleft = this.daysBetween(now, to);

                        if (daysleft.years > 0) {
                            this.daysleft += daysleft.years + ' år ';
                        }
                        if (daysleft.months > 0) {
                            this.daysleft += daysleft.months + ' md(r). ';
                        }
                        if (daysleft.days > 0) {
                            this.daysleft += Math.round(daysleft.days) + ' dag(e) ';
                        }
                        this.daysleft += 'tilbage';
                    } else {
                        this.daysleft = 'Parkering begynder d. ' + from.toJSDate().toLocaleDateString("da-DK", { year: "numeric", month: "2-digit", day: "2-digit", }).replace(/\./g, "-");
                    }
                });
                if (this.contract.paymentUId !== 'Gratis' && this.contract.paymentUId !== null) {
                    this.paymentService.getSubscription(this.contract.paymentUId).subscribe(subscription => {
                        this.paymentSubscription = subscription;
                        this.form.patchValue({ card: subscription });
                    });
                }
                this.contractService.getPlateHistory(this.parkingId).subscribe(plates => {
                    this.plates = plates;
                });
            });
        });

        this.userService.getUserDetails().subscribe(result => {
            this.user = result;
        });
    }

    changeDate(event: MatDatepickerInputEvent<Date>) {
        const from = DateTime.fromISO(event.value.toISOString());
        switch (this.product.type) {
            case ContractProductPeriod.Day:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ days: 1 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;

            case ContractProductPeriod.TwoDays:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ days: 2 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;

            case ContractProductPeriod.ThreeDays:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ days: 3 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;

            case ContractProductPeriod.FourDays:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ days: 4 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;

            case ContractProductPeriod.FiveDays:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ days: 5 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;

            case ContractProductPeriod.SixDays:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ days: 6 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;

            case ContractProductPeriod.Week:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ days: 7 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;

            case ContractProductPeriod.TwoWeeks:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ days: 14 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;

            case ContractProductPeriod.ThreeWeeks:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ days: 21 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;

            case ContractProductPeriod.Month:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ months: 1 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;
            case ContractProductPeriod.ThreeMonths:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ months: 3 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;
            case ContractProductPeriod.SixMonths:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ months: 6 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;
            case ContractProductPeriod.Year:
                this.contractService.getAvability(from.toFormat('yyyy-LL-dd'),
                    from.plus({ years: 1 }).toFormat('yyyy-LL-dd'), this.spot).subscribe(result => { this.handelChangeDate(result); });
                break;
        }
    }

    handelChangeDate(result: IAvability) {
        if (result.isAvailable) {
            this.showValidFromConfirm();
        } else {
            const errorModel = new ServiceError();
            errorModel.errorCode = 2006;
            this.errorService.sendError(errorModel);
        }
    }

    showVehicleRegistrationTypedConfirm() {
        const dialogRef = this.dialog.open(ContractChangeLicenseplateComponent, {
        }).afterClosed().subscribe(result => {
            if (result) {
                this.changevehicleRegistrationTypedConfirm();
            }
        });
    }
    showPaymentMethodConfirm() {
        const dialogRef = this.dialog.open(ContractChangeDateComponent, {
        }).afterClosed().subscribe(result => {
            if (result) {
                this.changePaymentMethodConfirm();
            }
        });
    }
    showValidFromConfirm() {
        const dialogRef = this.dialog.open(ContractChangeValidFromComponent, {
        }).afterClosed().subscribe(result => {
            if (result) {
                this.changeValidFromConfirm();
            }
        });
    }

    changevehicleRegistrationTypedConfirm() {
        const plate = JSON.parse(localStorage.getItem(`contractCars`));
        this.contractService.changePlate(this.parkingId, plate).subscribe(result => {
            this.form.patchValue({ plade: JSON.parse(localStorage.getItem(`contractCars`)) });
            this.contract.vehicleRegistrationTyped = plate;
            localStorage.removeItem(`contractCars`);
            this.contractService.getPlateHistory(this.parkingId).subscribe(plates => {
                this.plates = plates;
            });
            // this.router.navigate(['contractparking/' + this.spot + "/edit/" + result.number.longValue]);
        });
    }

    changeValidFromConfirm() {
        this.contractService.changeValidFrom(this.parkingId, this.form.value.plade, this.form.value.validFrom).subscribe(result => {
            location.reload();
        });
    }

    changePaymentMethodConfirm() {
        const paymentUId = JSON.parse(localStorage.getItem(`contractPayment`));
        this.contractService.changePaymentMethod(this.parkingId, paymentUId.paymentUId.toLowerCase()).subscribe(result => {
            this.form.patchValue({ card: JSON.parse(localStorage.getItem(`contractPayment`)) });
            localStorage.removeItem(`contractPayment`);
        });
    }

    openAddressDialog() {
        if (!this.user.addressline && !this.user.zip && !this.user.city && !this.user.door)
            return;

        this.dialog.open(ContractAddressDialogComponent, {
            disableClose: true,
            width: '500px',
            maxHeight: '810px',
            height: 'auto',
        });
    }

    openCarDialog() {
        this.dialog.open(ContractPickCarComponent, {
            disableClose: true,
            width: '500px',
            maxHeight: '810px',
            height: 'auto',
            data: { permit: 1 }
        }).afterClosed().subscribe(result => {
            if (result) {
                this.showVehicleRegistrationTypedConfirm();
            }
        });
    }

    openPaymentcardDialog() {
        this.dialog.open(ContractPaymentCardComponent, {
            disableClose: true,
            width: '500px',
            maxHeight: '810px',
            height: 'auto',
            data: {
                areaKey: this.spot,
                parkingId: this.parkingId,
            }
        }).afterClosed().subscribe(result => {
            if (result) {
                this.showPaymentMethodConfirm();
            }
        });
    }

    daysBetween(date1: DateTime, date2: DateTime) {
        const diff = date2.diff(date1, ['years', 'months', 'days']).toObject();
        return diff;
    }

    extendContract() {
        this.dialog.open(ContractConfirmExtendComponent, {
            width: '500px',
            data: {
                parkingId: this.parkingId,
                // vehicleRegistration: this.contract.vehicleRegistrationTyped,
                product: this.product,
                contract: this.contract
            }
        }).afterClosed().subscribe(result => {
            if (result) {
                this.contract.validTo = result;
            }
        });
    }

    cancelContract() {
        this.dialog.open(ContractCancelComponent, {
            width: '500px',
            data: {
                spotNum: this.spot,
                parkingId: this.parkingId,
                vehicleRegistration: this.contract.vehicleRegistrationTyped
            }
        });
    }

    overview() {
        this.router.navigate(['profile/currentparkings']);
    }
}
