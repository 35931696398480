import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-deactivatelanding',
    templateUrl: './deactivatelanding.component.html',
    styleUrls: ['./deactivatelanding.component.css']
  })
  export class DeactivateLandingComponent implements OnInit {
    userGuid: any;
    subscription: any;

    constructor(
      private userService: UserService,
      private route: ActivatedRoute,
    ) {}

    ngOnInit() {
      this.subscription = this.route.params.subscribe(params => {
        this.userGuid = params['userGuid'];
      });

      if (this.userGuid !== null) {
        this.userService.closeAccountAnonymous(this.userGuid).subscribe(result => {});
      }
    }
  }
