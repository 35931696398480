import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { SliderDateErrorComponent } from './sliderdateerror.component';

@Component({
    selector: 'app-sliderdatepicker',
    templateUrl: 'sliderdatepicker.component.html',
  })

  export class SliderDatePickerComponent implements OnInit {
    hours = [];
    min = [];
    startDateValue = DateTime.local().plus({hours: 6}).toJSDate();
    startHourValue = DateTime.local().plus({hours: 6}).hour;
    startMinuteValue = DateTime.local().plus({hours: 6}).minute;
    endDateValue;

    constructor(
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<SliderDatePickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        for ( let i = 0; i < 60; i++) {
            this.min.push(i);
        }

        for ( let i = 0; i < 24; i++) {
            this.hours.push(i);
        }

        this.startDateValue = this.data.startDate.toJSDate();
        this.startHourValue = this.data.startDate.hour;
        this.startMinuteValue = this.data.startDate.minute;
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    saveDate() {
        this.endDateValue = DateTime.fromJSDate(this.startDateValue).set({hour: this.startHourValue, minute: this.startMinuteValue});
        if (this.endDateValue > this.data.startDate.minus({hours: 6})) {
            this.dialogRef.close({date: this.endDateValue});
        } else {
            this.dialog.open(SliderDateErrorComponent, {
                disableClose: true,
                width: '500px'
            });
        }
    }
}
