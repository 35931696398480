import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { DateTime } from 'luxon';

import { AuthService } from '../../services/auth.service';
import { AccountService } from '../../services/account.service';
import { ParkingService } from '../../services/parking.service';
import { FacilityService } from '../../services/facility.service';
import { PaymentsubscriptionService } from '../../services/paymentsubscription.service';
import { VehicleregistrationService } from '../../services/vehicleregistration.service';
import { VoucherService } from '../../services/voucher.service';

import { IParking, ICreateParking, ICreateParkingResult } from '../../models/parking';

import { IPaymentSubscription } from '../../models/paymentsubscription';

import { IVehicleRegistration } from '../../models/vehicleregistration';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ForcedTermsComponent } from '../ForcedTerms/forcedterms.component';

@Component({
  selector: 'app-createparking',
  templateUrl: './createparking.component.html',
  styleUrls: ['./createparking.component.css']
})
export class CreateparkingComponent implements OnInit {
carControl = new FormControl();
cardsControl = new FormControl();

private subscription: any;
private authService: AuthService;
private parkingService: ParkingService;
private vehicleService: VehicleregistrationService;
private parkInfo: ICreateParking;
private facilityService: FacilityService;
private paymentService: PaymentsubscriptionService;
private currentFacility: any;

private _validTo = this.getValidTo().toLocal().plus({hours: 4});

cards = [];
selectedCard: any;

cars = [];
selectedCar: IVehicleRegistration;

id: string;
regNum: string;
email: string;
parkingKnob: number;
price = 0;
fee = 0;

voucher: string;
availableBalance: number;
acceptedVoucher = null;

extendStart?: DateTime;
extendNumber?: number;
extendParkingKey: any;
extendEndTime: DateTime;
extension = false;

isIE = false;

  constructor(
    private route: ActivatedRoute,
    authService: AuthService,
    parkingService: ParkingService,
    public voucherService: VoucherService,
    vehicleService: VehicleregistrationService,
    facilityService: FacilityService, paymentService: PaymentsubscriptionService,
    public accountService: AccountService,
    private router: Router,
    private dialog: MatDialog) {
    this.authService = authService;
    this.parkingService = parkingService;
    this.vehicleService = vehicleService;
    this.facilityService = facilityService;
    this.paymentService = paymentService;

    this.subscription = this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    this.facilityService.getFacility('DK', this.id).subscribe(result => {
      this.currentFacility = result;
    });

    if (authService.isAuthenticated()) {
      this.getCreditCards();
      this.getCars();
    } else {
      this.showGDPR();
    }

    this.checkIe();
    this.getValidTo();
    this.getPrice();
   }

   ngOnInit() {
    const startTime = sessionStorage.getItem('endTime');
    const parkingNumber = sessionStorage.getItem('parkingNumber');
    sessionStorage.removeItem('endTime');
    sessionStorage.removeItem('parkingNumber');

    if (startTime !== null && startTime !== undefined) {
      this.extendStart = DateTime.fromMillis(+startTime).toLocal();
    }

    if (parkingNumber !== null && parkingNumber !== undefined) {
      this.extendNumber = +parkingNumber;
      this.extension = true;
      this.parkingService.getParking(this.extendNumber).subscribe(result => {
        this.extendParkingKey = result.vehicleRegistration.key;
        this.getCars();
      });
    }
  }

  getCars() {
    this.vehicleService.registrations().subscribe(res => {
      for (const i in res) {
        if (this.extendParkingKey !== null && this.extendParkingKey !== undefined) {
          this.selectedCar = res.find(x => x.key === this.extendParkingKey);
        } else if (res[i].isPrimary === true) {
          this.selectedCar = res[i];
          this.cars.push(res[i]);
        } else {
          this.cars.push(res[i]);
        }
      }
    });
  }

  getCreditCards() {
    this.paymentService.activeSubscriptions().subscribe(res => {
      for (const i in res) {
        if (res[i].isDefault === true) {
          this.selectedCard = res[i];
          this.cards.push(res[i]);
        } else {
          this.selectedCard = res[0];
          this.cards.push(res[i]);
        }
      }
      this.checkIfCardsExists();
    });
  }

  checkIfCardsExists() {
    if (this.cards.length <= 0) {
      this.dialog.open(ParkingCardNeededComponent, {
        disableClose: true,
        width: '350px'
      });
    }
  }

  setValidTo(validTo: DateTime): void {
    this._validTo = validTo;
    this.getPrice();
  }

  private getValidTo(): DateTime {
    if (this._validTo) {
      return this._validTo;
    }

    return DateTime.utc();
  }

  getPrice() {
    this.facilityService.getPrice
    (this.id, DateTime.local(), this.getValidTo(), null, null, this.acceptedVoucher).subscribe(res => {
      this.availableBalance = res.discount;
      this.price = res.totalPrice;

      if (res.fee !== null && res.fee !== undefined) {
        this.fee = res.fee;
      }

   });
  }

  createParking() {
    const now = DateTime.local();
    this.parkInfo = {
      parkingAreaKey: this.id,
      facilityCountryCode: 'DK',
      vehicleRegistrationKey: this.selectedCar.key,
      validTo: this.getValidTo(),
      paymentUId: this.selectedCard.paymentUId,
    };

    if (this.acceptedVoucher !== undefined) {
      this.parkInfo.voucherCode = this.acceptedVoucher;
    }

    if (this.extendStart !== null && this.extendNumber !== null && this.extendNumber !== undefined && this.extendStart !== undefined) {
      this.parkingService.extendParking(this.extendNumber, this.getValidTo(), '',
      this.selectedCard.paymentUId).subscribe(res => {
        this.router.navigate(['/']);
      });
    } else {
      this.parkingService.createParking(this.parkInfo).subscribe(result => {
        this.router.navigate(['/']);
      });
    }
  }

  showGDPR() {
    this.dialog.open(ParkingGDPRComponent, {
      disableClose: true,
      width: '350px',
    });
  }

  showQuickPark() {
    if (this.regNum !== undefined) {
      if (this.acceptedVoucher !== null) {
        this.router.navigate([`/createparking/quickpark/${this.id}/${this.regNum}/${this.getValidTo().valueOf()}/${this.acceptedVoucher}`]);
      } else {
        this.router.navigate([`/createparking/quickpark/${this.id}/${this.regNum}/${this.getValidTo().valueOf()}`]);
      }
    } else {
      this.dialog.open(ParkingFailedComponent, {
      });
    }
  }

  addVoucher() {
    this.facilityService.getPrice
    (this.id, DateTime.local(), this.getValidTo(), null, null, this.voucher).subscribe(res => {
      this.availableBalance = res.discount;

    if (this.availableBalance > 0) {
      this.acceptedVoucher = this.voucher;
      this.dialog.open(CreateparkingvoucherSuccessComponent, {
      });
    } else {
      this.acceptedVoucher = null;
      this.dialog.open(CreateparkingvoucherFailureComponent, {
      });
    }

    this.getPrice();
   });
  }

  checkVoucher() {
    if (this.voucher.length >= 1) {
      this.addVoucher();
    }
  }

  checkIe() {
    if  (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.userAgent.indexOf('Trident/') > -1 || navigator.userAgent.match(/Edge/i)) {
      this.isIE = true;
    }
  }

  // saveIeDate() {
  //   const newDate = DateTime.fromJSDate(this.ieDateValue).plus({hours: this.ieHourValue, minutes: this.ieMinuteValue});
  //   this.setValidTo(newDate);
  // }
}

@Component({
  selector: 'app-createparkingvouchersuccess',
  templateUrl: './createparkingvouchersuccess.component.html',
  styleUrls: ['./createparkingvouchersuccess.component.css']
})
export class CreateparkingvoucherSuccessComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateparkingvoucherSuccessComponent>
  ) {}

  ngOnInit() {
  }

  ok() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-createparkingvoucherfailure',
  templateUrl: './createparkingvoucherfailure.component.html',
  styleUrls: ['./createparkingvoucherfailure.component.css']
})
export class CreateparkingvoucherFailureComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateparkingvoucherFailureComponent>
  ) {}

  ngOnInit() {
  }

  ok() {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'app-parkingfailed',
  templateUrl: './parkingfailed.component.html',
  styleUrls: ['./parkingfailed.component.css']
})
export class ParkingFailedComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ParkingFailedComponent>
  ) {}

  ngOnInit() {
  }

  ok() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-parkinggdpr',
  templateUrl: './parkinggdpr.component.html',
  styleUrls: ['./parkinggdpr.component.css']
})
export class ParkingGDPRComponent implements OnInit {
  constructor(
    public router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ParkingGDPRComponent>
  ) {}

  ngOnInit() {
  }

  ok() {
    console.log('start')
    this.dialogRef.close();
    console.log('closed');
    setTimeout(() => this.dialog.open(ForcedTermsComponent));
      // this.dialog.open(ForcedTermsComponent);
      console.log('opened')
      // dialogRef.afterClosed().subscribe(result => {
      //   this.selectedOption = result;
      // });
  }

  cancel() {
    this.dialogRef.close();
    this.router.navigate(['/']);
  }
}

@Component({
  selector: 'app-parkingcardneeded',
  templateUrl: './parkingcardneeded.component.html',
  styleUrls: ['./parkingcardneeded.component.css']
})
export class ParkingCardNeededComponent implements OnInit {
  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<ParkingCardNeededComponent>
  ) {}

  ngOnInit() {
  }

  ok() {
    this.dialogRef.close();
    this.router.navigate(['profile/paymentsubscription']);
  }
}
