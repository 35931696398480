import { Component } from '@angular/core';

@Component({
    selector: 'app-voucherparkingdone',
    templateUrl: './voucherparkingdone.component.html',
    styleUrls: ['./voucherparkingdone.component.css']
  })
  export class VoucherParkingDoneComponent {
    constructor() {}
  }
