import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { DateTime } from 'luxon';

import { AuthService} from './auth.service';
import { CurrentParking, CurrentParkingMode, QuickparkVerifiedResult } from '../models/currentparking';
import { IParking, ITimeLimits } from '../models/parking';

@Injectable({
  providedIn: 'root'
})
export class CurrentparkingService {

  private _local: CurrentParking[] = [];

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  public currentParkings(): Observable<CurrentParking[]> {
    if (this.authService.isAuthenticated()) {
      return this.http.get<IParking[]>(environment.apiBaseUrl + `parking/current`, {
        headers: {
          'Authorization': `Bearer ${this.authService.getAuthHeader()}`
        }
      }).pipe(map(parkings => {
        const current: CurrentParking[] = [];
        parkings.forEach((data, index) => {
          current.push(new CurrentParking({
            starttime: data.starttime,
            endtime: data.endtime,
            canExtend: true,
            canStop: true,
            mode: CurrentParkingMode.Payed,
            parkingAreakey: data.parkingAreaKey,
            parkingAreaCountry: 'DK',
            parkingAreaNumber: data.parkingAreaNumber,
            parkingNumber: data.parkingNumber,
            parkingNumberLong: data.parkingNumberLong,
            parkingAddress: data.parkingAreaAddress.line,
            quickpark: false,
            parkingType: data.parkingType,
            transactions: data.transactions != null ? data.transactions.map((transaction: any) => {
              transaction.created = DateTime.fromISO(transaction.created);
              transaction.validFrom = DateTime.fromISO(transaction.validFrom);
              if (transaction.validTo) {
                transaction.validTo = DateTime.fromISO(transaction.validTo);
              }
              return transaction;
            }) : null,
            vehicleRegistration: data.vehicleRegistration.typed,
            vehicleRegistrationKey: data.vehicleRegistration.key,
            timeLimits: this.timeLimit(data.timeLimits),
            allDay: data.allDay
          }, this));
        });
        return current;
      }));
    } else {
      return of(this._local);
    }
  }

  public timeLimit(timeLimits: ITimeLimits) : string {
    var i = 0;
    var isDone = false;
    var now = DateTime.local();
    if(this.isEmpty(timeLimits))
    { return null;} 
    while(!isDone) {
      if (timeLimits.Monday != null && now.set({weekNumber: now.weekNumber + i, weekday: 1}) > DateTime.local()) {
        return now.set({weekNumber: now.weekNumber + i, weekday: 1}).toISODate() + " kl. " + DateTime.fromISO(timeLimits.Monday[0].from).toLocaleString(DateTime.TIME_24_SIMPLE) + " - " + DateTime.fromISO(timeLimits.Monday[0].to).toLocaleString(DateTime.TIME_24_SIMPLE);
      }
      else if (timeLimits.Tuesday != null && now.set({weekNumber: DateTime.local().weekNumber + i, weekday: 2}) > DateTime.local()) {
        return now.set({weekNumber: now.weekNumber + i, weekday: 2}).toISODate() + " kl. " + DateTime.fromISO(timeLimits.Tuesday[0].from).toLocaleString(DateTime.TIME_24_SIMPLE) + " - " + DateTime.fromISO(timeLimits.Tuesday[0].to).toLocaleString(DateTime.TIME_24_SIMPLE);
      }
      else if (timeLimits.Wednesday != null && now.set({weekNumber: DateTime.local().weekNumber + i, weekday: 3}) > DateTime.local()) {
        return  now.set({weekNumber: now.weekNumber + i, weekday: 3}).toISODate() + " kl. " + DateTime.fromISO(timeLimits.Wednesday[0].from).toLocaleString(DateTime.TIME_24_SIMPLE) + " - " + DateTime.fromISO(timeLimits.Wednesday[0].to).toLocaleString(DateTime.TIME_24_SIMPLE);
      }
      else if (timeLimits.Thursday != null && now.set({weekNumber: DateTime.local().weekNumber + i, weekday: 4}) > DateTime.local()) {
        return now.set({weekNumber: now.weekNumber + i, weekday: 4}).toISODate() + " kl. " + DateTime.fromISO(timeLimits.Thursday[0].from).toLocaleString(DateTime.TIME_24_SIMPLE) + " - " + DateTime.fromISO(timeLimits.Thursday[0].to).toLocaleString(DateTime.TIME_24_SIMPLE);
      }
      else if (timeLimits.Friday != null && now.set({weekNumber: DateTime.local().weekNumber + i, weekday: 5}) > DateTime.local()) {
        return now.set({weekNumber: now.weekNumber + i, weekday: 5}).toISODate() + " kl. " + DateTime.fromISO(timeLimits.Friday[0].from).toLocaleString(DateTime.TIME_24_SIMPLE) + " - " + DateTime.fromISO(timeLimits.Friday[0].to).toLocaleString(DateTime.TIME_24_SIMPLE);
      }
      else if (timeLimits.Saturday != null && now.set({weekNumber: DateTime.local().weekNumber + i, weekday: 6}) > DateTime.local()) {
        return now.set({weekNumber: now.weekNumber + i, weekday: 6}).toISODate() + " kl. " + DateTime.fromISO(timeLimits.Saturday[0].from).toLocaleString(DateTime.TIME_24_SIMPLE) + " - " + DateTime.fromISO(timeLimits.Saturday[0].to).toLocaleString(DateTime.TIME_24_SIMPLE);
      }
      else if (timeLimits.Sunday != null && now.set({weekNumber: DateTime.local().weekNumber + i, weekday: 7}) > DateTime.local()) {
        return now.set({weekNumber: now.weekNumber + i, weekday: 7}).toISODate() + " kl. " + DateTime.fromISO(timeLimits.Sunday[0].from).toLocaleString(DateTime.TIME_24_SIMPLE) + " - " + DateTime.fromISO(timeLimits.Sunday[0].to).toLocaleString(DateTime.TIME_24_SIMPLE);
      }
      i++;
      if (i > 100)
      {
        isDone = true;
      }
    }
  }

  private isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  public addLocalCurrentParking(parking: CurrentParking) {
    this._local.push(parking);
  }

  public removeLocalCurrentParking(parkingNumber: number) {
    const index = this._local.findIndex(x => x.data.parkingNumberLong === parkingNumber);

    if (index > -1) {
      this._local.splice(index, 1);
    }
  }

  public verifyQuickpark(customerId: string, order: string): Observable<QuickparkVerifiedResult> {
    return this.http.get<QuickparkVerifiedResult>(environment.apiBaseUrl + `quickpark/verify/${customerId}/${order}`);
  }
}
