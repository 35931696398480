import { Component, OnInit, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { ICreateContractParking } from 'src/app/models/contract';
import { ContractService } from 'src/app/services/contract.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FacilityService } from 'src/app/services/facility.service';
import { AuthService } from 'src/app/services/auth.service';
import { IParkingFacility } from '../../models/parkingFacility';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-contractconfirm',
  templateUrl: './contractconfirm.component.html',
  styleUrls: ['./contractconfirm.component.css']
})

export class ContractConfirmComponent implements OnInit {
  currentFacility: IParkingFacility;
  spot: string;
  private subscription: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private facilityService: FacilityService) {
    this.subscription = this.route.params.subscribe(params => {
      this.spot = params['spotNum'];
    });
  }

  ngOnInit() {
    localStorage.removeItem('contractparking');
  }

  overview() {
    this.router.navigate(['profile/currentparkings']);
  }
}
