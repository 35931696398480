import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-appnudge',
    templateUrl: './appnudge.component.html',
    styleUrls: ['./appnudge.component.css']
  })
  export class AppNudgeComponent implements OnInit {

    constructor(
    public dialogRef: MatDialogRef<AppNudgeComponent>) {}

    ngOnInit() {
        localStorage.setItem('smartphoneIgnored', '');
    }

    ok() {
        this.dialogRef.close();
    }
  }
