import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ContractService } from '../../services/contract.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-contractpassword',
  templateUrl: './contractpassword.component.html',
  styleUrls: ['./contractpassword.component.css']
})

export class ContractPasswordComponent implements OnInit {
  productId: number;
  errorMessage: string;
  success = false;

  productCode = new FormControl('', [
    Validators.required
  ]);

  constructor(
    private contractService: ContractService,
    private dialogRef: MatDialogRef<ContractPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
       this.productId = data.productId;
  }

  ngOnInit() {
  }

  unlockProduct() {
    this.contractService.checkCodeAvaliable(this.productCode.value, this.productId).subscribe(result => {
      if (result.available === true) {
        this.success = true;
        this.dialogRef.close();
      } else {
          this.errorMessage = 'Kode ikke fundet i systemet';
      }
    });
  }
}
