import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EventService {

  updateParkings = false;
  private _updateMyParkings: Subject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {
  }


setUpdateMyParkings(shouldUpdate: boolean) {
  this.updateParkings = shouldUpdate;
}

getUpdateMyParkings() {
  return this.updateParkings;
}

  updateMyParkings() {
    this._updateMyParkings.next(this.updateParkings);
    return this._updateMyParkings.asObservable();
  }
}
