import { Component, OnInit } from '@angular/core';
import { TextService } from '../../services/text.service';

@Component({
  selector: 'app-gdprterms',
  templateUrl: './gdprterms.component.html',
  styleUrls: ['./gdprterms.component.css']
})
export class GdprtermsComponent implements OnInit {

  public gdprText: string;

  constructor(private textService: TextService) {

    textService.getText('dataprotection').subscribe((text) => this.gdprText = text);
    // textService.getGdprTerms().subscribe((text) => this.gdprText = text);
  }

  ngOnInit() {
  }

}
