
import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
import { UserService } from '../../services/user.service';
import { IUser } from '../../models/user';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-contractaddressdialog',
  templateUrl: './contractaddressdialog.component.html',
  styleUrls: ['./contractaddressdialog.component.css']
})
export class ContractAddressDialogComponent implements OnInit {
    user: IUser;
    addressForm = this.fb.group({
        addressline: ['', [Validators.required]],
        addressline2: [''],
        zip: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
        city: ['', [Validators.required]],
        door: ['', [Validators.required]],
      });
    constructor(private userService: UserService, public dialogRef: MatDialogRef<ContractAddressDialogComponent>, private fb: FormBuilder) {
    }
    ngOnInit() {
        this.userService.getUserDetails().subscribe(result => {
            this.user = result;
            if(!this.user.addressline && !this.user.zip && !this.user.city && !this.user.door)
                return this.dialogRef.close();

            this.addressForm.setValue({addressline: this.user.addressline, addressline2: this.user.addressline2, zip: this.user.zip, city: this.user.city, door: this.user.door})
        });
    }

    onSubmit() {
        if (this.addressForm.invalid)
            return;
        const updateUser = {
            firstname: null,
            lastname: null,
            email: null,
            allowContact: null,
            number: null,
            addressline: this.addressForm.get("addressline").value,
            addressline2: this.addressForm.get("addressline2").value,
            city: this.addressForm.get("city").value,
            zip: this.addressForm.get("zip").value,
            door: this.addressForm.get("door").value
        };
        this.userService.updateUser(updateUser).subscribe(x => {
            return this.dialogRef.close();
        });
    }
}
