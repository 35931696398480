import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, from, of, throwError } from 'rxjs';
import { IValidate, IValidateMobile, IValidateResponse, IEnroll, IEnrollmentCustomer, IEnrollmentCustomerAgreement } from '../models/brobizz';
import { AuthService} from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class BrobizzService {

  constructor(private http: HttpClient, private authService: AuthService) { }
  validate(model: IValidate): Observable<IValidateResponse> {
    return this.http.post<IValidateResponse>(environment.apiBaseUrl + `Brobizz/enrollment/validate`, {
      Email: model.email,
      MobileNumber: model.mobileNumber,
      MobileNumberCountry: model.mobileNumberCountry
    }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  validateEmail(email: string): Observable<IValidateResponse> {
    return this.http.post<IValidateResponse>(environment.apiBaseUrl + `Brobizz/enrollment/validate`, {
      Email: email
    }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  validateMobile(model: IValidateMobile): Observable<IValidateResponse> {
    return this.http.post<IValidateResponse>(environment.apiBaseUrl + `Brobizz/enrollment/validate`, {
      MobileNumber: model.mobileNumber,
      MobileNumberCountry: model.mobileNumberCountry
    }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  enroll(model: IEnroll): Observable<IEnrollmentCustomer> {
    return this.http.post<IEnrollmentCustomer>(environment.apiBaseUrl + `Brobizz/enrollment/enroll`, {
      CustomerNumber: model.customerNumber,
      NotifyMethod: model.notifyMethod
    }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  agreements(token: string): Observable<IEnrollmentCustomerAgreement[]> {
    return this.http.post<IEnrollmentCustomerAgreement[]>(environment.apiBaseUrl + `Brobizz/enrollment/${token}/agreements`, {
    }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }

  authorize(token: string, internalId: string): Observable<{}> {
    return this.http.post(environment.apiBaseUrl + `Brobizz/authorize/${token}/${internalId}`, {
    }, {
      headers: {
        'Authorization': `Bearer ${this.authService.getAuthHeader()}`
      }
    });
  }
}
