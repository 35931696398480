import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-mapquickpark',
    templateUrl: './mapquickpark.component.html',
    styleUrls: ['./mapquickpark.component.css']
  })
  export class MapQuickParkComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<MapQuickParkComponent>) {}

    ngOnInit() {}

    ok() {
      this.dialogRef.close();
    }
  }
