import { Component, OnInit, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { IProduct, ContractProductPeriod, IAvability } from 'src/app/models/contract';
import { ContractService } from 'src/app/services/contract.service';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { FacilityService } from 'src/app/services/facility.service';
import { AuthService } from 'src/app/services/auth.service';
import { IParkingFacility } from '../../models/parkingFacility';
import { log } from 'util';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContractPickCarComponent } from './contractpickcar.component';
import { ContractPaymentCardComponent } from './contractpaymentcard.component';
import { ContractPasswordComponent } from './contractpassword.component';
import { ErrorService } from '../../services/error.service';
import { ServiceError } from '../../models/error';
import { matFormFieldAnimations } from '@angular/material';
import { DISABLED } from '@angular/forms/src/model';

@Component({
  selector: 'app-contractparking',
  templateUrl: './contractparking.component.html',
  styleUrls: ['./contractparking.component.css']
})
export class ContractParkingComponent implements OnInit {
  private subscription: any;
  currentFacility: IParkingFacility;
  products?: IProduct[] = [];
  type: ContractProductPeriod;
  spot: string;
  start: DateTime;
  parkingId: string;
  end: DateTime;
  cars: string[];
  card: object;
  minDate = DateTime.local().plus({days: 1}).toString();
  selectedOptions: string[] = [];

  form = new FormGroup({
    id: new FormControl('', Validators.required),
    selects: new FormArray([], Validators.required),
    permit: new FormControl('', Validators.required),
    start: new FormControl(DateTime.local().plus({days: 1}).toString(), Validators.required),
    end: new FormControl('', Validators.required),
    price: new FormControl('', Validators.required),
    cars: new FormControl('', Validators.required),
    card: new FormControl('', Validators.required),
    expireType: new FormControl(''),
    renew: new FormControl(false),
    locked: new FormControl(false),
    code: new FormControl(''),
    facility: new FormControl(''),
    cartExpiresAt: new FormControl('')
  });

  get selects() { return this.form.get('selects') as FormArray; }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private errorService: ErrorService,
    private contractService: ContractService,
    private facilityService: FacilityService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    let data = JSON.parse(localStorage.getItem('contractparking')) as any;
    this.subscription = this.route.params.subscribe(params => {
      this.spot = params['spotNum'];
      this.parkingId = params['parkingId'];

      const now = DateTime.local().valueOf();
      if ( data != null && (data.facility !== this.spot || data.cartExpiresAt < now) ) {
        localStorage.removeItem('contractparking');
        data = null;
      }
      this.form.patchValue({ facility: this.spot });
      this.facilityService.getFacility('DK', this.spot).subscribe(result => {
        this.currentFacility = result;
      });
    });

    if (data != null) {
      Object.keys(data).forEach(key => {
        if (key !== 'selects') {
          this.form.get(key).patchValue(data[key]);
        }
        if (key === 'renew' && data[key]) {
          this.selectedOptions.push(key);
        }
      });
    } else {
    }
    this.contractService.getProducts(this.spot).subscribe(products => {
      this.products = products;

      products.forEach(element => {
        if (data != null && data.selects != null && data.selects.filter(x => x.value != null).length > 0) {
          if (data.selects.filter(x => x.id === element.id && x.value != null).length > 0) {
            const input = data.selects.find(x => x.id === element.id);
            this.selects.push(this.formBuilder.group({
              id: [input.id, Validators.required],
              price: [input.price, Validators.required],
              description: [element.description, Validators.required],
              title: [element.title, Validators.required],
              iconUrl: [element.iconUrl, Validators.required],
              type: [element.type, Validators.required],
              value: [input.value, Validators.required],
              locked: [input.locked]}));
          } else {
            const fb = this.formBuilder.group({
              id: [{value: element.id, disabled: true}, Validators.required],
              price: [{value: element.price, disabled: true}, Validators.required],
              description: [{value: element.description, disabled: true}, Validators.required],
              title: [{value: element.title, disabled: true}, Validators.required],
              iconUrl: [{value: element.iconUrl, disabled: true}, Validators.required],
              type: [{value: element.type, disabled: true}, Validators.required],
              value: [{value: null, disabled: true}, Validators.required],
              locked: [{value: element.locked, disabled: true}]}, {disable: true});
            this.selects.push(fb);
          }
        } else {
          const obj = {
            type: element.type,
            value: this.form.value.start
          };
          this.checkAvalibility(obj).subscribe(result => {
            if (!result.isAvailable) {
              this.selects.push(this.formBuilder.group({
                id: [{value: element.id, disabled: true}, Validators.required],
                price: [{value: element.price, disabled: true}, Validators.required],
                description: [{value: element.description, disabled: true}, Validators.required],
                title: [{value: element.title, disabled: true}, Validators.required],
                iconUrl: [{value: element.iconUrl, disabled: true}, Validators.required],
                type: [{value: element.type, disabled: true}, Validators.required],
                value: [{value: null, disabled: true}, Validators.required],
                locked: [{value: element.locked, disabled: true}]}, {disable: true}));
            } else {
                this.selects.push(this.formBuilder.group({
                  id: [{value: element.id, disabled: false}, Validators.required],
                  price: [{value: element.price, disabled: false}, Validators.required],
                  title: [{value: element.title, disabled: false}, Validators.required],
                  description: [{value: element.description, disabled: false}, Validators.required],
                  iconUrl: [{value: element.iconUrl, disabled: false}, Validators.required],
                  type: [{value: element.type, disabled: false}, Validators.required],
                  value: [{value: null, disabled: false}, Validators.required],
                  locked: [{value: element.locked, disabled: false}]}, {disable: false}));
            }
           });
        }
      });
    });
  }

  login() {
    this.router.navigate(['contractparking/' + this.spot + '/login']);
  }

  openCarDialog() {
    this.dialog.open(ContractPickCarComponent, {
      disableClose: true,
      width: '500px',
      maxHeight: '810px',
      height: 'auto',
      data: { permit: this.form.value.permit}
    }).afterClosed().subscribe(result => {
      this.cars = JSON.parse(localStorage.getItem(`contractCars`)) as string[];
      this.form.patchValue({ cars: this.cars });
      localStorage.setItem('contractparking', JSON.stringify(this.form.value));

      if (localStorage.getItem(`contractCars`) !== null && localStorage.getItem('contractPayment') === null && this.form.value.price > 0) {
        this.openPaymentcardDialog();
      } else if (this.form.value.price === 0) {
        this.form.patchValue({ card: 'Gratis' });
      }
    });
  }

  openPaymentcardDialog() {
    this.dialog.open(ContractPaymentCardComponent, {
      disableClose: true,
      width: '500px',
      maxHeight: '810px',
      height: 'auto',
      data: {
        areaKey: this.spot,
        parkingId: this.parkingId
      }
    }).afterClosed().subscribe(result => {
      this.card = JSON.parse(localStorage.getItem(`contractPayment`));
      this.form.patchValue({ card: this.card });
      localStorage.setItem('contractparking', JSON.stringify(this.form.value));
    });
  }

  renew(obj) {
    this.form.patchValue({ renew: obj.option.selected });
    localStorage.setItem('contractparking', JSON.stringify(this.form.value));
  }

  onDatePicked(obj) {
    const start = DateTime.fromISO(obj.value.toISOString());
    this.end = start;

    if (this.form.value.id == null || typeof this.form.value.id == null || this.form.value.id === '') {
      localStorage.setItem('contractparking', JSON.stringify(this.form.value));
      this.form.patchValue({ end: '' });
        this.selects.controls.forEach(control => {
        const eve = {
          type: control.value.type,
          value: obj.value.toISOString()
        };
        this.checkAvalibility(eve).subscribe(result => {
          if (!result.isAvailable) {
              control.disable();
            } else {
              control.enable();
            }
          });
      });
    } else {
      const product = this.products.find(element => element.id === this.form.value.id );

      switch (product.type) {
        case ContractProductPeriod.Day:
          this.end =  this.end.plus({ days: 1 });
          this.form.patchValue({ expireType: '1 dag' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) });
          break;

        case ContractProductPeriod.TwoDays:
          this.end =  this.end.plus({ days: 2 });
          this.form.patchValue({ expireType: '2 dage' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) });
          break;

        case ContractProductPeriod.ThreeDays:
          this.end =  this.end.plus({ days: 3 });
          this.form.patchValue({ expireType: '3 dage' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) });
          break;

        case ContractProductPeriod.FourDays:
          this.end =  this.end.plus({ days: 4 });
          this.form.patchValue({ expireType: '4 dage' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) });
          break;

        case ContractProductPeriod.FiveDays:
          this.end =  this.end.plus({ days: 5 });
          this.form.patchValue({ expireType: '5 dage' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) });
          break;

        case ContractProductPeriod.SixDays:
          this.end =  this.end.plus({ days: 6 });
          this.form.patchValue({ expireType: '6 dage' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) });
          break;

        case ContractProductPeriod.Week:
          this.end =  this.end.plus({ days: 7 });
          this.form.patchValue({ expireType: '7 dage' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) });
          break;

        case ContractProductPeriod.TwoWeeks:
          this.end =  this.end.plus({ days: 14 });
          this.form.patchValue({ expireType: '14 dage' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) });
          break;

        case ContractProductPeriod.ThreeWeeks:
          this.end =  this.end.plus({ days: 21 });
          this.form.patchValue({ expireType: '21 dage' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) });
          break;

        case ContractProductPeriod.Month:
        this.end =  this.end.plus({ months: 1 });
          this.form.patchValue({ expireType: '1 mdr.' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) })
          break;

        case ContractProductPeriod.ThreeMonths:
        this.end =  this.end.plus({ months: 3 });
          this.form.patchValue({ expireType: '3 mdr.' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end)})
          break;

        case ContractProductPeriod.SixMonths:
        this.end =  this.end.plus({ months: 6 });
          this.form.patchValue({ expireType: '6 mdr.' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) })
          break;

        case ContractProductPeriod.Year:
        this.end =  this.end.plus({ years: 1 });
          this.form.patchValue({ expireType: '1 år' });
          this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).subscribe(result => { this.handleChangeDate(result,  this.end) })
          break;
      }

      localStorage.setItem('contractparking', JSON.stringify(this.form.value));
      this.form.patchValue({ end: this.end });
      }
    }

  checkAvalibility(obj) {
    const start = DateTime.fromISO(obj.value);
    this.end = start;

    switch (obj.type) {
      case ContractProductPeriod.Day:
        this.end =  this.end.plus({ days: 1 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => { return result;});

      case ContractProductPeriod.TwoDays:
        this.end =  this.end.plus({ days: 2 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => { return result;});

      case ContractProductPeriod.ThreeDays:
        this.end =  this.end.plus({ days: 3 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => { return result;});

      case ContractProductPeriod.FourDays:
        this.end =  this.end.plus({ days: 4 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => { return result;});

      case ContractProductPeriod.FiveDays:
        this.end =  this.end.plus({ days: 5 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => { return result;});

      case ContractProductPeriod.SixDays:
        this.end =  this.end.plus({ days: 6 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => { return result;});

      case ContractProductPeriod.Week:
      this.end =  this.end.plus({ days: 7 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => { return result;});

      case ContractProductPeriod.TwoWeeks:
        this.end =  this.end.plus({ days: 14 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => { return result;});

      case ContractProductPeriod.ThreeWeeks:
        this.end =  this.end.plus({ days: 21 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => { return result;});

      case ContractProductPeriod.Month:
      this.end =  this.end.plus({ months: 1 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => { return result});

      case ContractProductPeriod.ThreeMonths:
      this.end =  this.end.plus({ months: 3 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => {  return result});

      case ContractProductPeriod.SixMonths:
      this.end =  this.end.plus({ months: 6 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => {  return result});

      case ContractProductPeriod.Year:
      this.end =  this.end.plus({ years: 1 });
        return this.contractService.getAvability(start.toFormat('yyyy-LL-dd').toString(),  this.end.toFormat('yyyy-LL-dd').toString(), this.spot).map(result => {  return result});
    }
  }

  handleChangeDate(result: IAvability, end: DateTime) {
    if (!result.isAvailable && result.availableLeft < this.form.value.permit) {
      this.form.patchValue({ start: null });
      this.form.patchValue({ end: null });
        const errorModel = new ServiceError();
        errorModel.errorCode = 2006;
        this.errorService.sendError(errorModel);
    } else {
      this.form.patchValue({ end: end });
    }
  }

  onSelect(obj) {
    if (obj.value != null) {
      this.selects.controls.forEach(control => {
        if (control.value.value === '' || control.value.value == null ) {
          control.disable();
        } else {

          this.form.patchValue({ facility: this.spot });

                this.form.patchValue({ cartExpiresAt: DateTime.local().plus({hours: 6}).valueOf() });
          if (control.value.locked === true) {
              const dialogRef = this.dialog.open(ContractPasswordComponent, { data: { productId: control.value.id}, width: '500px' });

            dialogRef.afterClosed().subscribe(result => {
              if (dialogRef.componentInstance.success === true) {
                this.form.patchValue({ permit: control.value.value });
                this.form.patchValue({ price: control.value.price });
                this.form.patchValue({ id: control.value.id });
                const endCalculated = this.getEndDate(control.value.type, this.form.value.start);
                this.form.patchValue({ end: endCalculated});
                this.form.patchValue({ expireType:  this.getExpireType(control.value.type) });
                // this.form.patchValue({ end: this.end });
                this.form.patchValue({ code: dialogRef.componentInstance.productCode.value });
                localStorage.setItem('contractparking', JSON.stringify(this.form.value));
              } else {
                this.form.patchValue({ permit: 0 });
                control.patchValue({value : ''});

                this.selects.controls.forEach(con => {
                const eve = {
                  type: con.value.type,
                  value: this.getDateStringFromObject(this.form.value.start)
                };
                this.checkAvalibility(eve).subscribe(res => {
                  if (!res.isAvailable) {
                    con.disable();
                  } else {
                    con.enable();
                    con.patchValue({ value: null });
                  }
                  });
                });
              }
            });
          } else {
            this.form.patchValue({ permit: control.value.value });
            this.form.patchValue({ price: control.value.price });
            this.form.patchValue({ id: control.value.id });
            const endCalculated = this.getEndDate(control.value.type, this.form.value.start);
            this.form.patchValue({ expireType:  this.getExpireType(control.value.type) });
            this.form.patchValue({ end: endCalculated});
          }
        }
      });
    } else {
      this.form.patchValue({ permit: null });
      this.form.patchValue({ id: null });
      this.selects.controls.forEach(control => {

        const eve = {
          type: control.value.type,
          value: this.getDateStringFromObject(this.form.value.start)
        };
        this.checkAvalibility(eve).subscribe(result => {
          if (!result.isAvailable) {
            control.disable();
          } else {
            control.enable();
            control.patchValue({ value: null });
          }
          });
      });
    }
    localStorage.setItem('contractparking', JSON.stringify(this.form.value));
  }

  getDateStringFromObject(start: any) {
    if (!(start instanceof Date) ) {
      return start;
    } else {
      return start.toISOString();
    }
  }

  checkout() {
    this.router.navigate(['contractparking/' + this.spot + '/checkout']);
  }

  getEndDate(type: number, startObj: any) {
      let end: DateTime;
      let start: DateTime;

      if (startObj instanceof DateTime ) {
        start = startObj;
      } else if (DateTime.fromISO(startObj.toString()).isValid) {
        start = DateTime.fromISO(startObj);

      } else {
        start = DateTime.fromISO(startObj.toISOString());
      }

    switch (type) {
      case ContractProductPeriod.Day:
        end =  start.plus({ days: 1 });
        break;

      case ContractProductPeriod.TwoDays:
        end =  start.plus({ days: 2 });
        break;

      case ContractProductPeriod.ThreeDays:
        end =  start.plus({ days: 3 });
        break;

      case ContractProductPeriod.FourDays:
        end =  start.plus({ days: 4 });
        break;

      case ContractProductPeriod.FiveDays:
        end =  start.plus({ days: 5 });
        break;

      case ContractProductPeriod.SixDays:
        end =  start.plus({ days: 6 });
        break;

      case ContractProductPeriod.Week:
        end =  start.plus({ days: 7 });
        break;

      case ContractProductPeriod.TwoWeeks:
        end =  start.plus({ days: 14 });
        break;

      case ContractProductPeriod.ThreeWeeks:
        end =  start.plus({ days: 21 });
        break;

      case ContractProductPeriod.Month:
        end =  start.plus({ months: 1 });
        break;

      case ContractProductPeriod.ThreeMonths:
        end =  start.plus({ months: 3 });
        break;

      case ContractProductPeriod.SixMonths:
        end =  start.plus({ months: 6 });
        break;

      case ContractProductPeriod.Year:
        end =  start.plus({ years: 1 });
        break;
    }

    return end;
  }

  getExpireType(type: number) {
    let typeString: string;
    switch (type) {
      case ContractProductPeriod.Day:
        typeString =  '1 dag';
        break;

      case ContractProductPeriod.TwoDays:
        typeString =  '2 dage';
        break;

      case ContractProductPeriod.ThreeDays:
        typeString =  '3 dage';
        break;

      case ContractProductPeriod.FourDays:
        typeString =  '4 dage';
        break;

      case ContractProductPeriod.FiveDays:
        typeString =  '5 dage';
        break;

      case ContractProductPeriod.SixDays:
        typeString =  '6 dage';
        break;

      case ContractProductPeriod.Week:
        typeString =  '7 dage';
        break;

      case ContractProductPeriod.TwoWeeks:
        typeString =  '14 dage';
        break;

      case ContractProductPeriod.ThreeWeeks:
        typeString =  '21 dage';
        break;

      case ContractProductPeriod.Month:
        typeString =  '1 md.';
        break;

      case ContractProductPeriod.ThreeMonths:
        typeString =  '3 mdr.';
        break;

      case ContractProductPeriod.SixMonths:
        typeString =  '6 mdr.';
        break;

      case ContractProductPeriod.Year:
        typeString =  '1 år';
        break;
    }

    return typeString;
  }
}
