import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordSuccessComponent } from './changepasswordsuccess.component';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  currentPassword = new FormControl('', [
    Validators.required
  ]);

  password = new FormControl('', [
    Validators.required,
    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')
  ]);

  confirmPass = new FormControl('', [
    Validators.required
  ]);

  passwordForm: FormGroup = new FormGroup(
  {
    currentPassword: this.currentPassword,
    password: this.password,
    confirmPass: this.confirmPass
  });

  constructor(private userService: UserService, private dialog: MatDialog) { }

  ngOnInit() {
  }

  updatePassword(): void {
    if (!this.passwordForm.valid) {
      return;
    }

    if (this.password.value === this.confirmPass.value) {
      this.userService.changePassword(this.currentPassword.value, this.password.value).subscribe(() => {
        this.dialog.open(ChangePasswordSuccessComponent, {});
      });
    }
  }

}
