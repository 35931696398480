import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { TopmenuComponent } from './Views/topmenu/topmenu.component';
import { MapComponent } from './Views/map/map.component';
import { CreateaccountComponent } from './Views/createaccount/createaccount.component';
import { TermsComponent } from './Views/terms/terms.component';
import { MyaccountComponent } from './Views/myaccount/myaccount.component';
import { CreatecarComponent } from './Views/createcar/createcar.component';
import { EditcarComponent } from './Views/editcar/editcar.component';
import { MycarsComponent } from './Views/mycars/mycars.component';
import { MybrobizzComponent } from './Views/mybrobizz/mybrobizz.component';
import { MymeanspaymentComponent } from './Views/mymeanspayment/mymeanspayment.component';
import { VoucherComponent } from './Views/voucher/voucher.component';
import { MyparkingsComponent } from './Views/myparkings/myparkings.component';
import { RegistercardComponent } from './Views/registercard/registercard.component';
import { ErrorComponent } from './Views/error/error.component';
import { MyaccountmenuComponent } from './Views/myaccountmenu/myaccountmenu.component';
import { CreateparkingComponent } from './Views/createparking/createparking.component';
import { CustomersupportComponent } from './Views/customersupport/customersupport.component';
import { PayAndCheckoutComponent} from './Views/payandcheckout/payandcheckout.component';
import { PayParkingComponent} from './Views/payandcheckout/payparking.component';
import { PayParkingPaidComponent } from './Views/payandcheckout/payparkingpaid.component';
import { SigninComponent} from './Views/signin/signin.component';
import { ParkingDetailsComponent } from './Views/parkingdetails/parkingdetails.component';
import { QuickParkComponent } from './Views/createparking/quickpark.component';
import {CurrentparkingsoverviewComponent} from './Views/currentparkingsoverview/currentparkingsoverview.component';
import { MobilenumbersComponent } from './Views/mobilenumbers/mobilenumbers.component';
import { GdprtermsComponent } from './Views/gdprterms/gdprterms.component';
import { ChangepasswordComponent } from './Views/changepassword/changepassword.component';
import { ResetPasswordAppComponent } from './Views/resetpasswordapp/resetpasswordapp.component';
import { ContactFormComponent } from './Views/contactform/contactform.component';
import { VoucherParkingComponent } from './Views/voucherparking/voucherparking.component';
import { VoucherParkingDoneComponent } from './Views/voucherparking/voucherparkingdone.component';
import { ContractParkingComponent } from './Views/contract/contractparking.component';
import { ContractLoginComponent } from './Views/contract/contractlogin.component';
import { LeaseMapComponent } from './Views/leasemap/leasemap.component';
import { ContractCheckoutComponent } from './Views/contract/contractcheckout.componet';
import { ContractConfirmComponent } from './Views/contract/contractconfirm.component';
import { ContractParkingEditComponent } from './Views/contract/contractparkingedit.component';
import { DeactivateLandingComponent } from './Views/deactivatelanding/deactivatelanding.component';

const appRoutes: Routes = [
    { path: '', component: MapComponent },
    { path: 'map/:areaKey', component: MapComponent },
    { path: 'map/createparking/:id', component: MapComponent },
    { path: 'customersupport/contact', component: ContactFormComponent },
    { path: 'createaccount', component: CreateaccountComponent },
    { path: 'createparking/:id', component: CreateparkingComponent },
    { path: 'profile/currentparkings', component: CurrentparkingsoverviewComponent },
    { path: 'customersupport', component: CustomersupportComponent },
    { path: 'profile', component: MyaccountComponent },
    { path: 'myaccountmenu', component: MyaccountmenuComponent },
    { path: 'mybrobizz', component: MybrobizzComponent },
    { path: 'profile/mycars', component: MycarsComponent },
    { path: 'profile/paymentsubscription', component: MymeanspaymentComponent },
    { path: 'profile/myparkings', component: MyparkingsComponent },
    { path: 'parkingdetails/:id', component: ParkingDetailsComponent },
    { path: 'payparking', component: PayAndCheckoutComponent },
    { path: 'payparking/:id', component: PayParkingComponent },
    { path: 'paidparking', component: PayParkingPaidComponent},
    { path: 'registercard', component: RegistercardComponent },
    { path: 'signin', component: SigninComponent },
    { path: 'customersupport/terms', component: TermsComponent },
    { path: 'profile/discount', component: VoucherComponent },
    { path: 'createparking/quickpark/:id/:regNum/:millis', component: QuickParkComponent },
    { path: 'createparking/quickpark/:id/:regNum/:millis/:voucher', component: QuickParkComponent },
    { path: 'profile/mobilenumbers', component: MobilenumbersComponent },
    { path: 'customersupport/gdpr', component: GdprtermsComponent },
    { path: 'profile/changepassword', component: ChangepasswordComponent },
    { path: 'apponly', component: ResetPasswordAppComponent },
    { path: 'voucherparking', component: VoucherParkingComponent },
    { path: 'voucherparkingdone', component: VoucherParkingDoneComponent },
    { path: 'contractparking/:spotNum', component: ContractParkingComponent },
    { path: 'contractparking/:spotNum/login', component: ContractLoginComponent },
    { path: 'contractparking/:spotNum/checkout', component: ContractCheckoutComponent },
    { path: 'contractparking/:spotNum/confirm', component: ContractConfirmComponent },
    { path: 'contractparking/:spotNum/edit/:parkingId', component: ContractParkingEditComponent },
    { path: 'leasemap', component: LeaseMapComponent },
    { path: 'deactivate/landingpage', component: DeactivateLandingComponent},
    { path: 'deactivate/landingpage/:userGuid', component: DeactivateLandingComponent},
    { path: '**', component: ErrorComponent }
  ];
@NgModule({
  imports: [ RouterModule.forRoot(appRoutes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
