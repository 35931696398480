import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ParkingService } from '../../services/parking.service';
import { VehicleregistrationService } from '../../services/vehicleregistration.service';
import { DateTime } from 'luxon';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-parkingdetails',
  templateUrl: './parkingdetails.component.html',
  styleUrls: ['./parkingdetails.component.css']
})
export class ParkingDetailsComponent implements OnInit {
  private sub: Subscription;
  id: number;
  vehicleRegistration: string;
  vehicleDescription: string;
  areaNumber: string;
  address: string;
  startTime: string;
  endTime: string;
  total: number;
  fee: number;
  parkingNumber : string;
  transactions = [];

  day = 0;
  hour = 0;
  minute = 0;

  constructor(
    private route: ActivatedRoute,
    private parkingService: ParkingService,
    private authService: AuthService,
    private vehicleRegistrationService: VehicleregistrationService) {

      const that = this;

      this.sub = this.route.paramMap
      .subscribe(params => {
        this.id = +params.get('id');
        this.parkingService.getParking(this.id).subscribe(x => {
          this.areaNumber = x.parkingAreaNumber;
          this.parkingNumber = x.parkingNumber;
          this.address = x.parkingAreaAddress.line + ' ' + x.parkingAreaAddress.postalCode + ' ' + x.parkingAreaAddress.city;
          this.vehicleRegistration = x.vehicleRegistration.typed;
          this.total = x.totalParkingPrice;
          if (x.transactions != null && x.transactions.length > 0) {
            this.startTime = x.starttime.toString();
            this.endTime = x.endtime.toString();

            // const duration = x.starttime.diff(x.endtime);
            // const minutes  = parseInt(((duration.milliseconds / (1000 * 60)) % 60).toString(), 10);
            // const hours    = parseInt(((duration.milliseconds / (1000 * 60 * 60)) % 24).toString(), 10);
            // const days     = parseInt((duration.milliseconds / (1000 * 60 * 60 * 24)).toString(), 10);

            // this.minute = minutes;
            // this.hour = hours;
            // this.day = days;
          } else {
            this.startTime = x.starttime.toString();
            this.endTime = x.endtime.toString();
          }

          let parkFee = 0;
          // tslint:disable-next-line:forin
          for (const i in x.transactions) {
            this.transactions.push(x.transactions[i]);
            if (x.transactions[i].parkingFee !== null && x.transactions[i].parkingFee !== undefined) {
              parkFee += x.transactions[i].parkingFee;
            }
          }

          this.fee = parkFee;
          this.vehicleRegistrationService.registrations().subscribe(y => {
            const vehReg = y.find(u => u.key === x.vehicleRegistration.key);
            if (vehReg !== undefined && vehReg !== null) {
              that.vehicleDescription =  vehReg.description;
            }
          });
        });
      });
     }

  ngOnInit() {
  }

  downloadPdf() {
    this.parkingService.downloadPdf(this.parkingNumber, this.authService.getSubjectId());
  }
}
