import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-sliderzonenotice',
    templateUrl: './sliderzonenotice.component.html',
    styleUrls: ['./sliderzonenotice.component.css']
  })
  export class SliderZoneNoticeComponent implements OnInit {
    constructor(
      public router: Router,
      public dialogRef: MatDialogRef<SliderZoneNoticeComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
    }

    ok() {
      this.dialogRef.close();
    }

    cancel() {
      console.log("Hit")
      this.dialogRef.close();
      this.router.navigate(['/']);
    }
  }
