import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ServiceError } from '../models/error';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  errorSubject: Subject<ServiceError>;

  constructor() {
    this.errorSubject = new Subject<ServiceError>();
  }

  public sendError(error: ServiceError): void {
    this.errorSubject.next(error);
  }
}
