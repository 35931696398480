import { Component, OnInit, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { ICreateContractParking } from 'src/app/models/contract';
import { ContractService } from 'src/app/services/contract.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FacilityService } from 'src/app/services/facility.service';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentsubscriptionService } from 'src/app/services/paymentsubscription.service';
import { IParkingFacility } from '../../models/parkingFacility';
import { forEach } from '@angular/router/src/utils/collection';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-contractcheckout',
  templateUrl: './contractcheckout.componet.html',
  styleUrls: ['./contractcheckout.componet.css']
})

export class ContractCheckoutComponent implements OnInit {
  currentFacility: IParkingFacility;
  spot: string;
  form: any;
  model = new ICreateContractParking();
  private subscription: any;
  checkingout = false;
  typeCard: string;
  area: string;
  disabled = false;
  btnDisabled = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private contractService: ContractService,
    private facilityService: FacilityService,
    private paymentService: PaymentsubscriptionService,
    private loadingService: LoadingService
  ) {
    this.subscription = this.route.params.subscribe(params => {
      this.spot = params['spotNum'];
    });
  }

  ngOnInit() {
    this.facilityService.getFacility('DK', this.spot).subscribe(result => {
      this.currentFacility = result;
    });
    this.form = JSON.parse(localStorage.getItem('contractparking')) as object;
    this.contractService.getProduct(this.form.id).subscribe(result => {
      this.area = result.title;
      console.log(this.form.card);
      if (this.form.card !== '' && this.form.card !== 'Gratis') {
      this.paymentService.getSubscription(this.form.card.paymentUId).subscribe(x => {
        this.typeCard = x.displayName;
      });
    }
    });
  }

  checkout() {
    // this.checkingout = true;

    this.btnDisabled = true;
    console.log(this.btnDisabled);
    for (let i = 0; i < this.form.cars.length; i++) {
      this.model.productid = this.form.id;
      this.model.parkingAreaKey = 'ADK-' + this.spot;
      if (this.form.card !== 'Gratis') {
      this.model.paymentUid = this.form.card.paymentUId;
      }
      this.model.validFrom = this.form.start;
      this.model.vehicleRegistration = this.form.cars[i];
      this.model.vehicleRegistrationCountry = 'DK';
      this.model.autoRenew = this.form.renew;
      this.model.code = this.form.code;
      this.contractService.createContractParking(this.model).subscribe(result => {

        if (i === (this.form.cars.length - 1)) {
          localStorage.removeItem('contractparking');
          localStorage.removeItem('contractPayment');
          localStorage.removeItem('contractCars');
          this.router.navigate(['contractparking/' + this.spot + '/confirm']);
        }

        this.btnDisabled = false;
      });
    }

    
  }

  back() {
    this.router.navigate(['contractparking/' + this.spot]);
  }
}
