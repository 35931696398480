import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.css']
})
export class CookieComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<CookieComponent>) {
  }

  ngOnInit() {
  }

  accept() {
    const expirationDate = DateTime.utc().setZone('Europe/Copenhagen').plus({years: 1});

    localStorage.setItem('cookieAcceptance', '');
    this.dialogRef.close();
  }
}
