import { DateTime } from 'luxon';
export enum NotifyMethod { Email = 0, Sms = 1 }
export enum AgreementType { BroBizz = 0, PayByPlate = 1 }
export enum ChoosePaymentForm { No = 0, Yes = 1 }

export interface IValidate {
  email: string;
  mobileNumber: string;
  mobileNumberCountry: string;
}

export interface IValidateEmail {
  email: string;
}

export interface IValidateMobile {
  mobileNumber: string;
  mobileNumberCountry: string;
}

export interface IValidateResponse {
  customerNumber: string;
}

export interface IEnroll {
  customerNumber: string;
  notifyMethod: NotifyMethod;
}

export interface IEnrollmentCustomer {
  customerNumber: string;
  token: string;
  active: boolean;
  activationExpiry: DateTime;
  activationExpiryTimestamp: number;
  maskedEmail: string;
  maskedMobile: string;
}

export interface IEnrollmentCustomerAgreement {
  internalId: string;
  agreementType: AgreementType;
  choosePaymentForm: ChoosePaymentForm;
  carCountryId: string;
  carCountryDesc: string;
  carRegistrationNumber: string;
  creditCardExpiryMonth: number;
  creditCardExpiryYear: number;
  creditCardMaskedNumber: string;
  creditCardTypeId: string;
  creditCardTypeName: string;
  reference: string;
}

export class ValidateBrobizz {
  constructor(
    public email: string,
    public mobileNumber: string,
    public mobileNumberCountry: string
  ) { }
}

