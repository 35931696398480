import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PagespinnerService {

  public StateChange: Subject<boolean>;

  constructor() {
    this.StateChange = new Subject<boolean>();
  }

  private setState(state: boolean): void {
    this.StateChange.next(state);
  }

  public show(): void {
    this.setState(true);
  }

  public hide(): void {
    this.setState(false);
  }
}
