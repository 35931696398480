import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { map, catchError, switchMap, single } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { DateTime } from 'luxon';

import {IQuickparkOptions} from '../models/quickparkOptions';
import {IQuickparkPending} from '../models/quickparkPending';
import {IQuickparkVerified} from '../models/quickparkVerified';


@Injectable({
  providedIn: 'root'
})
export class QuickparkService {

  constructor(private http: HttpClient) { }

  create(options: IQuickparkOptions): Observable<IQuickparkPending> {
    const times = options.endTime.valueOf();
    const time = DateTime.fromMillis(+times).setZone('Europe/Copenhagen');
    const data = {
      allowContact: options.allowContact,
      email: options.email,
      endTime: time.toFormat('yyyy-MM-dd HH:mm:ss'),
      parkingAreaKey: options.parkingAreaKey,
      firstname: options.firstname,
      lastname: options.lastname,
      vehicleRegistrationKey: options.vehicleRegistrationKey,
      reminderNumber: options.reminderNumber,
      smsReminder: options.smsReminder,
      voucherCode: options.voucherCode,
      metaData : {
        'Origin': 'Webapp',

      }
    };

    const observer = this.http.post(environment.apiBaseUrl + `quickpark`, data)
      .pipe(map<any, IQuickparkPending>(result => {
        const pending: IQuickparkPending = {
          id: result.id,
          validFrom: DateTime.fromISO(result.starttime),
          validTo: DateTime.fromISO(result.endtime),
          parkingAreaKey: options.parkingAreaKey,
          fee: result.fee,
          orderNumber: result.orderNumber,
          price: result.price,
          totalPrice: result.totalPrice,
          vehicleRegistration: result.vehicleRegistration,
          vehicleRegistrationKey: result.vehicleRegistrationKey,
          discount: result.discount,
          customerId: result.customerId
        };

        return pending;
      }));

      return observer;
  }

  extend(parkId: number, customerId: string, validTo: DateTime): Observable<IQuickparkPending> {

    const data = {
      endTime: validTo.toISO(),
      customerId: customerId
    };

    const observer = this.http.post(environment.apiBaseUrl + `quickpark/${parkId}/extend`, data)
      .pipe(map<any, IQuickparkPending>(result => {
        const pending: IQuickparkPending = {
          id: result.id,
          validFrom: DateTime.fromISO(result.validFrom),
          validTo: DateTime.fromISO(result.validFrom),
          parkingAreaKey: result.parkingAreaKey,
          fee: result.fee,
          orderNumber: result.orderNumber,
          price: result.price,
          totalPrice: result.totalPrice,
          vehicleRegistration: result.vehicleRegistration,
          vehicleRegistrationKey: result.vehicleRegistrationKey,
          discount: result.discount,
          customerId: result.customerId
        };

        return pending;
      }));

      return observer;
  }

  stop(parkId: number, customerId: string): Observable<void> {
    return this.http.post<void>(environment.apiBaseUrl + `quickpark/${parkId}/Stop`, { customerId: customerId});
  }

  verify(customerId: string, orderNumber: string): Observable<IQuickparkVerified> {
    return this.http.get<IQuickparkVerified>(environment.apiBaseUrl + `quickpark/verify/${customerId}/${orderNumber}`);
  }
}
