import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-ienudge',
    templateUrl: './ienudge.component.html',
    styleUrls: ['./ienudge.component.css']
  })
  export class IENudgeComponent implements OnInit {

    constructor(
    public dialogRef: MatDialogRef<IENudgeComponent>) {}

    ngOnInit() {
    }

    ok() {
        this.dialogRef.close();
    }
  }
