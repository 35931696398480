import { Component, OnInit, ViewChild, Input, HostListener, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FacilityService } from 'src/app/services/facility.service';
import { MatSlider, MatSliderChange, MatDialog, MatDialogRef } from '@angular/material';
import { DateTime } from 'luxon';
import { timer, interval } from 'rxjs';
import { IVehicleRegistration } from 'src/app/models/vehicleregistration';
import { Router, ActivatedRoute } from '@angular/router';
import { ParkingService } from 'src/app/services/parking.service';
import { ICreateParking } from 'src/app/models/parking';
import { VehicleregistrationService } from 'src/app/services/vehicleregistration.service';
import { EventService } from 'src/app/services/event.service';
import { IParkingFacility } from 'src/app/models/parkingFacility';

import { AuthService } from 'src/app/services/auth.service';
import { PaymentsubscriptionService } from 'src/app/services/paymentsubscription.service';
import { CreateparkingvoucherSuccessComponent, CreateparkingvoucherFailureComponent,
  ParkingFailedComponent, ParkingGDPRComponent } from '../createparking/createparking.component';
import { SliderZoneNoticeComponent } from './sliderzonenotice.component';
import { SliderDateErrorComponent } from './sliderdateerror.component';
import { SliderDatePickerComponent } from './sliderdatepicker.component';
import { IPaymentSubscription } from '../../models/paymentsubscription';
import { SliderClosingHoursComponent } from './sliderclosinghours.component';

// import * as rangeSlider from 'rangeslider-pure';

@Component({
  selector: 'app-parkslider',
  templateUrl: './parkslider.component.html',
  styleUrls: ['./parkslider.component.css']
})

export class ParkSliderComponent implements OnInit {

  @ViewChild('slider') public slider: MatSlider;

  @Input('areaKey')
  areaKey: string;

  @Output()
  sliderClickedOutside = new EventEmitter<boolean>();

  private _validTo = this.getValidTo().toLocal();

  parkSlider: any;

  cards : IPaymentSubscription[] = [];
  selectedCard: any;
  isOpen: boolean;

  cars = [];
  selectedCar: IVehicleRegistration;
  regNum: string;

  parkInfo: ICreateParking;

  showTimePicker = false;
  showDatePicker = false;
  invalidDate = false;
  showValidTo = false;
  checkout = false;
  success = false;

  currentArea: any;

  sliderValue = 0;

  electricPrice = null;
  price = 0;
  fee = 0;
  total = 0;

  days = 0;
  hours = 0;
  minutes = 0;

  shownMinutes = 0;
  shownHours = 0;

  customDate = false;

  voucher: string;
  voucherType?: number;
  voucherValue = null;

  availableBalance: number;
  acceptedVoucher = null;

  calculatingPrice = false;

  isExtension = false;
  extendStartTime?: any;
  extendStart?: DateTime;
  extendNumber?: number;
  extendParkingKey: any;
  timer: number;

  isValidClosingHours : boolean;
  // extendEndTime: DateTime;

  constructor(
    private route: ActivatedRoute,
    private eRef: ElementRef,
    public dialog: MatDialog,
    public router: Router,
    public authService: AuthService,
    public facilityService: FacilityService,
    public parkingService: ParkingService,
    public vehicleService: VehicleregistrationService,
    public paymentService: PaymentsubscriptionService,
    private cd: ChangeDetectorRef,
    private eventService: EventService
  ) {
    }

  ngOnInit() {
    this.extendStartTime = sessionStorage.getItem('endTime');
    const parkingNumber = sessionStorage.getItem('parkingNumber');
    sessionStorage.removeItem('endTime');
    sessionStorage.removeItem('parkingNumber');
    this.isValidClosingHours = false;
    this.getArea();

    if (this.authService.isAuthenticated()) {
      this.getCreditCards();
      this.getCars();
    }

    if (!this.authService.isAuthenticated()) {
      this.dialog.open(ParkingGDPRComponent, {
        disableClose: true,
        width: '500px'
      });
    }

    if (this.extendStartTime !== null && this.extendStartTime !== undefined) {
      this.extendStart = DateTime.fromMillis(+this.extendStartTime).toLocal();
      this.isExtension = true;
    }

    if (parkingNumber !== null && parkingNumber !== undefined) {
      this.extendNumber = +parkingNumber;
      this.parkingService.getParking(this.extendNumber).subscribe(result => {
        this.extendParkingKey = result.vehicleRegistration.key;
        this.getCars();
      });
    }

    this.getPrice();
  }

  getArea() {
    this.facilityService.getFacility('DK', this.areaKey).subscribe(result => {
      this.currentArea = result;

      if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) return;
      if (result.type === 2) {
        this.dialog.open(SliderZoneNoticeComponent, {
          disableClose: true,
          width: '500px',
          data: {
            area: this.currentArea
          }
        });
        console.log(this.currentArea)
      }
    });
  }

  sliderChange(event: MatSliderChange) {
    let totalMinutes = 0;
    let mHours = 0;
    let roundedMinutes = 0;
    this.sliderValue = event.value / 10;
    let c = 6.0 * this.sliderValue;
    const current = this.sliderValue;

    this.calculatingPrice = true;

// (∩｀-´)⊃━☆ﾟ.*・｡ﾟ crazy magic numbers do not touch  (∩｀-´)⊃━☆ﾟ.*・｡ﾟ
  //   if (current < 0.3) {
  //     c = c / 1.68;
  //   } else if ( current >= 0.3 &&  current < 0.4  ) {
  //     c = c / 1.5;
  //  } else if ( current >= 0.4 &&  current < 0.5  ) {
  //      c = c / 1.4;
  //   } else if ( current >= 0.5 &&  current < 0.6  ) {
  //     c = c / 1.3;
  //  } else if ( current >= 0.6 && current < 0.9  ) {
  //      c = c / 1.18;
  //   } else if ( current >= 0.9 && current < 0.95  ) {
  //      c = c / 1.12;
  //   } else if ( current >= 0.95 && current < 0.98  ) {
  //      c = c / 1.09 ;
  //   } else if ( current >= 0.98 && current < 1  ) {
  //      c = c / 1.05 ;
  //   }

    this.customDate = false;
    // let factor =  Math.floor(c) < 1 ? 5 : 15;
    const factor = 5;

  //   if (event.value / 10 < 0.30 ) {
  //       factor = 5;
  //   } else if (event.value / 10 < 0.6 ) {
  //     factor = 10;
  // } else  {
  //   factor = 15;
  // }

    // Calculate the remainding minutes of the current time. (e.g. c = 4.3 = 18min)
    totalMinutes = (c % 1) * 60;
    roundedMinutes = Math.floor(Math.round(totalMinutes / factor) * factor);

    if (roundedMinutes === 60) {
        mHours = Math.floor(c) + 1;
        roundedMinutes = 0;
    } else {
        mHours = Math.floor(c);
    }

    const mMinutes = roundedMinutes;

    this.shownHours = mHours;
    this.shownMinutes = mMinutes;
    let date: DateTime;

    if (this.extendStartTime !== null && this.extendStartTime !== undefined) {
      date = DateTime.fromMillis(+this.extendStartTime).toLocal().plus({hours: mHours, minutes: mMinutes});
    } else {
      date = DateTime.local().plus({hours: mHours, minutes: mMinutes});
    }

    this.isValidClosingHours = true;
    if (!isClosingTimeValid(date, this.currentArea)) {
      this.isValidClosingHours = false;
      if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) {
        this.dialog.open(SliderClosingHoursComponent, {
          data: this.currentArea,
          width: '600px',
        });
      }
    }
    else {
      this.dialog.closeAll();
    }

    this.getTimeSpan(date);
    this.getPriceAsync();

    if (this.authService.isAuthenticated() || this.authService.isAuthenticated() !== true) {
      if (this.sliderValue === 1) {
      if (!this.dialog.openDialogs || this.dialog.openDialogs.length) return;
        const dialogRef = this.dialog.open(SliderDatePickerComponent, {
          data: { startDate: date },
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.date !== null && result.date !== undefined) {
            if (this.extendStartTime !== null && this.extendStartTime !== undefined) {
              if (result.date > DateTime.fromMillis(+this.extendStartTime).toLocal() && isClosingTimeValid(result.date, this.currentArea)) {
                this.getTimeSpan(result.date);
                setTimeout(() => {
                  this.getPrice();
                  this.cd.markForCheck();
                  if (this.authService.isAuthenticated() !== true && this.regNum === undefined || this.authService.isAuthenticated() !== true && this.regNum === '') {
                    this.checkout = false;
                  } else {
                    this.checkout = true;
                  }
                  this.customDate = true;
                }, 400);
              }
            } else {
              if (result.date > DateTime.local() && isClosingTimeValid(result.date, this.currentArea)) {
                this.getTimeSpan(result.date);
                setTimeout(() => {
                  this.getPrice();
                  this.cd.markForCheck();
                  if (this.authService.isAuthenticated() !== true && this.regNum === undefined || this.authService.isAuthenticated() !== true && this.regNum === '') {
                    this.checkout = false;
                  } else {
                    this.checkout = true;
                  }
                  this.customDate = true;
                }, 400);
              } else {
                this.checkout = false;
              }
            }
          }
        });
      }
    }
  }

  showCheckout() {
    if (this.checkout === false) {
      this.checkout = true;
    } else {
      this.checkout = false;
    }
  }

  setValidTo(validTo: DateTime): void {
    this._validTo = validTo;
  }

  private getValidTo(): DateTime {
    if (this._validTo) {
      return this._validTo;
    }

    return DateTime.utc();
  }

  getCars() {
    this.vehicleService.registrations().subscribe(res => {
      for (const i in res) {
        if (this.extendParkingKey !== null && this.extendParkingKey !== undefined) {
          this.selectedCar = res.find(x => x.key === this.extendParkingKey);
        } else if (res[i].isPrimary === true) {
          this.selectedCar = res[i];
          this.cars.push(res[i]);
        } else {
          this.cars.push(res[i]);
        }
      }
    });
  }

  getCreditCards() {
    this.paymentService.activeSubscriptions().subscribe(res => {
      for (const i in res) {
        if (res[i].isDefault === true) {
          this.selectedCard = res[i];
          this.cards.push(res[i]);
        } else {
          this.selectedCard = res[0];
          this.cards.push(res[i]);
        }
      }
      // this.checkIfCardsExists();
    });
  }

  getPrice(_that = null) {
    let that = this;
    if (_that) {
      that = _that;
    }



    let regiNum = '';
    if(that.regNum){
      regiNum = that.regNum;
    } else if(that.selectedCar){
      regiNum = that.selectedCar.typed;
    }




    that.facilityService.getPrice
    (that.areaKey, that.extendStartTime !== null ? DateTime.fromMillis(+that.extendStartTime) : DateTime.local(),
    that.getValidTo(), this.isExtension, null, that.acceptedVoucher, this.selectedCard ? this.selectedCard.paymentUId : null, regiNum).subscribe(res => {
      console.log(res);
      that.availableBalance = res.discount;
      that.electricPrice = res.electricPrice;
if(+res.discountRawValue > 0){
  // this.acceptedVoucher = true;
  this.voucherValue = res.discountRawValue;
  this.voucherType = res.discountType;

}
      if (res.discount > 0) {
        that.price = res.priceWithoutDiscount;
      } else {
      that.price = res.price;
      }

      if (res.fee !== null && res.fee !== undefined) {
        that.fee = res.fee;
      }
      that.total = res.totalPrice;
      that.cd.markForCheck();
      console.log(that.voucher);
      console.log(that.price);
      console.log(that.voucherValue);
      that.calculatingPrice = false;
   });
  }

  createParking() {
    this.parkInfo = {
      parkingAreaKey: this.areaKey,
      facilityCountryCode: 'DK',
      vehicleRegistrationKey: this.selectedCar.key,
      validTo: this.getValidTo(),
      paymentUId: this.selectedCard.paymentUId,
    };

    if (this.acceptedVoucher !== undefined) {
      this.parkInfo.voucherCode = this.acceptedVoucher;
    }

    if (this.extendStart !== null && this.extendNumber !== null && this.extendNumber !== undefined && this.extendStart !== undefined) {
      this.parkingService.extendParking(this.extendNumber, this.getValidTo(), this.acceptedVoucher,
      this.selectedCard.paymentUId).subscribe(res => {
        this.eventService.setUpdateMyParkings(true);
        this.checkout = false;
        this.success = true;
      });
    } else {
      this.parkingService.createParking(this.parkInfo).subscribe(result => {
        this.eventService.setUpdateMyParkings(true);
        this.checkout = false;
        this.success = true;
        this.cd.detectChanges();
      });
    }
  }

  showQuickPark() {
    if (this.regNum !== undefined) {
      if (this.acceptedVoucher !== null) {
        this.router.navigate([`/createparking/quickpark/${this.areaKey}/${this.regNum}/${this.getValidTo().valueOf()}/${this.acceptedVoucher}`]);
      } else {
        this.router.navigate([`/createparking/quickpark/${this.areaKey}/${this.regNum}/${this.getValidTo().valueOf()}`]);
      }
    } else {
      this.dialog.open(ParkingFailedComponent, {
      });
    }
  }

  addVoucher() {
    this.facilityService.getPrice
    (this.areaKey, this.extendStartTime !== null ? DateTime.fromMillis(+this.extendStartTime) : DateTime.local(),
    this.getValidTo(), this.isExtension, null, this.voucher, this.selectedCard ? this.selectedCard.paymentUId : null).subscribe(res => {
      this.availableBalance = res.discount;

    if (this.availableBalance > 0 || res.discountRawValue) {
      this.acceptedVoucher = this.voucher;
      this.voucherType = res.discountType;
      this.voucherValue = res.discountRawValue;

      this.dialog.open(CreateparkingvoucherSuccessComponent, {});
    } else {
      this.acceptedVoucher = null;
      this.voucherType = null;
      this.voucherValue = null;
      this.dialog.open(CreateparkingvoucherFailureComponent, {
      });
    }

    this.getPrice();
   });
  }


  getPriceAsync() {
    window.clearTimeout( this.timer);
    this.timer = window.setTimeout(this.getPrice, 100, this );
  }

  checkVoucher() {
    if (this.voucher.length >= 1) {
      this.addVoucher();
    }
  }

  getTimeSpan(to: DateTime) {
    const startTime = DateTime.local().plus({minutes: -1});
    const diff = to.diff(startTime);
    this.setValidTo(to);

    this.minutes = parseInt(Math.floor(+diff / (1000 * 60) % 60).toString(), 10);
    this.hours = parseInt(Math.floor(+diff / (1000 * 60 * 60) % 24).toString(), 10);
    this.days = Math.floor(diff.milliseconds / (1000 * 60 * 60 * 24)) ;
  }

  swapTime() {
    if (this.showValidTo === false) {
      this.showValidTo = true;
    } else {
      this.showValidTo = false;
    }
  }

  closeWindow() {
    this.sliderClickedOutside.emit(false);
  }
}

export const isClosingTimeValid = (date: DateTime, currentArea: IParkingFacility) : boolean => {
  if (currentArea.closingPeriods.length <= 0 && (currentArea.closingTimes == null || currentArea.closingTimes == undefined)) {
    return true;
  }
  if (currentArea.closingPeriods.length > 0 && currentArea.closingPeriods.some(x => DateTime.fromISO(x.from) < date && DateTime.fromISO(x.to) > date)) {
    return false;
  }
  if (currentArea.closingTimes == null || currentArea.closingTimes == undefined) {
    return true;
  }
  switch(date.weekdayLong)
  {
    case "Sunday":
        if (currentArea.closingTimes.Sunday == null || currentArea.closingTimes.Sunday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Sunday.some(x => DateTime.fromISO(x.from) < date && DateTime.fromISO(x.to) > DateTime.local())
    case "Monday":
        if (currentArea.closingTimes.Monday == null || currentArea.closingTimes.Monday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Monday.some(x => DateTime.fromISO(x.from) < date && DateTime.fromISO(x.to) > DateTime.local())
    case "Tuesday":
        if (currentArea.closingTimes.Tuesday == null || currentArea.closingTimes.Tuesday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Tuesday.some(x => DateTime.fromISO(x.from) < date && DateTime.fromISO(x.to) > DateTime.local())
    case "Wednesday":
        if (currentArea.closingTimes.Wednesday == null || currentArea.closingTimes.Wednesday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Wednesday.some(x => DateTime.fromISO(x.from) < date && DateTime.fromISO(x.to) > DateTime.local())
    case "Thursday":
        if (currentArea.closingTimes.Thursday == null || currentArea.closingTimes.Thursday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Thursday.some(x => DateTime.fromISO(x.from) < date && DateTime.fromISO(x.to) > DateTime.local())
    case "Friday":
        if (currentArea.closingTimes.Friday == null || currentArea.closingTimes.Friday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Friday.some(x => DateTime.fromISO(x.from) < date && DateTime.fromISO(x.to) > DateTime.local())
    case "Saturday":
        if (currentArea.closingTimes.Saturday == null || currentArea.closingTimes.Saturday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Saturday.some(x => DateTime.fromISO(x.from) < date && DateTime.fromISO(x.to) > DateTime.local())
  }
}

export const isClosingTimeFromToValid = (date: DateTime, currentArea: IParkingFacility) : boolean => {

if(!currentArea)
return false;

  if (currentArea.closingPeriods.length <= 0 && (currentArea.closingTimes == null || currentArea.closingTimes == undefined)) {
    return true;
  }
  if (currentArea.closingPeriods.length > 0 && currentArea.closingPeriods.some(x => DateTime.fromISO(x.from) < date && DateTime.fromISO(x.to) > date)) {
    return false;
  }
  if (currentArea.closingTimes == null || currentArea.closingTimes == undefined) {
    return true;
  }
  switch(date.weekdayLong)
  {
    case "Sunday":
        if (currentArea.closingTimes.Sunday == null || currentArea.closingTimes.Sunday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Sunday.some(x => DateTime.fromISO(x.to) > date && DateTime.fromISO(x.from) < date)
    case "Monday":
        if (currentArea.closingTimes.Monday == null || currentArea.closingTimes.Monday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Monday.some(x => DateTime.fromISO(x.to) > date && DateTime.fromISO(x.from) < date)
    case "Tuesday":
        if (currentArea.closingTimes.Tuesday == null || currentArea.closingTimes.Tuesday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Tuesday.some(x => DateTime.fromISO(x.to) > date && DateTime.fromISO(x.from) < date)
    case "Wednesday":
        if (currentArea.closingTimes.Wednesday == null || currentArea.closingTimes.Wednesday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Wednesday.some(x => DateTime.fromISO(x.to) > date && DateTime.fromISO(x.from) < date)
    case "Thursday":
        if (currentArea.closingTimes.Thursday == null || currentArea.closingTimes.Thursday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Thursday.some(x => DateTime.fromISO(x.to) > date && DateTime.fromISO(x.from) < date)
    case "Friday":
        if (currentArea.closingTimes.Friday == null || currentArea.closingTimes.Friday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Friday.some(x => DateTime.fromISO(x.to) > date && DateTime.fromISO(x.from) < date)
    case "Saturday":
        if (currentArea.closingTimes.Saturday == null || currentArea.closingTimes.Saturday == undefined) {
          return true;
        }
      return !currentArea.closingTimes.Saturday.some(x => DateTime.fromISO(x.to) > date && DateTime.fromISO(x.from) < date)
  }
}
